import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useOktaAuth } from "@okta/okta-react";

// Import Internal Components
import SecurityBranch1 from "./SecurityBranch1";
import SecurityBranch2 from "./SecurityBranch2";

// Import Constant
import { USER_INFO } from "redux/user/userConstant";

import {
  CS_Hub_Security_Admin_Branch_1,
  CS_Hub_Security_Admin_Branch_2,
} from "roles/roles";

const SecurityIndex = ({ history }) => {
  const dispatch = useDispatch();
  const { authState, oktaAuth } = useOktaAuth();

  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  useEffect(() => {
    if (authState.isAuthenticated) {
      oktaAuth.getUser().then((info) => {
        const getIdToken = oktaAuth.getIdToken();

        dispatch({
          type: USER_INFO,
          payload: {
            name: info.name,
            email: info.email,
            preferred_username: info.preferred_username,
            groups: info.groups,
            idToken: getIdToken,
          },
        });

        if (
          !info.groups.includes(CS_Hub_Security_Admin_Branch_1) &&
          !info.groups.includes(CS_Hub_Security_Admin_Branch_2)
        ) {
          history.push("/landing");
        }
      });
    } else {
      dispatch({
        type: USER_INFO,
        payload: null,
      });
      oktaAuth.signOut();
    }
  }, [authState, oktaAuth, dispatch]);

  return (
    <>
      {userInfo !== null &&
        authState &&
        authState.isAuthenticated &&
        (userInfo.groups.includes(CS_Hub_Security_Admin_Branch_1) ? (
          <SecurityBranch1 />
        ) : (
          <SecurityBranch2 />
        ))}
    </>
  );
};

export default SecurityIndex;
