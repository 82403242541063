import React from "react";
import { useDispatch } from "react-redux";
import {
  CONFORMITY_ALERT,
  CONFORMITY_RESOLUTION,
} from "redux/chatbot/chatBotConstant";

const SecurityFirstPrompt = (props) => {
  const dispatch = useDispatch();
  const options = [
    {
      text: "Conformity alert enumeration",
      handler: () => {
        props.actionProvider.handleAccountAlerts("alertCount");
        dispatch({
          type: CONFORMITY_ALERT,
          payload: true,
        });
        dispatch({
          type: CONFORMITY_RESOLUTION,
          payload: false,
        });
      },
      id: 8,
    },
    {
      text: "Alert resolution",
      handler: () => {
        props.actionProvider.handleAccountAlerts("alertResolution");
        dispatch({
          type: CONFORMITY_ALERT,
          payload: false,
        });
        dispatch({
          type: CONFORMITY_RESOLUTION,
          payload: true,
        });
      },
      id: 9,
    },
  ];

  const optionsMarkup = options.map((option) => {
    return (
      <button
        key={option.id}
        onClick={option.handler}
        className="option-button"
      >
        {option.text}
      </button>
    );
  });

  return (
    <>
      <div className="options-container">{optionsMarkup}</div>
    </>
  );
};

export default SecurityFirstPrompt;
