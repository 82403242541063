import React, { useEffect, useState } from "react";
import { Row, Col, Table } from "react-bootstrap";

// Import Icons
import backicon from "icons/back.svg";
import editicon from "icons/edit.svg";

import { CS_Hub_AWS_Account_Request_Admin } from "roles/roles";

const AccountRequestsView = ({
  data,
  cloudTitle,
  closeViewParent,
  openEditParent,
  userInfo,
}) => {
  const [selectedInstancesList, setSelectedInstancesList] = useState([]);

  useEffect(() => {
    debugger;
    const initialInstances = data?.instances ? data?.instances : null;
    const mappedInstances = [];
    if (initialInstances) {
      const regionList = data?.instances?.map((item) => Object.keys(item)[0]);

      let temp;
      for (let i = 0; i <= regionList.length - 1; i++) {
        temp = initialInstances[i][regionList[i]];
        for (let j = 0; j <= temp?.length - 1; j++) {
          mappedInstances.push({
            region: regionList[i],
            instance: initialInstances[i][regionList[i]][j],
          });
        }
      }
    }
    setSelectedInstancesList(mappedInstances);
  }, []);
  return (
    <div className="view-edit-box-conatiner">
      <div className="view-edit-box-top-bar">
        <div className="view-edit-box-back-title-container">
          <img
            src={backicon}
            alt="Back"
            className="view-edit-box-back-btn"
            onClick={() => closeViewParent(false)}
          />
          <div>
            <h5 className="view-edit-box-title">
              {cloudTitle} Patch Automation Details
            </h5>
            {(data.requestStatus === "Approved" ||
              data.requestStatus === "Cancelled" ||
              data.requestStatus === "Declined") && (
              <Row>
                <Col md={12}>
                  <div className="d-flex justify-content-center">
                    <span className="account-request-view-request-message">
                      {data.RequestActionTakenBy &&
                        `Request ${data.requestStatus} by
                      ${data.RequestActionTakenBy}`}
                    </span>
                  </div>
                </Col>
              </Row>
            )}
          </div>
          {data.requestStatus === "Approved" && (
            <span className="view-edit-box-accountid mx-3">
              Account ID: {data.accountnumber}
            </span>
          )}
        </div>
        <div className="view-edit-box-back-edit-container">
          {data.requestStatus === "Pending" &&
            (userInfo.groups.includes(CS_Hub_AWS_Account_Request_Admin) ||
              data.emailAO.toLowerCase() === userInfo.email.toLowerCase() ||
              data.emailTC.toLowerCase() === userInfo.email.toLowerCase() ||
              data.submittedBy === userInfo.preferred_username) && (
              <img
                src={editicon}
                alt="Edit"
                className="view-edit-box-edit-btn"
                onClick={() => {
                  closeViewParent(false);
                  openEditParent(true, data);
                }}
              />
            )}
          {/* {data.requestStatus === 'Approved' &&
            (userInfo.groups.includes(CS_Hub_AWS_Account_Request_Admin) ||
              data.emailAO.toLowerCase() === userInfo.email.toLowerCase() ||
              data.emailTC.toLowerCase() === userInfo.email.toLowerCase()) && (
              <img
                src={editicon}
                alt='Edit'
                className='view-edit-box-edit-btn'
                onClick={() => {
                  closeViewParent(false);
                  openEditParent(true);
                }}
              />
            )} */}
          {data.requestStatus === "Update Declined" &&
            (userInfo.groups.includes(CS_Hub_AWS_Account_Request_Admin) ||
              data.emailAO.toLowerCase() === userInfo.email.toLowerCase() ||
              data.emailTC.toLowerCase() === userInfo.email.toLowerCase()) && (
              <img
                src={editicon}
                alt="Edit"
                className="view-edit-box-edit-btn"
                onClick={() => {
                  closeViewParent(false);
                  openEditParent(true);
                }}
              />
            )}
        </div>
      </div>
      <div className="table-view-box-content mt-4 mb-5">
        <Row>
          <Col md={12}>
            <div className="account-request-view-title-container">
              <p className="account-request-view-title">
                Account Number: {data?.accountnumber}
              </p>
            </div>
          </Col>
          <Col md={12} className="mt-3">
            <div className="account-request-view-table-container">
              <table width="100%">
                <tbody className="account-request-view-table-tbody">
                  <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      Account Name
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value"
                    >
                      {data?.accountname}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      installation schedule
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value"
                    >
                      {`Hour: ${data?.hour ? data?.hour : "-"} | Minutes: ${
                        data?.minute ? data?.minute : "-"
                      } (UTC)`}
                    </td>
                  </tr>
                  {/* <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      Provided Hour
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value"
                    >
                      {data.hour}
                    </td>
                  </tr> */}
                  {/* <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      Provided Minute
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value"
                    >
                      {data.minute}
                    </td>
                  </tr> */}
                  <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      Status
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value"
                    >
                      {data?.requestStatus}
                    </td>
                  </tr>
                  {data?.instancetype === "0" ? (
                    <>
                      <tr>
                        <td
                          width="50%"
                          className="account-request-view-table-td-key"
                        >
                          Tag Name
                        </td>
                        <td
                          width="50%"
                          className="account-request-view-table-td-value"
                        >
                          {data?.tags?.tagName}
                        </td>
                      </tr>
                      <tr>
                        <td
                          width="50%"
                          className="account-request-view-table-td-key"
                        >
                          Tag Region
                        </td>
                        <td
                          width="50%"
                          className="account-request-view-table-td-value"
                        >
                          {data?.tags?.region}
                        </td>
                      </tr>
                      <tr>
                        <td
                          width="50%"
                          className="account-request-view-table-td-key"
                        >
                          Tag Description
                        </td>
                        <td
                          width="50%"
                          className="account-request-view-table-td-value"
                        >
                          {data?.tags?.tagDescription}
                        </td>
                      </tr>
                    </>
                  ) : null}

                  <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      Submitted By
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value"
                    >
                      {data?.submittedBy}
                    </td>
                  </tr>
                </tbody>
              </table>
              {data?.instancetype === "1" ? (
                <>
                  <Col md={12} className="mb-2">
                    <div className="account-request-view-title-container">
                      <p className="account-request-view-title">Instances</p>
                    </div>
                  </Col>
                  <Table
                    bordered={true}
                    size="sm"
                    className="account-confirmation-table"
                  >
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>Instance Id</th>
                        <th>Region</th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedInstancesList?.map((item, index) => (
                        <>
                          <tr>
                            <td>{index + 1}</td>
                            <td>{item?.instance}</td>
                            <td>{item?.region}</td>
                          </tr>
                        </>
                      ))}
                    </tbody>
                  </Table>
                </>
              ) : null}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default AccountRequestsView;
