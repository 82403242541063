import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { CS_Hub_GCS_Billing_Admins } from "roles/roles";

const adminDateValue = 32;

export const GetUserAuth = (dateValue = null) => {
  //screen date
  const screenDate = useSelector((state) => state?.billing?.billingScreenDate);
  //get user data
  const user = useSelector((state) => state.user);
  const { userInfo } = user;
  const userAccess = userInfo?.groups?.includes(CS_Hub_GCS_Billing_Admins);
  return userAccess ? adminDateValue : dateValue ? dateValue : screenDate;
};

export const opscostHeaders = [
  "Account ID",
  "GSID Tags",
  "Vendor",
  "Account Name",
  "Account Region",
  "Ops Cost",
  "IO Code/Cost Center",
  "Remarks",
];

export const awsCreditsHeaders = [
  "Account ID",
  "Account Name",
  "Description",
  "Total Credits Allocated",
  "Remainder",
];

export const gsidIoHeaders = [
  "GSID",
  "IO",
  "Billing Opco",
  "Billing Sub OpCo",
  "Billing Department",
  "Billing Region",
  "Billing First Name",
  "Billing Last Name",
  "Billing Email",
];
export const modificationHeaders = [
  "Account",
  "GSID",
  "Hosting Modification",
  "Post Mod",
  "Reason",
];

export const pamCostHeaders = [
  "AWS Account No",
  "Count of Server Name",
  "Sum of Unit Cost",
];

export const billingScreen = {
  opsCostScreenDate: () => GetUserAuth(),
  gsidScreenDate: () => GetUserAuth(11),
  awsCreditsScreenDate: () => GetUserAuth(11),
  modificationsScreenDate: () => GetUserAuth(11),
  pamCostScreenDate: () => GetUserAuth(),
};

export const cardChargebackData = [
  {
    name: "O",
    title: "OpsCost Details",
    color: "#b3a0e1",
    desc: "To view / modify the OpsCost details.",
    link: "/billing-edit",
    category: "Chargeback Actions",
    categoryName: "OpsCost",
  },
  {
    name: "G",
    title: "GSID-IO Mapping",
    color: "rgb(72 183 127)",
    desc: "To view / modify GSID - IO mapping details.",
    link: "/aws-gsidio",
    category: "Chargeback Actions",
    categoryName: "GSIDIO",
  },
  {
    name: "P",
    title: "Pam Cost Details",
    color: "#5BC3B0",
    desc: "To view / modify the PAM Costs details.",
    link: "/aws-pamcosts",
    category: "Chargeback Actions",
    categoryName: "PAM",
  },
  {
    name: "A",
    title: "AWS Credits",
    color: "#6FC5B5",
    desc: "Track and adjust credits mapped to AWS account.",
    link: "/aws-credits",
    category: "Chargeback Actions",
    categoryName: "AWS Credit",
  },
  {
    name: "M",
    title: "Modifications",
    color: "rgb(124 154 199)",
    desc: "To view / modify cost adjustments details.",
    link: "/aws-modification",
    category: "Chargeback Actions",
    categoryName: "MOD",
  },
];

export const cardDataInvoice = [
  {
    name: "G",
    title: "Generate Invoices",
    color: "#3FC5AC",
    desc: "To generate invoices for custom months.",
    link: "",
    category: "Invoices Actions",
    categoryName: "Invoice",
  },

  {
    name: "D",
    title: "Download Invoices",
    color: "#468585",
    desc: "To download invoices for previous months.",
    link: "",
    category: "Invoices Actions",
    categoryName: "",
  },
  {
    name: "G",
    title: "GitLab Report",
    color: "rgb(133 133 133)",
    desc: "Download GitLab user's report.",
    link: "",
    category: "Reports",
    categoryName: "",
  },
  {
    name: "W",
    title: "Workspaces Report",
    color: "rgb(0 175 175)",
    desc: "Download Workspaces user's report.",
    link: "",
    category: "Reports",
    categoryName: "",
  },
];

export const getISTTime = () => {
  const date = new Date();
  const offset =
    date.getTimezoneOffset() == 0 ? 0 : -1 * date.getTimezoneOffset();

  let normalized = new Date(date.getTime() + offset * 60000);
  let indiaTime = new Date(
    normalized.toLocaleString("en-US", { timeZone: "Asia/Calcutta" })
  );
  const timeWithDate = indiaTime.toISOString().split("T");
  const filteredTime = timeWithDate[1].split(".");
  const getTime = filteredTime[0];
  const getDate = timeWithDate[0];
  const timeObj = {
    getTime,
    getDate,
  };
  return timeObj;
};

export const month = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
