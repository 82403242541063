import React, { useState } from "react";
import TableDownload from "./TableDownload";

const TableComponent = ({ data }) => {
  const [isLiked, setLiked] = useState(false);
  const [isDisLiked, setDisLiked] = useState(false);
  return (
    <>
      <div className="table-overflow-x-scroll mb-1">
        {" "}
        <table className="table mb-0 fs-13 chatbot-msg-table table-hover">
          <thead>
            <tr>
              {Object.keys(data[0]).map((key) => (
                <th className={`${data?.length > 2 ? "p-1" : "p-2"}`} key={key}>
                  {key}
                </th>
              ))}
            </tr>
          </thead>
          <>
            <tbody>
              {data.map((item, index) => (
                <tr key={index}>
                  {Object.entries(item).map(([key, value]) => (
                    <td
                      className={`${data?.length > 2 ? "p-1" : "p-2"}`}
                      key={key}
                    >
                      {value}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </>
        </table>
      </div>

      <p className="mb-0 mt-1 fs-9_4 text-start float-start vertical-align-middle">
        <b>**For detailed resolution steps click on alert resolution</b>
      </p>
      <TableDownload
        formattedCsvData={data}
        isLiked={isLiked}
        setLiked={setLiked}
        isDisLiked={isDisLiked}
        setDisLiked={setDisLiked}
      />
    </>
  );
};

export default TableComponent;
