import React from "react";

const ColorCardsComponent = ({
  mainTitle,
  iconName,
  totalCount,
  subHeading,
  subCount,
  color,
  background,
}) => (
  <>
    <div
      className="p-3 security-dashboard-count"
      style={{ background: `${color}`, background: `${background}` }}
    >
      <h6>{mainTitle}</h6>
      <div className="d-flex">
        <img
          src={iconName}
          className="d-flex flex-grow-0 justify-content-start"
        />
        <h1 className=" d-flex flex-grow-1 justify-content-end">
          {totalCount}
        </h1>
      </div>
      <div className="d-flex">
        <h6 className="d-flex flex-grow-0 justify-content-start fw-500">
          {subHeading}
        </h6>
        <h6 className="d-flex flex-grow-1 justify-content-end fw-500">
          {subCount}
        </h6>
      </div>
    </div>{" "}
  </>
);

export default ColorCardsComponent;
