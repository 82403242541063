import React from 'react';
import { Row, Col } from 'react-bootstrap';

import TrendMicro from './TrendMicro';
import TrendMicroTopAlert from './TrendMicroTopAlert';

const TrendMicroContainer = () => {
  return (
    <div className='custom-card px-2'>
      <Row>
        <Col>
          <p className='subtitle-text px-2'>TrendMicro Workload Security</p>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <TrendMicro />
        </Col>
        <Col md={6}>
          <TrendMicroTopAlert />
        </Col>
      </Row>
    </div>
  );
};

export default TrendMicroContainer;
