import Api from "api/api";
import client from "api/client";
import { prearePostRequest } from "api/cloudeye";

export function securityTrendMicroConformityApi(values) {
  return client(Api.securityTrendMicroConformity, {
    ...prearePostRequest(values),
  }).then((res) => res.data);
}

export function securityTrendMicroConformityBranch2Api(values) {
  return client(Api.securityTrendMicroConformityBranch2, {
    ...prearePostRequest(values),
  }).then((res) => res.data);
}

export function securityTrendMicroConformityDetailsApi(values) {
    return client(Api.securityTrendMicroDetails, {
      ...prearePostRequest(values),
    }).then((res) => res.data);
  }

  export function securityTrendMicroConformityDetailsBranch2Api(values) {
    return client(Api.securityTrendMicroDetailsBranch2, {
      ...prearePostRequest(values),
    }).then((res) => res.data);
  }
