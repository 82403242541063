import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import Loader from "components/Common/Loader";
import AlertMessage from "components/Common/AlertMessage";

import { postApiCall } from "helper/postApiCall";
import { REDIRECT_ALERT } from "redux/aws-host-patch-management/patchAccountConstant";

const AccountRequestsApprove = ({
  yesParent,
  closeModalParent,
  status,
  uid,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  // Redux State
  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  const accountRequestCloud = useSelector((state) => state.accountRequestCloud);
  const { accountRequestCloudType } = accountRequestCloud;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successApproved, setSuccessApproved] = useState(false);

  const idToken = userInfo.idToken;

  const apiUrl = `${process.env.REACT_APP_SSM_PATCHING_SUBMIT_REQUEST_URL}`;

  async function pendingApprovalforUpdateToApprove() {
    setLoading(true);
    const requestBody = `{}`;
    const getStatus = status;
    const replaceSpaceWithUnderscore = getStatus.replace(/ /g, "_");
    const setStatus = replaceSpaceWithUnderscore;
    const setApiUrl = `${apiUrl}?uid=${uid}&type=1&actiontakenby=${userInfo.email}`;

    await postApiCall(setApiUrl, idToken).then((response) => {
      if (
        response.data.message === "Successfully initiated SSM Patching creation"
      ) {
        setSuccessApproved(true);
      } else {
        setError("Failed to updated");
        dispatch({
          type: REDIRECT_ALERT,
          payload: true,
        });
        closeModalParent(null);
        history.push("/aws-host-patch-request");
      }
    });
  }

  return (
    <div className="d-flex justify-content-center flex-column">
      {successApproved === false && (
        <>
          <p>Are you sure you want to approve this request</p>
          {error !== null && (
            <div className="d-flex justify-content-center flex-column">
              <AlertMessage
                cssClassName="alert-message-danger"
                alertMessage={error}
              />
            </div>
          )}
          {loading && <Loader />}
          {loading === false && error === null && (
            <ul className="btn-ul">
              <li>
                <button
                  type="button"
                  className="teal-bordered-btn"
                  onClick={() => {
                    closeModalParent(null);
                  }}
                >
                  No
                </button>
              </li>
              <li>
                <button
                  type="button"
                  className="teal-filled-btn"
                  onClick={() => {
                    pendingApprovalforUpdateToApprove();
                  }}
                >
                  Yes
                </button>
              </li>
            </ul>
          )}
        </>
      )}
      {successApproved === true && (
        <>
          <p>Successfully initiated SSM Patching creation!</p>
          <p>
            <button
              type="button"
              className="teal-filled-btn"
              onClick={() => {
                yesParent(null);
              }}
            >
              Ok
            </button>
          </p>
        </>
      )}
    </div>
  );
};

export default AccountRequestsApprove;
