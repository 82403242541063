import React from "react";
import { Container, Row, Col } from "react-bootstrap";

// Import Internal Components
import PrismaContainer from "./PrismaContainer";
import TrendMicroContainer from "./TrendMicroContainer";
import GISSecurity from "./GISSecurity";

const SecurityBranch1 = ({ history }) => {
  return (
    <>
      <Container fluid className="mb-3">
        <Row>
          <Col md={12} className="mt-3">
            <PrismaContainer />
          </Col>
        </Row>
        <Row>
          <Col md={12} className="mt-3">
            <TrendMicroContainer />
          </Col>
        </Row>
        <Row>
          <Col md={12} className="mt-3">
            <GISSecurity />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default SecurityBranch1;
