import {
  BOT_WINDOW,
  ACCOUNT_LIST,
  ACCOUNT_ID_PROMPT,
  ALERT_DESCRIPTION,
  FINANCE_PROMPT,
  APPLICATION_TYPE,
  FIRST_INPUT_ENTER,
  CONFORMITY_ALERT,
  CONFORMITY_RESOLUTION,
  IS_LOADING,
} from "./chatBotConstant";

export const chatBotReducer = (
  state = {
    botWindowStatus: false,
    accountList: null,
    isAccountIdPrompt: false,
    isFinancePrompt: false,
    applicationType: null,
    isFirstText: false,
    isConformityAlert: false,
    isConformityResolution: false,
    isLoading: false,
  },
  action
) => {
  switch (action.type) {
    case BOT_WINDOW:
      return { ...state, botWindowStatus: action.payload };
    case ACCOUNT_LIST:
      return { ...state, accountList: action.payload };
    case ACCOUNT_ID_PROMPT:
      return { ...state, isAccountIdPrompt: action.payload };
    case FINANCE_PROMPT:
      return { ...state, isFinancePrompt: action.payload };
    case APPLICATION_TYPE:
      return { ...state, applicationType: action.payload };
    case FIRST_INPUT_ENTER:
      return { ...state, isFirstText: action.payload };
    case CONFORMITY_ALERT:
      return { ...state, isConformityAlert: action.payload };
    case CONFORMITY_RESOLUTION:
      return { ...state, isConformityResolution: action.payload };
    case IS_LOADING:
      return { ...state, isLoading: action.payload };
    default:
      return state;
  }
};
