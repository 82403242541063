import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

import { dashboardChartReducer } from "./redux/dashboard/dashboardReducer";

import {
  accountRequestBackupReducer,
  accountRequestCloudReducer,
  accountRequestReloadReducer,
} from "./redux/account-requests/accountRequestReducer";

import { userReducer } from "./redux/user/userReducer";

import { cloudEyeReducer } from "./redux/cloudeye-user/cloudEyeReducer";
import { cloudEyeManagementReducer } from "./redux/cloudeye-management/cloudEyeManagementReducer";

import { securityReducer } from "./redux/security/securityReducer";

import { workspaceRequestReloadReducer } from "./redux/workspace-request/workspaceRequestReducer";

import { subscriptionDetailsReducer } from "./redux/trend-micro-onboarding/subscriptionDetailsReducer";

import { vpcReducer } from "redux/VPC/vpcReducer";

import { editValueReducer } from "./redux/edit-window/editValueReducer";

//aws host patching

import { patchAccountReducer } from "./redux/aws-host-patch-management/patchAccountReducer";

//billing
import { editableTableReducer } from "./redux/billing/editableTableReducer";
import { chatBotReducer } from "redux/chatbot/chatBotReducer";

const reducer = combineReducers({
  dashboardChart: dashboardChartReducer,
  accountRequestCloud: accountRequestCloudReducer,
  accountRequestReload: accountRequestReloadReducer,
  accountRequestBackup: accountRequestBackupReducer,
  user: userReducer,
  cloudEye: cloudEyeReducer,
  cloudEyeManagement: cloudEyeManagementReducer,
  security: securityReducer,
  workspaceRequestReload: workspaceRequestReloadReducer,
  trendMicroSubscriptions: subscriptionDetailsReducer,
  vpcRequests: vpcReducer,
  editValue: editValueReducer,
  awsHostPatching: patchAccountReducer,
  billing: editableTableReducer,
  chatBot: chatBotReducer,
});

const initialState = {
  user: { userInfo: null },
  dashboardChart: {
    serviceCanvas: false,
    moriartyCanvas: false,
    remedyTicketCanvas: false,
    prismaCanvas: false,
    trendCanvas: false,
  },
  accountRequestCloud: {
    accountRequestCloudType: "AWS",
  },
  accountRequestReload: {
    accountRequestReloadType: false,
  },
  accountRequestBackup: {
    accountRequestBackupType: false,
  },
  cloudEye: {
    cloudEyeUserTable: "",
    prismaCanvas: false,
    trendCanvas: false,
    cloudEyeApplicationName: "",
  },
  cloudEyeManagement: {
    cloudEyeManagementView: "",
  },
  security: {
    prismaCanvas: false,
    trendCanvas: false,
  },
  workspaceRequestReload: {
    workspaceRequestReloadType: false,
  },
  trendMicroSubscriptions: {
    trendMicroSubscriptionDetails: null,
    trendMicroSubscriptionReload: false,
  },
  vpcRequests: {
    environmentValue: null,
  },
  editValue: {
    tabErrorReduxValues: null,
    is_dirty_application_name: false,
  },
  awsHostPatching: {
    selectedInstancesList: null,
    providedTime: {},
    redirectAlert: false,
  },
  billing: {
    selectedMonth: 0,
    sortingBoolean: {
      columnId: null,
      bool: undefined,
    },
    isTableRefresh: false,
    showMandatory: {
      accountId: null,
      gsidTag: null,
      ioCode: null,
      opsCost: null,
    },
    duplicatedData: false,
    awsCreditsShowMandatory: {
      accountId: null,
      description: null,
      totalCredits: null,
      accontName: null,
      remainder: null,
    },
    gsidShowMandatory: {
      gsid: null,
      io: null,
      opco: null,
      subOpco: null,
      department: null,
      region: null,
      firstName: null,
      lastName: null,
      email: null,
    },
    modShowMandatory: {
      gsid: null,
      account: null,
      hostingModification: null,
      postModification: null,
      reason: null,
    },
    pamShowMandatory: {
      awsAccountNo: null,
      countOfServerName: null,
      sumOfUnitCost: null,
    },
    billingScreenDate: null,
  },
  chatBot: {
    botWindowStatus: false,
    accountList: null,
    isAccountIdPrompt: false,
    applicationType: null,
    isFirstText: false,
    isConformityAlert: false,
    isConformityResolution: false,
    isLoading: false,
  },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
