import React, { useState, useEffect, useCallback } from "react";
import { Row, Col, Form, Alert } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Select from "react-select";

// Import Form Component
import Loader from "components/Common/Loader";
import CustomModal from "components/Modal/CustomModal";
import {
  awsBackupRegionData,
  backUpScheduleData,
  optionalDropDownData,
} from "static-contents/region-data";

// Import Internal Components
import NewRequestSubmit from "./NewRequestSubmit";
import NewRequestCancel from "./NewRequestCancel";

// Helper
import { postApiCall } from "helper/postApiCall";

// Validator
import {
  accountDetailsValidate,
  awsBackUpValidate,
} from "../../Validator/validator";

// Static Data

import { CS_Hub_AWS_Account_Request_Admin } from "roles/roles";
import CountryDropdown from "components/Forms/CountryDropdown";
import ErrorAlert from "./ErrorAlert";
import WarningMessage from "../WarningMessage";
import CrossBackUpTextComponent from "../CrossBackUpTextComponent";
import DrBackUpComponent from "../DrBackUpComponent";
import BackUpPlanComponent from "../BackUpPlanComponent";

const AccountDetails = ({ step, setStep, formData, setFormData }) => {
  const { t } = useTranslation();
  // Redux State
  const user = useSelector((state) => state.user);
  const { userInfo } = user;
  // React Select Option
  const customStyles = {
    control: (base) => ({
      ...base,
      border: "1px solid red",
    }),
  };

  const [apiError, setApiError] = useState(null);

  // Set ID Token Globally
  const idToken = userInfo.idToken;

  // Response Data State

  const [loading, setLoading] = useState(true);
  const [responseData, setResponseData] = useState([]);

  // Component Form Data State
  const [accountNumberNameData, setAccountNumberNameData] = useState([]);
  const [accountNumberData, setAccountNumberData] = useState("");
  const [accountNameData, setAccountNameData] = useState("");
  const [getAccountsApiError, setGetAccountsApiError] = useState(null);

  const [accountNumberArray, setaccountNumberArray] = useState([]);

  // Form Submit State
  const [formSubmitLoading, setFormSubmitLoading] = useState(false);
  const [formSubmitSuccess, setFormSubmitSuccess] = useState(false);
  const [newRequestUid, setNewRequestUid] = useState(null);

  // Data Input Error State
  const [dataInputError, setDataInputError] = useState({});

  // Show Cancel Modal
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [addErrorMsg, setAddErrorMsg] = useState(null);

  // warning message
  const [warningMessage, setWarningMessage] = useState(null);
  const [isDisclaimerAgreed, setIsDisclaimerAgreed] = useState(false);
  const [isCrossBkupAgreed, setCrossBkupAgreed] = useState(false);
  const [isDrBkupAgreed, setDrBkupAgreed] = useState(false);
  const [isBkupPlanAgreed, setBkupPlanAgreed] = useState(false);

  // Close Modal Callback (Modal No Click Part)
  const closeModalCallback = useCallback((value) => {
    setShowCancelModal(value);
  }, []);

  // API Variables
  const apiUrl = `${process.env.REACT_APP_CUSTOM_ROLE_ACCOUNT_NUMBER_NAME}`;

  // Account Number Useeffect
  useEffect(() => {
    setGetAccountsApiError(null);
    setLoading(true);
    setAccountNumberNameData([]);

    const requestBody = {
      email: `${userInfo?.email}`,
      user_type: `${
        userInfo.groups.includes(CS_Hub_AWS_Account_Request_Admin)
          ? "admin"
          : "user"
      }`,
    };

    postApiCall(apiUrl, idToken, requestBody)
      .then((response) => {
        setAccountNumberNameData(response.data);

        const accountNumberOptions = response.data
          .filter((f) => f !== "")
          .map((d) => ({
            value: d.split(" - ")[0],
            label: d.split(" - ")[0],
          }));

        setaccountNumberArray(accountNumberOptions);

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setGetAccountsApiError(
          "Failed to Retrieve data, Contact Sony Global Cloud Team for Assistance"
        );
      });

    return () => {
      setAccountNumberNameData([]);
    };
  }, []);

  const handleContinue = (e) => {
    e.preventDefault();
    const formDataObj = {
      accountName: `${accountNameData}`,
      accountNumber: `${accountNumberData}`,
      drBackUp: `${
        formData?.accountDetails?.drBackUp === "yes" ? "true" : "false"
      }`,
      region: `${formData?.accountDetails?.region}`,
      crossBackUp: `${
        formData?.accountDetails?.crossBackUp === "yes" ? "true" : "false"
      }`,
      backUpPlan: `${formData?.accountDetails?.backUpPlan}`,
      type: "add",
    };

    const errors = awsBackUpValidate(formDataObj);
    console.log(errors);
    setDataInputError(errors);
    if (Object.keys(errors).length > 0) {
      return;
    } else {
      setFormSubmitLoading(true);
      // Form Submission
      const currentEpochTime = Date.now();

      const formSubmitApiUrl = `${process.env.REACT_APP_AWS_BACKUP_ADD}`;
      const requestBody = {
        ...formDataObj,
        requestStatus: "Pending",
        uid: `u-${currentEpochTime}`,
        submittedBy: userInfo.email,
      };
      postApiCall(formSubmitApiUrl, idToken, requestBody)
        .then((response) => {
          if (response?.data?.status === 400) {
            setShowErrorAlert(true);
            setAddErrorMsg(response?.data?.message);
            setFormSubmitLoading(false);
          }
          if (response.data.message === "Request added successfully") {
            setNewRequestUid(`u-${currentEpochTime}`);
            setFormSubmitSuccess(true);
            setFormSubmitLoading(false);
          }
        })
        .catch((err) => {
          setFormSubmitLoading(false);
          setApiError(t("failed_to_retrieve_data"));
        });
    }
  };
  // handle onchange
  const handleData = ({ target }) => {
    if (target.value === "") {
      setDrBkupAgreed(false);
      setCrossBkupAgreed(false);
      setBkupPlanAgreed(false);
    }
    setFormData({
      type: "UPDATE_BACKUP_PLAN",
      payload: { [target.name]: target.value },
    });
  };

  return (
    <>
      <Row></Row>

      <Form onSubmit={handleContinue}>
        {(loading || getAccountsApiError) && <Loader />}
        {loading === false && getAccountsApiError === null && (
          <>
            <Row className="px-3">
              <Col md={12} xs={12} className="px-3 pt-3">
                <h5 className="page-content-title mb-3">
                  {t("account_details")}
                </h5>
              </Col>
              <Col md={6} className="mt-2">
                <Form.Group className="mb-3" controlId="selectAccountNo">
                  <Form.Label>{t("*Account Number")}</Form.Label>
                  <Select
                    name="accountNumber"
                    placeholder="Account Number"
                    searchable={true}
                    value={{ label: accountNumberData }}
                    isClearable={true}
                    onChange={(value) => {
                      const accNum = value !== null ? value.value : "";
                      setAccountNumberData(accNum);

                      if (value !== null) {
                        for (let i = 0; i < accountNumberNameData.length; i++) {
                          let accNumName = accountNumberNameData[i];
                          let splited = accNumName.split(" - ")[0];
                          if (splited === accNum) {
                            let splitedAccName = accNumName.split(" - ")[1];
                            setAccountNameData(splitedAccName);
                          }
                        }
                      } else {
                        setAccountNameData("");
                      }
                    }}
                    options={accountNumberArray}
                    styles={dataInputError?.accountNumber && customStyles}
                  />

                  <p className="custom-error-message">
                    {dataInputError?.accountNumber}
                  </p>
                </Form.Group>
              </Col>
              <Col md={6} className="mt-2">
                <Form.Group className="mb-3" controlId="selectAccountName">
                  <Form.Label>{t("account_name")}</Form.Label>

                  <Form.Control
                    name="accountName"
                    type="text"
                    readOnly
                    placeholder={t("account_name")}
                    value={accountNameData}
                    isInvalid={dataInputError?.accountName ? true : false}
                  />

                  <Form.Control.Feedback type="invalid">
                    {dataInputError?.accountName}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6} className="mt-2 ">
                <CountryDropdown
                  label={"*Region"}
                  name="region"
                  placeholder="Select Region"
                  value={formData?.accountDetails?.region}
                  onChange={(e) => handleData(e)}
                  selectValues={awsBackupRegionData}
                  isInvalid={dataInputError?.region ? true : false}
                  invalidMessage={dataInputError?.region}
                />
              </Col>
              <Col md={12} xs={12} className="px-3 pt-3">
                <h5 className="page-content-title mb-3">
                  {t("Backup vaults and plans")}
                </h5>
              </Col>
              <p className="custom-no-data-message-text">
                Note :
                <strong>
                  {`Vault in the active region is enabled by default`}
                </strong>
              </p>
              <Col md={6} className="mt-2 ">
                <CountryDropdown
                  label={"Would you like to have a cross region backup"}
                  name="drBackUp"
                  placeholder="Select"
                  value={formData?.accountDetails?.drBackUp}
                  onChange={(e) => handleData(e)}
                  selectValues={optionalDropDownData}
                  onClick={() => {
                    if (!isDrBkupAgreed) {
                      setWarningMessage("drBackUp");
                      setIsDisclaimerAgreed(false);
                    }
                  }}
                />
              </Col>
              <Col md={6} className="mt-2 ">
                <CountryDropdown
                  label={"Would you like to have a cross account backup"}
                  name="crossBackUp"
                  placeholder="Select"
                  value={formData?.accountDetails?.crossBackUp}
                  onChange={(e) => handleData(e)}
                  selectValues={optionalDropDownData}
                  onClick={() => {
                    if (!isCrossBkupAgreed) {
                      setWarningMessage("crossBackUp");
                      setIsDisclaimerAgreed(false);
                    }
                  }}
                />
              </Col>
              <Col md={6} className="mt-2 ">
                <CountryDropdown
                  label={"*BackUp Plan"}
                  name="backUpPlan"
                  placeholder="Select"
                  value={formData?.accountDetails?.backUpPlan}
                  onChange={(e) => handleData(e)}
                  selectValues={backUpScheduleData}
                  isInvalid={dataInputError?.backUpPlan ? true : false}
                  invalidMessage={dataInputError?.backUpPlan}
                  onClick={() => {
                    if (!isBkupPlanAgreed) {
                      setWarningMessage("backUpPlan");
                      setIsDisclaimerAgreed(false);
                    }
                  }}
                />
              </Col>
            </Row>

            <Row className="px-3">
              <Col md={12}>
                <div className="d-flex justify-content-center">
                  {formSubmitLoading === false && apiError !== null && (
                    <Alert variant="danger">{apiError}</Alert>
                  )}
                </div>

                <div className="col-md-5 offset-md-7">
                  {formSubmitLoading === true && (
                    <div className="mb-3">
                      <Loader />
                    </div>
                  )}
                  {formSubmitLoading === false && (
                    <ul className="px-0 btn-ul">
                      <li>
                        <button
                          type="button"
                          className="teal-bordered-btn"
                          onClick={() => {
                            setShowCancelModal(true);
                          }}
                        >
                          {t("cancel")}
                        </button>
                      </li>
                      <li>
                        <button type="submit" className="teal-filled-btn">
                          {t("save_submit")}
                        </button>
                      </li>
                    </ul>
                  )}
                </div>
              </Col>
            </Row>
          </>
        )}
      </Form>
      {formSubmitSuccess && (
        <CustomModal
          showModal={true}
          children={<NewRequestSubmit refUid={newRequestUid} />}
        />
      )}
      {showCancelModal && (
        <CustomModal
          showModal={true}
          children={<NewRequestCancel closeModalParent={closeModalCallback} />}
        />
      )}
      {showErrorAlert && (
        <CustomModal
          showModal={true}
          children={
            <ErrorAlert
              addErrorMsg={addErrorMsg}
              setShowErrorAlert={setShowErrorAlert}
              setAddErrorMsg={setAddErrorMsg}
            />
          }
        />
      )}
      {warningMessage && (
        <CustomModal
          showModal={true}
          fileUpload={true}
          children={
            <WarningMessage
              children={
                warningMessage === "crossBackUp" ? (
                  <CrossBackUpTextComponent />
                ) : warningMessage === "drBackUp" ? (
                  <DrBackUpComponent />
                ) : (
                  warningMessage === "backUpPlan" && <BackUpPlanComponent />
                )
              }
              setAgreedPlan={
                warningMessage === "crossBackUp"
                  ? setCrossBkupAgreed
                  : warningMessage === "drBackUp"
                  ? setDrBkupAgreed
                  : warningMessage === "backUpPlan" && setBkupPlanAgreed
              }
              handleClose={setWarningMessage}
              setIsDisclaimerAgreed={setIsDisclaimerAgreed}
              isDisclaimerAgreed={isDisclaimerAgreed}
              setFormData={setFormData}
              fieldName={warningMessage}
            />
          }
        />
      )}
    </>
  );
};

export default AccountDetails;
