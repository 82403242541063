import React from "react";
import { Doughnut } from "react-chartjs-2";

const DoughnutComponent = ({
  title,
  titleCount,
  data,
  options,
  dgraphCount,
  inColor,
  outColor,
  firstValue,
  secondValue,
}) => (
  <>
    <div
      className={`doughnut-g rounded doughnut-g-${dgraphCount} bg-white-security px-0 ${
        dgraphCount === 2 ? "bg-blue" : ""
      }`}
    >
      <div className="d-flex p-3">
        <h6 className="fw-500 mb-4 d-flex justify-content-start flex-grow-0">
          {title}
        </h6>
        <h1 className="justify-content-end d-flex flex-grow-1 fw-bolder">
          {titleCount}
        </h1>
      </div>
      <div
        className={`d-flex flex-column ${
          dgraphCount === 1 ? "px-0" : ""
        } mx-3 align-items-center`}
      >
        <Doughnut data={data} options={options} />
      </div>
      <div className="bottom-count px-2 m-4 d-flex flex-row">
        {firstValue ? (
          <div className="d-flex flex-column justify-content-start flex-grow-1">
            <h3 className="fw-bold mb-0">
              {" "}
              <i
                style={{ color: `${inColor}` }}
                class="fa fa-circle fs-11 me-2"
              ></i>
              {firstValue}
            </h3>
            <p className="fw-500 fs-14 ps-1 ms-3">Now</p>
          </div>
        ) : null}
        {secondValue ? (
          <div className="d-flex flex-column justify-content-end flex-grow-0">
            <h3 className="fw-bold mb-0">
              {" "}
              <i
                style={{ color: `${outColor}` }}
                class="fa fa-circle fs-11 me-2"
              ></i>
              {secondValue}
            </h3>
            <p className="fw-500 fs-14 ps-1 ms-3">Return</p>
          </div>
        ) : null}
      </div>
    </div>
  </>
);

export default DoughnutComponent;
