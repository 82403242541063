import React from 'react';
import { Row, Col } from 'react-bootstrap';
// import moment from 'moment';

// Import Icons
import backicon from 'icons/back.svg';
import editicon from 'icons/edit.svg';

const ViewBox = ({ data, closeViewParent, openEditParent }) => {
  return (
    <div className='view-edit-box-conatiner pb-5'>
      <div className='view-edit-box-top-bar'>
        <div className='view-edit-box-back-title-container'>
          <img
            src={backicon}
            alt='Back'
            className='view-edit-box-back-btn'
            onClick={() => closeViewParent(false)}
          />
          <div>
            <h5 className='view-edit-box-title'>AWS Workspace Request</h5>
          </div>
        </div>
        <div className='view-edit-box-back-edit-container'>
          {data.requestStatus === 'Pending' && (
            <img
              src={editicon}
              alt='Edit'
              className='view-edit-box-edit-btn'
              onClick={() => {
                closeViewParent(false);
                openEditParent(true);
              }}
            />
          )}
        </div>
      </div>
      <div className='table-view-box-content mt-4 mb-5'>
        <Row>
          <Col md={12} className='mt-3'>
            <div className='account-request-view-title-container'>
              <p className='account-request-view-title'>
                Workspace Owner Details
              </p>
            </div>
          </Col>
          <Col md={12} className='mt-3'>
            <div className='account-request-view-table-container'>
              <table width='100%'>
                <tbody className='account-request-view-table-tbody'>
                  <tr>
                    <td
                      width='50%'
                      className='account-request-view-table-td-key'
                    >
                      Global ID
                    </td>
                    <td
                      width='50%'
                      className='account-request-view-table-td-value text-uppercase'
                    >
                      {data.WOgid}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width='50%'
                      className='account-request-view-table-td-key'
                    >
                      Email Address
                    </td>
                    <td
                      width='50%'
                      className='account-request-view-table-td-value text-uppercase'
                    >
                      {data.WOmail}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width='50%'
                      className='account-request-view-table-td-key'
                    >
                      First Name
                    </td>
                    <td
                      width='50%'
                      className='account-request-view-table-td-value text-capitalize'
                    >
                      {data.WOfirstName}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width='50%'
                      className='account-request-view-table-td-key'
                    >
                      Last Name
                    </td>
                    <td
                      width='50%'
                      className='account-request-view-table-td-value text-capitalize'
                    >
                      {data.WOlasttName}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width='50%'
                      className='account-request-view-table-td-key'
                    >
                      Phone No
                    </td>
                    <td
                      width='50%'
                      className='account-request-view-table-td-value text-capitalize'
                    >
                      {data.WOphno}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12} className='mt-3'>
            <div className='account-request-view-title-container'>
              <p className='account-request-view-title'>
                Workspace User Details
              </p>
            </div>
          </Col>
          <Col md={12} className='mt-3'>
            <div className='account-request-view-table-container'>
              <table width='100%'>
                <tbody className='account-request-view-table-tbody'>
                  <tr>
                    <td
                      width='50%'
                      className='account-request-view-table-td-key'
                    >
                      Global ID
                    </td>
                    <td
                      width='50%'
                      className='account-request-view-table-td-value text-uppercase'
                    >
                      {data.usergid}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width='50%'
                      className='account-request-view-table-td-key'
                    >
                      Email Address
                    </td>
                    <td
                      width='50%'
                      className='account-request-view-table-td-value text-uppercase'
                    >
                      {data.usermail}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width='50%'
                      className='account-request-view-table-td-key'
                    >
                      First Name
                    </td>
                    <td
                      width='50%'
                      className='account-request-view-table-td-value text-capitalize'
                    >
                      {data.userfirstName}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width='50%'
                      className='account-request-view-table-td-key'
                    >
                      Last Name
                    </td>
                    <td
                      width='50%'
                      className='account-request-view-table-td-value text-capitalize'
                    >
                      {data.userlasttName}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width='50%'
                      className='account-request-view-table-td-key'
                    >
                      Phone No
                    </td>
                    <td
                      width='50%'
                      className='account-request-view-table-td-value text-capitalize'
                    >
                      {data.userphno}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12} className='mt-3'>
            <div className='account-request-view-title-container'>
              <p className='account-request-view-title'>Billing Contact</p>
            </div>
          </Col>
          <Col md={12} className='mt-3'>
            <div className='account-request-view-table-container'>
              <table width='100%'>
                <tbody className='account-request-view-table-tbody'>
                  <tr>
                    <td
                      width='50%'
                      className='account-request-view-table-td-key'
                    >
                      Email Address
                    </td>
                    <td
                      width='50%'
                      className='account-request-view-table-td-value text-uppercase'
                    >
                      {data.BOmail}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width='50%'
                      className='account-request-view-table-td-key'
                    >
                      First Name
                    </td>
                    <td
                      width='50%'
                      className='account-request-view-table-td-value text-capitalize'
                    >
                      {data.BOfirstName}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width='50%'
                      className='account-request-view-table-td-key'
                    >
                      Last Name
                    </td>
                    <td
                      width='50%'
                      className='account-request-view-table-td-value text-capitalize'
                    >
                      {data.BOlasttName}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width='50%'
                      className='account-request-view-table-td-key'
                    >
                      Phone No
                    </td>
                    <td
                      width='50%'
                      className='account-request-view-table-td-value text-capitalize'
                    >
                      {data.BOphno}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12} className='mt-3'>
            <div className='account-request-view-title-container'>
              <p className='account-request-view-title'>Billing Info</p>
            </div>
          </Col>
          <Col md={12} className='mt-3'>
            <div className='account-request-view-table-container'>
              <table width='100%'>
                <tbody className='account-request-view-table-tbody'>
                  <tr>
                    <td
                      width='50%'
                      className='account-request-view-table-td-key'
                    >
                      Billing Operating Company
                    </td>
                    <td
                      width='50%'
                      className='account-request-view-table-td-value'
                    >
                      {data.billingopco}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width='50%'
                      className='account-request-view-table-td-key'
                    >
                      Billing Sub Operating Company
                    </td>
                    <td
                      width='50%'
                      className='account-request-view-table-td-value'
                    >
                      {data.billingsupco}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width='50%'
                      className='account-request-view-table-td-key'
                    >
                      Billing Department
                    </td>
                    <td
                      width='50%'
                      className='account-request-view-table-td-value'
                    >
                      {data.billingdepartment}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width='50%'
                      className='account-request-view-table-td-key'
                    >
                      Bill To
                    </td>
                    <td
                      width='50%'
                      className='account-request-view-table-td-value'
                    >
                      {data.billto}
                    </td>
                  </tr>
                  {data.io && (
                    <tr>
                      <td
                        width='50%'
                        className='account-request-view-table-td-key'
                      >
                        IO
                      </td>
                      <td
                        width='50%'
                        className='account-request-view-table-td-value'
                      >
                        {data.io}
                      </td>
                    </tr>
                  )}
                  {data.costcenter && (
                    <tr>
                      <td
                        width='50%'
                        className='account-request-view-table-td-key'
                      >
                        Cost Center
                      </td>
                      <td
                        width='50%'
                        className='account-request-view-table-td-value'
                      >
                        {data.costcenter}
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td
                      width='50%'
                      className='account-request-view-table-td-key'
                    >
                      Billing Region
                    </td>
                    <td
                      width='50%'
                      className='account-request-view-table-td-value text-capitalize'
                    >
                      {data.billingregion}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <div className='account-request-view-title-container'>
              <p className='account-request-view-title'>
                Worksapce Details/Configurations
              </p>
            </div>
          </Col>
          <Col md={12} className='mt-3'>
            <div className='account-request-view-table-container'>
              <table width='100%'>
                <tbody className='account-request-view-table-tbody'>
                  <tr>
                    <td
                      width='50%'
                      className='account-request-view-table-td-key'
                    >
                      Region
                    </td>
                    <td
                      width='50%'
                      className='account-request-view-table-td-value'
                    >
                      {data.region}
                    </td>
                  </tr>

                  <tr>
                    <td
                      width='50%'
                      className='account-request-view-table-td-key'
                    >
                      Bundle ID
                    </td>
                    <td
                      width='50%'
                      className='account-request-view-table-td-value'
                    >
                      {data.bundleId}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ViewBox;
