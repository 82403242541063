import React from "react";
import { useCreateSupportTicket, useHelpAndSupportListApi } from "./dataloader";
import { Col, Row } from "react-bootstrap";
import SpinnerLoader from "components/Common/SpinnerLoader";
import TextInput from "components/Forms/TextInput";
import { useFormik } from "formik";
import StaticDropdown from "components/Forms/StaticDropdown";
import { useOktaAuth } from "@okta/okta-react";
import Table from "components/Table";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import useInitScreen from "components/InitPage";
import * as yup from "yup";
import moment from "moment";
import "../../scss/help-support/help-support.scss";
import img1 from "../../images/sony_global_logo.png";
import img3 from "../../images/arrow-back.svg";
import backicon from 'icons/back.svg';


export default function HelpAndSupportIndex() {
  useInitScreen();
  const { t } = useTranslation();
  const [showCreateForm, setShowCreateForm] = React.useState(false);
  const { data = [], isFetching } = useHelpAndSupportListApi();
  const user = useSelector((state) => state.user);
  const { userInfo } = user;
  if (!userInfo) {
    return <SpinnerLoader />;
  }

  const columns = [
    {
      dataField: "incidentid",
      text: t("INCIDENT"),
      sort: true,
      searchable: true,
    },
    {
      dataField: "Summary",
      text: t("SUMMARY"),
      sort: true,
      searchable: true,
    },
    {
      dataField: "uid",
      text: t("CREATED ON"),
      sort: true,
      searchable: true,
      formatter: (cellContent, row) => {
        let dateConverted = moment(parseInt(row.uid.split("-")[1])).format(
          "MMM DD, YYYY hh:mm A"
        );
        return <span>{dateConverted}</span>;
      },
      sortFunc: (a, b, order) => {
        let dateConvertedA = moment(parseInt(a.split("-")[1])).format(
          "MMM DD, YYYY hh:mm A"
        );
        let dateConvertedB = moment(parseInt(b.split("-")[1])).format(
          "MMM DD, YYYY hh:mm A"
        );
        if (order === "asc") {
          return Date.parse(dateConvertedA) - Date.parse(dateConvertedB);
        } else if (order === "desc") {
          return Date.parse(dateConvertedB) - Date.parse(dateConvertedA);
        }
      },
    },
    {
      dataField: "Urgency",
      text: t("URGENCY"),
      sort: true,
      searchable: true,
    },
    {
      dataField: "Type",
      text: t("TICKET TYPE"),
      sort: true,
      searchable: true,
    },
    {
      dataField: "Category",
      text: t("ISSUE CATEGORY"),
      sort: true,
      searchable: true,
    },
    {
      dataField: "status",
      text: t("STATUS"),
      sort: true,
      searchable: true,
    },
  ];

  

  return (
    <div className="main p-3 pt-4">
      <h6 className="font-weight-bold mb-3">{t("Help & Support")}</h6>

      <div className="custom-card mb-4 p-4 d-flex justify-content-between align-items-center">
        <div class="img_div">
          <img src={img1} alt="img logo" />
        </div>
        <div class="desc_div">
          <p className="small text-secondary mb-4">
            {t("checkBackText")}
          </p>
          <button
            className="teal-filled-btn"
            onClick={() => setShowCreateForm(true)}
          >
            {t("Create a support request")}
          </button>
        </div>
      </div>
      {showCreateForm && (
        <HelpAndSupportFormModule setShowCreateForm={setShowCreateForm} />
      )}
      <div className="custom-card mb-4">
        {isFetching && <SpinnerLoader />}
        {!!data.length && (
          <Table
            data={data}
            columns={columns}
            text={t("Recent Support Request")}
            idField="incidentid"
          />
        )}
      </div>
    </div>
  );
}

const ImpactOptions = [
  { label: "1-Extensive/Widespread", value: "1-Extensive/Widespread" },
  { label: "2-Significant/Large", value: "2-Significant/Large" },
  { label: "3-Moderate/Limited", value: "3-Moderate/Limited" },
  { label: "4-Minor/Localized", value: "4-Minor/Localized" },
];

const UrgencyOptions = [
  { label: "1-Critical", value: "1-Critical" },
  { label: "2-High", value: "2-High" },
  { label: "3-Medium", value: "3-Medium" },
  { label: "4-Low", value: "4-Low" },
];

const IsuueCategoryOptions = [
  { label: "Okta", value: "Okta" },
  { label: "Jira or Confluence", value: "Jira or Confluence" },
  { label: "Security tools", value: "Security tools" },
  { label: "Network mesh", value: "Network mesh" },
  { label: "Others", value: "Others" },
];

const TicketTypeOptions = [
  { label: "Incident", value: "Incident" },
  { label: "Work Order", value: "Work Order" },
];

const stringRequiredValidation = yup.string().required("Required");

const validationSchema = yup.object({
  LastName: stringRequiredValidation,
  FirstName: stringRequiredValidation,
  Email: stringRequiredValidation,
  Impact: stringRequiredValidation,
  Urgency: stringRequiredValidation,
  Category: stringRequiredValidation,
  Type: stringRequiredValidation,
  Notes: stringRequiredValidation,
  Summary: stringRequiredValidation,
});

function HelpAndSupportFormModule({ setShowCreateForm }) {
  const { t } = useTranslation();
  const { mutateAsync: createTicket, isLoading } = useCreateSupportTicket();
  const { authState } = useOktaAuth();
  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  const { handleSubmit, getFieldProps, errors, touched } = useFormik({
    validationSchema,
    initialValues: {
      uid: `u-${Date.now()}`,
      FirstName: userInfo.given_name || "",
      LastName: userInfo.family_name || "",
      Email: userInfo.email || "",
      Impact: "",
      Urgency: "",
      Category: "",
      Type: "",
      Notes: "",
      Summary: "",
    },
    onSubmit: async (values) => {
      await createTicket({ ...values, idToken: authState.idToken.idToken });
      setShowCreateForm(false);
    },
  });
  return (
    <div className="view-edit-box-conatiner pb-5">
      <div className="view-edit-box-top-bar">
        <div className="view-edit-box-back-title-container">
          <img
            src={backicon}
            alt="Back"
            className="view-edit-box-back-btn"
            onClick={() => setShowCreateForm(false)}
          />
          <h5 className="view-edit-box-title">{t("New Support Request")}</h5>
        </div>
      </div>

      <div className="mt-4">
        {isLoading && <SpinnerLoader />}
        <Row>
          <Col md={6}>
            <TextInput
              isDisabled={true}
              label={t("First Name:")}
              {...getFieldProps("FirstName")}
              placeholder="First Name"
              isInvalid={errors.FirstName && touched.FirstName}
              invalidMessage={errors.FirstName}
            />
          </Col>
          <Col md={6}>
            <TextInput
              isDisabled={true}
              label={t("Last Name:")}
              {...getFieldProps("LastName")}
              placeholder="Last Name"
              isInvalid={errors.LastName && touched.LastName}
              invalidMessage={errors.LastName}
            />
          </Col>
          <Col md={6}>
            <TextInput
              isDisabled={true}
              label={t("Email")+":"}
              {...getFieldProps("Email")}
              placeholder="Email"
              isInvalid={errors.Email && touched.Email}
              invalidMessage={errors.Email}
            />
          </Col>
          <Col md={6}>
            <StaticDropdown
              label={t("Impact")+":"}
              {...getFieldProps(t("Impact"))}
              defaultSelectMessage="Select Value"
              selectValues={ImpactOptions}
              isInvalid={errors.Impact && touched.Impact}
              invalidMessage={errors.Impact}
            />
          </Col>
          <Col md={6}>
            <StaticDropdown
              label={t("Urgency")+":"}
              {...getFieldProps("Urgency")}
              defaultSelectMessage="Select Value"
              selectValues={UrgencyOptions}
              isInvalid={errors.Urgency && touched.Urgency}
              invalidMessage={errors.Urgency}
            />
          </Col>
          <Col md={6}>
            <StaticDropdown
              label={t("Issue Category")+":"}
              {...getFieldProps("Category")}
              defaultSelectMessage="Select Value"
              selectValues={IsuueCategoryOptions}
              isInvalid={errors.Category && touched.Category}
              invalidMessage={errors.Category}
            />
          </Col>
          <Col md={6}>
            <StaticDropdown
              label={t("Ticket Type")+":"}
              {...getFieldProps("Type")}
              defaultSelectMessage="Select Value"
              selectValues={TicketTypeOptions}
              isInvalid={errors.Type && touched.Type}
              invalidMessage={errors.Type}
            />
          </Col>
          <Col md={6}>
            <TextInput
              as="textarea"
              label={t("Notes")+":"}
              {...getFieldProps("Notes")}
              placeholder="Notes"
              isInvalid={errors.Notes && touched.Notes}
              invalidMessage={errors.Notes}
            />
          </Col>
          <Col md={6}>
            <TextInput
              as="textarea"
              label={t("Summary")+":"}
              {...getFieldProps("Summary")}
              placeholder="Summary"
              isInvalid={errors.Summary && touched.Summary}
              invalidMessage={errors.Summary}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12} className="d-flex justify-content-end">
            <ul className="px-0 btn-ul">
              <li>
                <button
                  type="button"
                  className="teal-bordered-btn"
                  onClick={() => {
                    setShowCreateForm(false);
                  }}
                >
                  {t("Cancel")}
                </button>
              </li>
              <li>
                <button
                  disabled={isLoading}
                  type="button"
                  className="teal-filled-btn"
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  {t("submit")}
                </button>
              </li>
            </ul>
          </Col>
        </Row>
      </div>
    </div>
  );
}


