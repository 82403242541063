import React from 'react';

// Import Icons
import backicon from 'icons/back.svg';
import editicon from 'icons/edit.svg';

// Roles
import {
  CS_Hub_AWS_Account_Request_Admin,
  CS_Hub_AWS_Dynamo_Search_Request_Admin,
} from 'roles/roles';

const ViewEditContainer = ({
  data,
  title,
  closeContainer,
  viewEditType,
  userInfo,
  children,
}) => {
  return (
    <div className='view-edit-box-conatiner pb-5'>
      <div className='view-edit-box-top-bar'>
        <div className='view-edit-box-back-title-container'>
          <img
            src={backicon}
            alt='Back'
            className='view-edit-box-back-btn'
            onClick={() => closeContainer('')}
          />
          <h5 className='view-edit-box-title'>{title} Account Details</h5>

          <span className='view-edit-box-accountid mx-3'>
            Account ID: {data.accountnumber}
          </span>
        </div>

        {viewEditType === 'View' &&
          (userInfo.groups.includes(CS_Hub_AWS_Account_Request_Admin) ||
            userInfo.groups.includes(CS_Hub_AWS_Dynamo_Search_Request_Admin)) &&
          data.accstatus !== 'SUSPENDED' && (
            <></>
            // <div className='view-edit-box-back-edit-container'>
            //   <img
            //     src={editicon}
            //     alt='Edit'
            //     className='view-edit-box-edit-btn'
            //     onClick={() => {
            //       closeContainer('Edit');
            //     }}
            //   />
            // </div>
          )}
      </div>
      {children}
    </div>
  );
};

export default ViewEditContainer;
