import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Row, Col, Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

// Helper
import { postApiCall } from "helper/postApiCall";

// Internal Component
import SpinnerLoader from "components/Common/SpinnerLoader";
import DangerAlert from "components/Message/DangerAlert";

const PrismaTopAlert = () => {
  // Redux State
  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  // API State
  const [apiLoading, setApiLoading] = useState(true);
  const [apiResponseData, setApiResponseData] = useState([]);
  const [apiError, setApiError] = useState(null);

  // API Variable
  const apiUrl = `${process.env.REACT_APP_SECURITY_PRISMA_TOP_ALERT}`;
  const idToken = `${userInfo.idToken}`;
  const requestBody = {};

  useEffect(() => {
    setApiLoading(true);
    postApiCall(apiUrl, idToken, requestBody)
      .then((response) => {
        let tempArray = [];
        for (const property in response.data) {
          let tempObj = {};
          tempObj["Account"] = property;
          tempObj["Alerts"] = response.data[property];
          tempArray.push(tempObj);
        }
        setApiResponseData(tempArray);
        setApiLoading(false);
      })
      .catch((err) => {
        setApiError(t("failed_to_retrieve_data"));
        setApiLoading(false);
      });
    return () => {
      setApiResponseData([]);
    };
  }, []);
  const { t } = useTranslation();
  return (
    <div className="custom-card">
      {apiLoading && (
        <Row>
          <Col md={12}>
            <SpinnerLoader />
          </Col>
        </Row>
      )}
      {apiLoading === false && apiError !== null && (
        <Row>
          <Col md={12}>
            <DangerAlert message={apiError} />
          </Col>
        </Row>
      )}
      {apiLoading === false && apiError === null && (
        <Row>
          <Col md={12}>
            <div className="subtitle-text mb-2">
              {t("top_3_highest_alerts")}
              <br />
              <span className="splunk-link mx-0">
                Data shown in range -{" "}
                {apiResponseData.find((d) => d.Account === "daterange")?.Alerts}
              </span>
            </div>
            {apiResponseData.length > 0 && (
              <>
                <Table
                  responsive
                  className=" mb-0 table-centered table-nowrap table-borderless"
                >
                  <thead className="offcanvas-details-table-head-font">
                    <tr className="mb-2">
                      <td>{t("ACCOUNT")}</td>
                      <td>{t("ALERTS")}</td>
                    </tr>
                  </thead>
                  <tbody className="offcanvas-details-table-body-font">
                    {apiResponseData.map((data, index) =>
                      data.Account !== "daterange" ? (
                        <tr key={index} className="mb-3">
                          <td>{data.Account}</td>
                          <td>{data.Alerts}</td>
                        </tr>
                      ) : null
                    )}
                  </tbody>
                </Table>
              </>
            )}
          </Col>
        </Row>
      )}
    </div>
  );
};

export default PrismaTopAlert;
