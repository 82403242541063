import backicon from "icons/back.svg";
import DangerAlert from "components/Message/DangerAlert";

export default function Slider({
  headerText = "",
  noDataMessage = "",
  children,
  error = "",
  onClose = () => {},
}) {
  return (
    <div className="view-edit-box-conatiner pb-5" style={{ width: "640px" }}>
      <div className="view-edit-box-top-bar">
        <div className="view-edit-box-back-title-container">
          <img
            src={backicon}
            alt="Back"
            className="view-edit-box-back-btn"
            onClick={onClose}
          />
          <div>
            <h5 className="view-edit-box-title">{headerText}</h5>
          </div>
        </div>
      </div>

      {error && <DangerAlert message={error} />}

      <div className="custom-error-message text-center mt-4">
        {noDataMessage}
      </div>

      <div className="table-view-box-content mt-4 mb-5">{children}</div>
      <div className="d-flex justify-content-end align-items-baseline"></div>
    </div>
  );
}
