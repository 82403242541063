import useInitScreen from "components/InitPage";
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import PageContentSidebar from "components/PageContent/PageContentSidebar";
import awsLogo from "images/aws-logo.svg";
import azureLogo from "images/azure-logo.svg";
import { useResourceQueryApi } from "./dataloader";
import { useOktaAuth } from "@okta/okta-react";
import Table from "components/Table";
import SpinnerLoader from "components/Common/SpinnerLoader";
import { useFormik } from "formik";
import TextInput from "components/Forms/TextInput";
import { Form } from "react-bootstrap";
import StaticDropdown from "components/Forms/StaticDropdown";

const tagNameOptions = [{ label: "GSID", value: "GSID" }];

const resourceTypeOpts = [
  { label: "Lambda", value: "Lambda" },
  { label: "DynamoDB", value: "DynamoDB" },
  { label: "EC2", value: "EC2" },
  { label: "RDS", value: "RDS" },
  { label: "API Gateway", value: "API Gateway" },
];

const resourceVersionOptMap = {
  Lambda: [
    { label: "Python 3.6", value: "Python 3.6" },
    { label: "Python 3.7", value: "Python 3.7" },
    { label: "Python 3.8", value: "Python 3.8" },
    { label: "Python 3.9", value: "Python 3.9" },
  ],
  DynamoDB: [],
  "API Gateway": [],
  EC2: [{ label: "Amazon Linux2", value: "Amazon Linux2" }],
  RDS: [
    { label: "MySQL", value: "MySQL" },
    { label: "Oracle", value: "Oracle" },
  ],
};

export default function ResourceQuery() {
  useInitScreen();
  return (
    <Container fluid>
      <Row>
        <Col md={2} className="px-0">
          <PageContentSidebar
            firstLogoLink="/aws-resource-query"
            firstLogoImg={awsLogo}
            fisrtLogoAlt="AWS"
            firstLogoFirstMatchingLink="aws-resource-query"
            secondLogoLink="/azure-resource-query"
            secondLogoImg={azureLogo}
            secondLogoAlt="Azure"
            secondLogoFirstMatchingLink="azure-resource-query"
            showLinks="no"
          />
        </Col>
        <Col md={10}>
          <ResourceQueryMain />
        </Col>
      </Row>
    </Container>
  );
}

function ResourceQueryMain() {
  const { data = [], mutateAsync, isLoading } = useResourceQueryApi();
  const { authState } = useOktaAuth();

  const { handleSubmit, getFieldProps, values, setFieldValue } = useFormik({
    initialValues: { queryType: "", resourcetype: "", resourceversion: "" },
    onSubmit: async (values) => {
      const queryObject =
        values.queryType === "tag"
          ? { tagkey: values.tagkey?.trim(), tagvalue: values.tagvalue?.trim() }
          : {
              resourcetype: values.resourcetype,
              resourceversion: values.resourceversion,
            };
      if (
        (values.queryType === "tag" && values.tagkey?.trim() && values.tagvalue?.trim()) ||
        values.queryType === "resource"
      )
        mutateAsync({
          ...queryObject,
          idToken: authState.idToken.idToken,
        });
    },
  });

  return (
    <div className="rqContainer">
      {isLoading && <SpinnerLoader />}
      <p className="resourceTitle">Resource Query</p>
      <div className="radioContainer">
        <Row className="mb-3">
          <Col md={4}>
            <Form.Check
              label="Tag"
              type="radio"
              id={"tag"}
              checked={values.queryType === "tag"}
              {...getFieldProps("tag")}
              onChange={(e) => {
                setFieldValue("queryType", "tag");
              }}
            />
          </Col>
          <Col md={4}>
            <Form.Check
              label="Resource"
              type="radio"
              id={"resource"}
              checked={values.queryType === "resource"}
              {...getFieldProps("resource")}
              onChange={(e) => {
                setFieldValue("queryType", "resource");
              }}
            />
          </Col>
        </Row>
      </div>

      <div className="rdSelContainer">
        <Row>
          {values.queryType === "resource" && (
            <React.Fragment>
              <Col md={4}>
                <StaticDropdown
                  label="Resource Type:"
                  name="resourcetype"
                  {...getFieldProps("resourcetype")}
                  defaultSelectMessage="Select Value"
                  selectValues={resourceTypeOpts}
                />
              </Col>

              <Col md={4}>
                <StaticDropdown
                  name="resourceversion"
                  label="Resource Type Version:"
                  {...getFieldProps("resourceversion")}
                  defaultSelectMessage="Select Value"
                  disabled={
                    resourceVersionOptMap[values?.resourcetype]?.length == 0
                  }
                  selectValues={
                    resourceVersionOptMap[values?.resourcetype] || []
                  }
                />
              </Col>
            </React.Fragment>
          )}

          {values.queryType === "tag" && (
            <React.Fragment>
              <Col md={4}>
                <TextInput
                  label="Tag Name:"
                  {...getFieldProps("tagkey")}
                  defaultSelectMessage="Select Value"
                  selectValues={[]}
                />
              </Col>

              <Col md={4}>
                <TextInput
                  label="Tag Value:"
                  {...getFieldProps("tagvalue")}
                  defaultSelectMessage="Select Value"
                  selectValues={[]}
                />
              </Col>
            </React.Fragment>
          )}
          {values.queryType && (
            <Col md={4}>
              <div>
                <button
                  className="teal-filled-btn rqBtnStyle"
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  Search
                </button>
              </div>
            </Col>
          )}
        </Row>
      </div>

      {!!data.length && (
        <div className="mt-5 mb-3 rqResultContainer">
          <Table
            striped
            bordered
            columns={columns}
            data={data}
            idField="resid"
            text="Resource Table"
            downloadEnabled
          />
        </div>
      )}
    </div>
  );
}

const columns = [
  {
    dataField: "accnum",
    text: "ACCOUNT NUMBER",
    sort: true,
    searchable: true,
  },
  {
    dataField: "acctname",
    text: "ACCOUNT NAME",
    sort: true,
    searchable: true,
  },
  {
    dataField: "resname",
    text: "RESOURCE NAME",
    sort: true,
    searchable: true,
  },
  {
    dataField: "version",
    text: "VERSION",
    sort: true,
    searchable: true,
  },
];
