import React from "react";

const FaqPrompt = (props) => {
  const options = [
    {
      text: "What is Golden AMI?",
      handler: () => props.actionProvider.handleFaq("goldenAMI"),
      id: 4,
    },
    {
      text: "Resource tagging Policy",
      handler: () => props.actionProvider.handleFaq("tagPolicy"),
      id: 5,
    },
    {
      text: "How can i get GitLab Account?",
      handler: () => props.actionProvider.handleFaq("getGitLab"),
      id: 14,
    },
    {
      text: "How to activate my blocked GitLab Account?",
      handler: () => props.actionProvider.handleFaq("gitlabAccount"),
      id: 15,
    },
    {
      text: "How to request access to my AWS Account?",
      handler: () => props.actionProvider.handleFaq("awsAccess"),
      id: 16,
    },
    {
      text: "How to add my aws account to Network Mesh?",
      handler: () => props.actionProvider.handleFaq("networkMesh"),
      id: 17,
    },
  ];

  const optionsMarkup = options.map((option) => {
    return (
      <button
        key={option.id}
        onClick={option.handler}
        className="option-button"
      >
        {option.text}
      </button>
    );
  });

  return (
    <>
      <div className="options-container">{optionsMarkup}</div>
    </>
  );
};

export default FaqPrompt;
