import React, { useEffect, useState } from "react";
import Chatbot from "react-chatbot-kit";
import config from "../utils/config.js";
import MessageParser from "../utils/MessageParser.js";
import ActionProvider from "../utils/ActionProvider.js";
import { useDispatch, useSelector } from "react-redux";
import BotIconComponent from "../components/BotIconComponent.js";
import { CS_Hub_AWS_Account_Request_Admin } from "roles/roles.js";
import { postApiCall } from "helper/postApiCall.js";
import { ACCOUNT_LIST } from "redux/chatbot/chatBotConstant.js";
import WarningMessage from "../components/WarningMessage.js";
import CustomModal from "components/Modal/CustomModal.js";
import { Modal } from "react-bootstrap";

const ChatComponent = ({ state }) => {
  const dispatch = useDispatch();
  const showBot = useSelector((state) => state.chatBot.botWindowStatus);
  const isLoading = useSelector((state) => state.chatBot.isLoading);
  const isConformityAlert = useSelector(
    (state) => state.chatBot.isConformityAlert
  );
  const isConformityResolution = useSelector(
    (state) => state.chatBot.isConformityResolution
  );
  const user = useSelector((state) => state.user);
  const { userInfo } = user;
  const idToken = `${userInfo?.idToken}`;

  const getUserName = () => {
    const firstName = userInfo?.name.split(" ")[0][0];
    const lastName = userInfo?.name.split(" ")[1][0];
    return `${firstName}${lastName}`;
  };
  const userName = getUserName();
  const [accountNumberArray, setaccountNumberArray] = useState([]);

  const validateInput = (message) => {
    if (message && !isLoading) {
      return true;
    }
  };

  // Account Number Useeffect
  // API Variables
  const apiUrl = `${process.env.REACT_APP_CUSTOM_ROLE_ACCOUNT_NUMBER_NAME}`;
  useEffect(() => {
    if (!accountNumberArray?.length && userInfo?.email) {
      setaccountNumberArray([]);
      dispatch({
        type: ACCOUNT_LIST,
        payload: [],
      });
      const requestBody = {
        email: `${userInfo?.email}`,
        user_type: `${
          userInfo?.groups?.includes(CS_Hub_AWS_Account_Request_Admin)
            ? "admin"
            : "user"
        }`,
      };

      postApiCall(apiUrl, idToken, requestBody)
        .then((response) => {
          if (response.data?.length) {
            const accountNumberOptions = response.data
              .filter((f) => f !== "")
              .map((d) => ({
                value: d.split(" - ")[0],
                label: d.split(" - ")[0],
              }));

            setaccountNumberArray(accountNumberOptions);
            dispatch({
              type: ACCOUNT_LIST,
              payload: accountNumberOptions,
            });
          } else {
            setaccountNumberArray([]);
            dispatch({
              type: ACCOUNT_LIST,
              payload: [],
            });
          }
        })
        .catch((err) => {
          console.log("error", err);
        });
    }
  }, [userInfo?.groups]);

  return (
    <div className="chat--window position-relative">
      <div
        style={showBot ? { visibility: "visible" } : { visibility: "hidden" }}
      >
        <Chatbot
          config={config({ userName })}
          validator={validateInput}
          messageParser={MessageParser}
          actionProvider={ActionProvider}
          headerText="Ask Neo"
          placeholderText={`${
            isConformityAlert
              ? "Ask for conformity alert enumeration"
              : isConformityResolution
              ? "Ask for alert resolution"
              : " Ask here...."
          }`}
        />
      </div>

      {!showBot && <BotIconComponent />}
    </div>
  );
};

export default ChatComponent;
