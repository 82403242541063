import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';

import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  PaginationTotalStandalone,
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

// Components
import Loader from 'components/Common/Loader';
import DangerAlert from 'components/Message/DangerAlert';

import CIDRApproveBox from './CIDRApproveBox';
import CIDRDeclineBox from './CIDRDeclineBox';
import CIDRCancelBox from './CIDRCancelBox';
import CIDRViewBox from './CIDRViewBox';
import CIDREditBox from './CIDREditBox';
import CIDRDownload from './CIDRDownload';

// Helper
import { postApiCall } from 'helper/postApiCall';

// Import Icon
import dots from 'icons/3-dots.svg';
import { Link } from 'react-router-dom';

import {
  CS_Hub_AWS_Account_Request_Admin,
  Everyone_Role,
  CS_Hub_AWS_Account_CIDR_Security,
} from 'roles/roles';

const AWSTable = () => {
  // Redux State
  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  // API URL
  const apiUrl = `${process.env.REACT_APP_AWS_CIDR_VIEW_REQUEST_URL}`;

  // ID Token
  const idToken = `${userInfo.idToken}`;

  // Request Body
  let requestBody = '';
  if (
    userInfo.groups.includes(Everyone_Role) ||
    userInfo.groups.includes(CS_Hub_AWS_Account_CIDR_Security)
  ) {
    requestBody = `{"submittedBy":"${userInfo.preferred_username}", "type_of_user":0}`;
  }
  if (userInfo.groups.includes(CS_Hub_AWS_Account_Request_Admin)) {
    requestBody = `{"type_of_user":1}`;
  }

  // Table State
  const [Loading, setLoading] = useState(true);
  const [responseData, setResponseData] = useState([]);
  const [apiError, setApiError] = useState(null);

  const fetchTableData = useCallback(() => {
    setLoading(true);
    postApiCall(apiUrl, idToken, requestBody)
      .then((response) => {
        setResponseData(response.data);
        setLoading(false);
      })
      .catch((err) => {
        setApiError(
          'Failed to Retrieve data, Contact Sony Global Cloud Team for Assistance'
        );
        setLoading(false);
      });
  }, [apiUrl, idToken, requestBody]);

  // Option Part
  // ! Option Part
  const [options, setOptions] = useState({
    showOption: null,
    selectedOptionUID: null,
    selectedOptionData: {},
  });

  // Option Click Handler
   function optionHandler(selectedOptionDataValues, isMenuOpen = null) {
    if (options.showOption || isMenuOpen) {
      setOptions({
        showOption: false,
        selectedOptionUID: selectedOptionDataValues.uid,
        selectedOptionData: selectedOptionDataValues,
      });
    } else {
      setOptions({
        showOption: true,
        selectedOptionUID: selectedOptionDataValues.uid,
        selectedOptionData: selectedOptionDataValues,
      });
    }
  }

  // ! Approve Box State & Function & CallBack
  // ! Approve Box State
  const [showApproveBox, setshowApproveBox] = useState(false);

  // ! Approve (No Callback)
  const approveNoCallback = useCallback((value) => {
    setshowApproveBox(value);
  }, []);

  // ! Approve (Success)
  const approvedCallback = useCallback(
    (value) => {
      if (value) {
        setOptions({
          selectedOptionUID: null,
        });
        fetchTableData();
      }
    },
    [fetchTableData]
  );

  // ! Decline Box State & Function & CallBack
  // ! Decline Box State
  const [showDeclineBox, setshowDeclineBox] = useState(false);

  // ! Decline (No Callback)
  const declineNoCallback = useCallback((value) => {
    setshowDeclineBox(value);
  }, []);

  // ! Approve (Success)
  const declinedCallback = useCallback(
    (value) => {
      if (value) {
        setOptions({
          selectedOptionUID: null,
        });
        fetchTableData();
      }
    },
    [fetchTableData]
  );

  // ! Cancel Box State & Function & CallBack
  // ! Cancel Box State
  const [showCancelBox, setshowCancelBox] = useState(false);

  // ! Cancel (No Callback)
  const cancelNoCallback = useCallback((value) => {
    setshowCancelBox(value);
  }, []);

  // ! Cancel (Success)
  const canceledCallback = useCallback(
    (value) => {
      if (value) {
        setOptions({
          selectedOptionUID: null,
        });
        fetchTableData();
      }
    },
    [fetchTableData]
  );

  // ! View Box State & Function & Callback
  // ! View Box State
  const [showViewBox, setshowViewBox] = useState(false);

  // ! View (Back Callback)
  const viewBackCallback = useCallback((value) => {
    setshowViewBox(value);
  }, []);

  // ! Edit Box State & Function & Callback
  // ! Edit Box State
  const [showEditBox, setshowEditBox] = useState(false);

  // ! Edit (Back Callback)
  const editBackCallback = useCallback((value) => {
    setshowEditBox(value);
  }, []);

  // ! Edit (Show Callback)
  const editShowCallback = useCallback((value) => {
    setshowEditBox(value);
  }, []);
  const {t}= useTranslation();

  const refreshDataCallback = useCallback(
    (value) => {
      if (value === true) {
        fetchTableData();
      }
    },
    [fetchTableData]
  );

  // Data Tables
  const columns = [
    {
      dataField: 'dummyCreatedOn',
      isDummyField: true,
      text: t('request_id'),
      sort: true,
      formatter: (cell, row) => {
        return <span className='text-uppercase'>{`${row.uid}`}</span>;
      },
    },
    {
      dataField: 'accountName',
      text: t('account_name'),
      sort: true,
    },
    {
      dataField: 'region',
      text: t('region'),
      sort: true,
    },
    {
      dataField: 'ip',
      text: t('ip'),
      sort: true,
      formatter: (cell, row) => {
        let splitbyHifen = row.ip.split(' - ')[0];
        let splitbySpace = splitbyHifen.split(' ')[0];
        return <span className='text-uppercase'>{`${splitbySpace}`}</span>;
      },
    },
    {
      dataField: 'allocCIDR',
      text: t('cidr_block'),
      sort: true,
    },
    {
      dataField: 'uid',
      text: t('created_on'),
      sort: true,
      formatter: (cellContent, row) => {
        let dateConverted = moment(parseInt(row.uid.split('-')[1])).format(
          'MMM DD, YYYY hh:mm A'
        );
        return <span>{dateConverted}</span>;
      },
      sortFunc: (a, b, order) => {
        let dateConvertedA = moment(parseInt(a.split('-')[1])).format(
          'MMM DD, YYYY hh:mm A'
        );
        let dateConvertedB = moment(parseInt(b.split('-')[1])).format(
          'MMM DD, YYYY hh:mm A'
        );
        if (order === 'asc') {
          return Date.parse(dateConvertedA) - Date.parse(dateConvertedB);
        } else if (order === 'desc') {
          return Date.parse(dateConvertedB) - Date.parse(dateConvertedA);
        }
      },
    },
    {
      dataField: 'requestStatus',
      text: t('status'),
      sort: true,
      formatter: (cellContent, row) => {
        if (row.requestStatus === 'Approved') {
          return (
            <div
              className='approved-badge text-capitalize'
              style={{ fontSize: '11px' }}
            >
              {row.requestStatus}
            </div>
          );
        }
        if (row.requestStatus === 'Processing') {
          return (
            <div
              className='approved-badge text-capitalize'
              style={{ fontSize: '11px' }}
            >
              {row.requestStatus}
            </div>
          );
        }
        if (row.requestStatus === 'Pending') {
          return (
            <div
              className='pending-badge text-capitalize'
              style={{ fontSize: '11px' }}
            >
              {row.requestStatus}
            </div>
          );
        }
        if (
          row.requestStatus === 'Cancelled' ||
          row.requestStatus === 'Canceled'
        ) {
          return (
            <div
              className='canceled-badge text-capitalize'
              style={{ fontSize: '11px' }}
            >
              {row.requestStatus}
            </div>
          );
        }
        if (row.requestStatus === 'Declined') {
          return (
            <div
              className='declined-badge text-capitalize'
              style={{ fontSize: '11px' }}
            >
              {row.requestStatus}
            </div>
          );
        }
      },
    },
    {
      dataField: 'dummyActionField',
      text: '',
      formatExtraData: options,
      formatter: (cellContent, row) => {
        return (
          <div
            className='custom-table-option-conatiner'
            tabIndex="0"
            onBlur={() => {
              optionHandler(row, 'open');
            }}
            onClick={() => {
              optionHandler(row);
            }}
          >
            <img src={dots} alt='action' className='p-2' />
            {options.showOption === true &&
              options.selectedOptionUID === row.uid && (
                <div className='custom-table-option'>
                  <ul>
                    <li
                      onClick={() => {
                        setshowViewBox(true);
                      }}
                    >
                      {t('view')}
                    </li>

                    {userInfo.groups.includes(
                      CS_Hub_AWS_Account_Request_Admin
                    ) &&
                      row.requestStatus === 'Pending' && (
                        <>
                          <li
                            onClick={() => {
                              setshowApproveBox(true);
                            }}
                          >
                            {t('approve')}
                          </li>
                          <li
                            onClick={() => {
                              setshowDeclineBox(true);
                            }}
                          >
                            {t('decline')}
                          </li>
                        </>
                      )}

                    {(userInfo.groups.includes(
                      CS_Hub_AWS_Account_Request_Admin
                    ) ||
                      userInfo.groups.includes('CS Hub - Security') ||
                      userInfo.groups.includes(Everyone_Role)) &&
                      row.requestStatus === 'Pending' && (
                        <>
                          <li
                            onClick={() => {
                              setshowEditBox(true);
                            }}
                          >
                            {t('edit')}
                          </li>
                          <li
                            onClick={() => {
                              setshowCancelBox(true);
                            }}
                          >
                            {t('cancel_msg')}
                          </li>
                        </>
                      )}
                  </ul>
                </div>
              )}
          </div>
        );
      },
    },
  ];

  const defaultSorted = [
    {
      dataField: 'uid',
      order: 'desc',
    },
  ];

  const customTotal = (from, to, size) => (
    <span className='react-bootstrap-table-pagination-total'>
      {t("showing_results",{from,to,size})}
    </span>
  );

  const pageOptions = {
    sizePerPage: 10,
    totalSize: responseData.length,
    custom: true,
    paginationTotalRenderer: customTotal,
  };

  const { SearchBar } = Search;

  useEffect(() => {
    fetchTableData();
    return () => {
      setResponseData([]);
    };
  }, [fetchTableData]);

  return (
    <>
      {Loading && <Loader />}

      {Loading === false && apiError !== null && (
        <Row>
          <Col md={12} className='mt-3'>
            <DangerAlert message={apiError} />
          </Col>
        </Row>
      )}

      {Loading === false && apiError === null && (
        <PaginationProvider
          pagination={paginationFactory(pageOptions)}
          keyField='id'
          columns={columns}
          data={responseData}
        >
          {({ paginationProps, paginationTableProps }) => (
            <ToolkitProvider
              keyField='uid'
              columns={columns}
              data={responseData}
              search
            >
              {(toolkitProps) => (
                <>
                  <Row>
                    <Col md={3} className='mt-3 d-flex align-items-center'>
                      <p className='maintitle-text'>{t("aws_cidr_req")}</p>
                    </Col>

                    <Col md={1} className='mt-3'>
                      <button
                        className='teal-bordered-btn w-100'
                        type='button'
                        onClick={() => {
                          fetchTableData();
                        }}
                      >
                        <i className='fa fa-refresh'></i>
                      </button>
                    </Col>

                    <Col md={3} className='mt-3'>
                      <div className='search-input-group'>
                        <i className='fa fa-search'></i>
                        <SearchBar
                          {...toolkitProps.searchProps}
                          placeholder={t("search_placeholder")}
                          className='custom-search-input-form-control'
                        />
                      </div>
                    </Col>

                    <Col md={2} className='mt-3'>
                      {(userInfo.groups.includes(
                        CS_Hub_AWS_Account_Request_Admin
                      ) ||
                        userInfo.groups.includes(
                          CS_Hub_AWS_Account_CIDR_Security
                        )) && <CIDRDownload />}
                    </Col>

                    <Col md={3} className='mt-3'>
                      <button className='teal-filled-btn w-100'>
                        <Link to='/aws-cidr-new-request'>
                          <i className='fa fa-plus mx-2'></i>
                          {t("new_request")}
                        </Link>
                      </button>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={12} xl={12} className='mt-4'>
                      <div className='table-responsive'>
                        <BootstrapTable
                          keyField={'id'}
                          responsive
                          bordered={false}
                          striped={false}
                          defaultSorted={defaultSorted}
                          classes={'table align-middle table-nowrap'}
                          headerWrapperClasses={
                            'custom-table-head custom-pointer'
                          }
                          bodyClasses={'custom-table-body'}
                          {...toolkitProps.baseProps}
                          {...paginationTableProps}
                        />
                      </div>
                    </Col>
                  </Row>

                  <Row className='align-items-md-center mt-3'>
                    <Col className='d-flex flex-wrap justify-content-between align-items-baseline'>
                      <div>
                        <PaginationTotalStandalone {...paginationProps} />
                      </div>
                      <div>
                        <PaginationListStandalone {...paginationProps} />
                      </div>
                    </Col>
                  </Row>
                </>
              )}
            </ToolkitProvider>
          )}
        </PaginationProvider>
      )}
      {Loading === false && responseData.length === 0 && apiError === null && (
        <div className='d-flex justify-content-center align-items-center'>
          {t("no_data_to_display")}
        </div>
      )}
      {/* Approve Box */}
      {showApproveBox && (
        <CIDRApproveBox
          show={showApproveBox}
          uid={options.selectedOptionUID}
          approveNoParent={approveNoCallback}
          approvedParent={approvedCallback}
          userInfo={userInfo}
          cidrServiceType='AWS'
        />
      )}

      {/* Decline Box */}
      {showDeclineBox && (
        <CIDRDeclineBox
          show={showDeclineBox}
          uid={options.selectedOptionUID}
          declineNoParent={declineNoCallback}
          declinedParent={declinedCallback}
          userInfo={userInfo}
          cidrServiceType='AWS'
        />
      )}

      {/* Cancel Box */}
      {showCancelBox && (
        <CIDRCancelBox
          show={showCancelBox}
          uid={options.selectedOptionUID}
          cancelNoParent={cancelNoCallback}
          canceledParent={canceledCallback}
          userInfo={userInfo}
          cidrServiceType='AWS'
        />
      )}

      {/* View Box */}
      {showViewBox && (
        <CIDRViewBox
          uid={options.selectedOptionUID}
          viewBackParent={viewBackCallback}
          // status={options.selectedOptionData.requestStatus}
          optionData={options.selectedOptionData}
          editShowParent={editShowCallback}
          userInfo={userInfo}
        />
      )}

      {/* Edit Form Box */}
      {showEditBox && (
        <CIDREditBox
          uid={options.selectedOptionUID}
          status={options.selectedOptionData.requestStatus}
          optionData={options.selectedOptionData}
          editBackParent={editBackCallback}
          refreshDataParent={refreshDataCallback}
        />
      )}
    </>
  );
};

export default AWSTable;
