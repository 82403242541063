import React, { useState, useCallback, useRef } from "react";
import { Row, Col, Form, Alert } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

// Tag Input
import ReactTagInput from "@pathofdev/react-tag-input";
import "@pathofdev/react-tag-input/build/index.css";

// Multi Select
import Multiselect from "multiselect-react-dropdown";

// Wyswig Editor
import { EditorState } from "draft-js";
import { convertToHTML } from "draft-convert";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import TextInput from "components/Forms/TextInput";
import CustomModal from "components/Modal/CustomModal";
import ModalCancel from "./ModalCancel";
import ModalSubmit from "./ModalSubmit";

import { notificationValidator } from "./validator";

import { postApiCall } from "helper/postApiCall";

import SpinnerLoader from "components/Common/SpinnerLoader";
import DangerAlert from "components/Message/DangerAlert";
import StaticDropdown from "components/Forms/StaticDropdown";
import { cloudTypeValues } from "static-contents/notification-data";

const Notification = () => {
  const { t } = useTranslation();
  // Redux State
  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  const [isAllAcccountsSelected, setIsAllAcccountsSelected] = useState(false);

  const [accountName, setAccountName] = useState([]);
  const [accountNumber, setAccountNumber] = useState([]);
  const [gsirtsplunkopcoindex, setGsirtsplunkopcoindex] = useState([]);
  const [contactType, setContactType] = useState([]);
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState(EditorState.createEmpty());
  const [convertedMessage, setConvertedMessage] = useState("");
  const [dataInputError, setDataInputError] = useState({});

  //Recepients list
  const [recepients, setRecepients] = useState([]);
  const [cloudType, setCloudType] = useState("");
  const [submittedBy, setSubmittedBy] = useState(null);
  const [userName, setUserName] = useState(null);

  const contactTypeOptions = [
    { label: "Account Owner", value: "accountowner" },
    { label: "Billing Contact", value: "billingcontact" },
    { label: "Security Contact", value: "securitycontact" },
    { label: "Technical Contact", value: "technicalcontact" },
    { label: "Billing Email Group", value: "billingemailgroup" },
    { label: "Security Email Group", value: "securityemailgroup" },
    { label: "Technical Email Group", value: "technicalemailgroup" },
  ];

  const handleEditorChange = (state) => {
    setMessage(state);
    convertContentToHTML();
  };

  const convertContentToHTML = () => {
    let currentContentAsHTML = convertToHTML(message.getCurrentContent());
    setConvertedMessage(currentContentAsHTML);
  };

  // API Call
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState(null);
  const [fileLoading, setFileLoading] = useState(false);

  const apiUrl = `${process.env.REACT_APP_NOTIFICATION_ALERT}`;
  const idToken = `${userInfo.idToken}`;

  // Modal
  const [showModal, setShowModal] = useState(false);
  const [showSubmitModal, setShowSubmitModal] = useState(false);
  const [submitMessage, setSubmitMessage] = useState("");

  // Close Modal Callback (Modal No Click Part)
  const closeModalCallback = useCallback((value) => {
    setShowModal(value);
  }, []);

  function formSubmit() {
    setDataInputError({});
    let tempContactTypeArray = [];
    for (let i = 0; i < contactType.length; i++) {
      tempContactTypeArray.push(contactType[i].value);
    }

    const validateAccountName =
      accountName.toString() === "" ? null : accountName.toString();
    const validateAccountNumber =
      accountNumber.toString() === "" ? null : accountNumber.toString();
    const validateGsirt =
      gsirtsplunkopcoindex.toString() === ""
        ? null
        : gsirtsplunkopcoindex.toString();

    const validateContactType = tempContactTypeArray.toString();

    let createTempObject = {
      isAllAccountSelected: isAllAcccountsSelected,
      contactType: validateContactType,
      subject: subject,
      message: convertedMessage,
      validatedAccountName: validateAccountName,
      validatedAccountNumber: validateAccountNumber,
    };

    const errors = notificationValidator(createTempObject);
    setDataInputError(errors);

    let requestBody = {};
    if (Object.keys(errors).length > 0) {
      return;
    } else {
      if (isAllAcccountsSelected === true) {
        requestBody = {
          account: {
            allaccounts: `${isAllAcccountsSelected}`,
          },
          contacttype: validateContactType,
          subject: subject,
          message: convertedMessage,
          ...(fileName !== "" && {
            filename: fileName,
          }),
          ...(baseFile !== "" && {
            file: baseFile,
          }),
        };
      } else {
        requestBody = {
          account: {
            allaccounts: `${isAllAcccountsSelected}`,
            ...(validateAccountName !== null && {
              accountname: validateAccountName,
            }),
            ...(validateAccountNumber !== null && {
              accountnumber: validateAccountNumber,
            }),
            ...(validateGsirt !== null && {
              gsirtsplunkopcoindex: validateGsirt,
            }),
          },
          contacttype: validateContactType,
          subject: subject,
          message: convertedMessage,
          ...(fileName !== "" && {
            filename: fileName,
          }),
          ...(baseFile !== "" && {
            file: baseFile,
          }),
        };
      }
    }
    if (cloudType !== "") {
      requestBody.cloudType = cloudType;
    }
    requestBody.validatedRecepients = recepients;
    requestBody.submittedBy = submittedBy ? submittedBy : userInfo?.email;
    requestBody.userName = userName ? userName : userInfo?.name;
    setLoading(true);

    postApiCall(apiUrl, idToken, requestBody).then((response) => {
      if (response.data.statusCode === 200) {
        setSubmitMessage("your message sent successfully");
        setShowSubmitModal(true);
      } else {
        setApiError(
          "Failed to Retrieve data, Contact Sony Global Cloud Team for Assistance"
        );
      }
      setLoading(false);
    });
  }

  const [baseFile, setBaseFile] = useState("");
  const [fileName, setFileName] = useState("");

  const ref = useRef();

  const uploadImage = async (e) => {
    setFileLoading(true);
    const file = e.target.files[0];
    const filePath = e.target.files[0].name;
    const fileSize = e.target.files[0].size;
    const checkFileSize = Math.round(fileSize / 1024);

    const allowedExtensions = ["pdf", "xlsx", "docx"];
    const fileExtension = filePath.split(".").pop();

    if (checkFileSize >= 5120) {
      alert("File size should be below 5MB");
      ref.current.value = "";
    } else if (!allowedExtensions.includes(fileExtension)) {
      alert("file type not allowed");
      ref.current.value = "";
    } else {
      const base64 = await convertBase64(file);
      setBaseFile(base64);
      setFileName(file.name);
      setFileLoading(false);
    }
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleCloudSelect = useCallback(
    (e) => {
      setCloudType(e.target.value);
    },
    [cloudType]
  );
  const handleRecepients = (value) => {
    const dummyArray = [];
    const getEachValue = value.map((item) => {
      const splittedItems = item.split(",");
      if (splittedItems?.length > 0) {
        splittedItems?.map((eachItem) => dummyArray.push(eachItem));
      } else {
        dummyArray.push(item);
      }
    });
    setRecepients(dummyArray);
  };

  return (
    <>
      <Row>
        <Col>
          <p className="maintitle-text">{t("notification")}</p>
        </Col>
      </Row>
      {loading === false && apiError !== null && (
        <Row>
          <Col md={12}>
            <DangerAlert message={apiError} />
          </Col>
        </Row>
      )}
      <Row>
        <Col md={6}>
          <StaticDropdown
            label={"Select Cloud Type"}
            defaultSelectMessage={t("Select Cloud Type")}
            name="cloudType"
            value={cloudType}
            onChange={(e) => handleCloudSelect(e)}
            selectValues={cloudTypeValues}
          />
        </Col>
      </Row>
      <hr />
      <Row>
        <Col md={6} className="d-flex align-items-center mt-3">
          <span className="subtitle-text">{t("select_all_accounts")}</span>
          <label className="custom-toggle-switch mx-3">
            <input
              type="checkbox"
              checked={isAllAcccountsSelected}
              onChange={(e) => {
                let getCheckedValue = e.target.checked;
                setIsAllAcccountsSelected(getCheckedValue);
              }}
            />
            <span className="custom-toggle-slider round"></span>
          </label>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col md={12}>
          {isAllAcccountsSelected === false ? (
            <p className="notification-note">{t("notification_note")}</p>
          ) : null}
        </Col>
        {dataInputError.optionalValue && (
          <Col md={12}>
            <Alert variant="danger">{dataInputError.optionalValue}</Alert>
          </Col>
        )}
        <Col md={6}>
          {isAllAcccountsSelected === false ? (
            <Form.Group className="mb-3">
              <Form.Label>{t("account_name")}</Form.Label>
              <ReactTagInput
                tags={accountName}
                onChange={(newTags) => setAccountName(newTags)}
                placeholder={t("account_name")}
                removeOnBackspace={true}
              />
            </Form.Group>
          ) : (
            <Form.Group className="mb-3">
              <Form.Label>{t("account_name")}</Form.Label>
              <Form.Select disabled={true}>
                <option>{t("account_name")}</option>
              </Form.Select>
            </Form.Group>
          )}
        </Col>
        <Col md={6}>
          {isAllAcccountsSelected === false ? (
            <Form.Group className="mb-3">
              <Form.Label>{t("account_number")}</Form.Label>
              <ReactTagInput
                tags={accountNumber}
                onChange={(newTags) => setAccountNumber(newTags)}
                placeholder={t("account_number")}
                removeOnBackspace={true}
              />
            </Form.Group>
          ) : (
            <Form.Group className="mb-3">
              <Form.Label>{t("account_number")}</Form.Label>
              <Form.Select disabled={true}>
                <option>{t("account_number")}</option>
              </Form.Select>
            </Form.Group>
          )}
        </Col>
        <Col md={6}>
          <Form.Group className="mb-3" controlId="form-subject">
            <TextInput
              label={t("Requestor Name")}
              name="form-subject"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
              placeholder={t("Requestor Name")}
            />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group className="mb-3" controlId="form-subject">
            <TextInput
              label={t("Requestor Email")}
              name="form-subject"
              value={submittedBy}
              onChange={(e) => setSubmittedBy(e.target.value)}
              placeholder={t("Requestor Email")}
            />
          </Form.Group>
        </Col>
        {/* <Col md={6}>
          {isAllAcccountsSelected === false ? (
            <Form.Group className="mb-3">
              <Form.Label>{t("gsirtsplunk_opco_index")}</Form.Label>
              <ReactTagInput
                separators={["Enter", "Space", "Tab"]}
                handleAddition={(newTags) => setGsirtsplunkopcoindex(newTags)}
                tags={gsirtsplunkopcoindex}
                // onChange={(newTags) => setGsirtsplunkopcoindex(newTags)}
                placeholder={t("gsirtsplunk_opco_index")}
                removeOnBackspace={true}
              />
            </Form.Group>
          ) : (
            <Form.Group className="mb-3">
              <Form.Label>{t("gsirtsplunk_opco_index")}</Form.Label>
              <Form.Select disabled={true}>
                <option>{t("gsirtsplunk_opco_index")}</option>
              </Form.Select>
            </Form.Group>
          )}
        </Col> */}
        <Col md={6}>
          {/* {isAllAcccountsSelected === false ? ( */}
          <Form.Group className="mb-3">
            <Form.Label>{t("Recepients")}</Form.Label>
            <ReactTagInput
              tags={recepients}
              onChange={(newTags) => {
                handleRecepients(newTags);
                // setRecepients(newTags);
              }}
              placeholder={t("Recepients")}
              removeOnBackspace={true}
            />
          </Form.Group>
          {/* ) : ( */}
          {/* <Form.Group className="mb-3">
              <Form.Label>{t("recepients")}</Form.Label>
              <Form.Select disabled={true}>
                <option>{t("recepients")}</option>
              </Form.Select>
            </Form.Group>
          )} */}
        </Col>
        <Col md={6}>
          <Form.Group className="mb-3" controlId="form-contact-type">
            <Form.Label>{t("contact_type")}</Form.Label>
            {/* <MultiSelect
              options={contactTypeOptions}
              value={contactType}
              onChange={setContactType}
              labelledBy='Contact Type'
            /> */}
            <Multiselect
              selectedValues={{}}
              options={contactTypeOptions}
              onSelect={(selectedList, selectedItem) => {
                setContactType(selectedList);
              }}
              onRemove={(selectedList, selectedItem) => {
                setContactType(selectedList);
              }}
              displayValue="label"
              disablePreSelectedValues={true}
              closeOnSelect={false}
              avoidHighlightFirstOption={true}
              value={contactType}
            />
            <div className="custom-error-message-text">
              {dataInputError.contactType}
            </div>
          </Form.Group>
        </Col>
        <Col md={12}>
          <Form.Group className="mb-3" controlId="form-subject">
            <TextInput
              as="textarea"
              label={t("subject")}
              name="form-subject"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              placeholder={t("subject")}
              isInvalid={dataInputError.subject ? true : false}
              invalidMessage={dataInputError.subject}
            />
          </Form.Group>
        </Col>
        <Col md={12}>
          <Form.Group className="mb-3" controlId="selectIp">
            <Editor
              editorState={message}
              onEditorStateChange={handleEditorChange}
              wrapperClassName="wrapper-class"
              editorClassName="editor-class"
              toolbarClassName="toolbar-class"
            />
            <div className="custom-error-message-text">
              {dataInputError.message}
            </div>
          </Form.Group>
        </Col>
        <Col md={12}>
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>{t("file_upload")}</Form.Label>
            {/* <Form.Control
              type='file'
              ref={ref}
              onChange={(e) => {
                uploadImage(e);
              }}
            /> */}
            <div className="custom-upload-wrapper">
              <button className="custom-upload-button">
                {fileLoading ? (
                  <SpinnerLoader />
                ) : fileName ? (
                  fileName
                ) : (
                  <>
                    <i className="fa fa-cloud-upload"></i>
                    {t("add_file")} (pdf/ xlsx/ docx)
                  </>
                )}
              </button>
              <input
                type="file"
                ref={ref}
                onChange={(e) => {
                  uploadImage(e);
                }}
              />
            </div>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          {loading === true && (
            <div className="d-flex justify-content-center">
              <SpinnerLoader />
            </div>
          )}
          {loading === false && (
            <div className="d-flex justify-content-end">
              <ul className="px-0 btn-ul">
                <li>
                  <button
                    type="button"
                    className="teal-bordered-btn"
                    onClick={() => {
                      setShowModal(true);
                    }}
                  >
                    {t("cancel")}
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    className={`teal-filled-btn ${
                      fileLoading ? "disabled-div disabled-bg" : ""
                    }`}
                    onClick={() => {
                      formSubmit();
                    }}
                  >
                    {t("send")}
                  </button>
                </li>
              </ul>
            </div>
          )}
        </Col>
      </Row>

      {showModal && (
        <CustomModal
          showModal={true}
          children={<ModalCancel closeModalParent={closeModalCallback} />}
        />
      )}

      {showSubmitModal && (
        <CustomModal
          showModal={true}
          children={<ModalSubmit message={submitMessage} />}
        />
      )}
    </>
  );
};

export default Notification;
