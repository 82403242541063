import awsIcon from 'icons/sub-menu-icons/awscolor.svg';
import azureIcon from 'icons/sub-menu-icons/azurecolor.svg';
import { useTranslation } from 'react-i18next';

export function useGetStartedList(){
  const {t} = useTranslation();
  return t("getstarted_items",{awsIcon: awsIcon, azureIcon: azureIcon});
}

const getstarted = [

  {
    icon: awsIcon,
    title: 'Data Analytics with AWS  | Duration: 1h',
    link: 'https://explore.skillbuilder.aws/learn/public/learning_plan/view/97/data-analytics-learning-plan?dt=sec&sec=lp',
  },

  {
    icon: awsIcon,
    title: 'AWS Technical Essentials | Duration: 6h',
    link: 'https://explore.skillbuilder.aws/learn/course/external/view/elearning/1851/aws-technical-essentials?dt=tile&tile=fdt'  },


  {
    icon: awsIcon,
    title: 'AWS Cloud Practitioner Essentials | Duration: 4h',
    link: 'https://explore.skillbuilder.aws/learn/course/external/view/elearning/134/aws-cloud-practitioner-essentials?dt=tile&tile=fdt',
  },
      
  {
    icon: azureIcon,
    title: 'Azure Basics Training',
    link: 'https://docs.microsoft.com/en-us/learn/azure/',
  },
  {
    icon: azureIcon,
    title: 'Azure Developer Videos',
    link: 'https://azure.microsoft.com/en-us/resources/videos/',
  },
  // {
  //   icon: azureIcon,
  //   title: 'Azure Fundamentals',
  //   link:
  //     'https://docs.microsoft.com/en-us/learn/paths/az-900-describe-cloud-concepts/',
  // },

  // {
  //   icon: awsIcon,
  //   title: 'AWS Deep Learning Containers',
  //   link: 'https://www.youtube.com/watch?v=qAFUQwTFnkY',
  // },
  // {
  //   icon: awsIcon,
  //   title: 'Integrate External IdPs with AWS Grafana',
  //   link: 'https://www.youtube.com/watch?v=t0NtkHtYeZ4',
  // },
  // {
  //   icon: awsIcon,
  //   title: 'Query Data Lake from Amazon Redshift',
  //   link: 'https://www.youtube.com/watch?v=-pyy0qNmEKo',
  // },
];

export { getstarted };
