export const applicationID = {
  faq: "e91494dd-835b-4248-b09b-44f3814c554d",
  // faq: "41bf181d-8425-4541-9ec1-b0ac4a8297cb",
  finance: "d9529ffa-f269-4a10-b2d9-b0f76fb9209a",
  conformity: "88dc7fcc-d4a0-4d0e-be2e-32cb3603f512",
};

export const pluginData = {
  faq: "305bbd08-d17e-4cfe-9ac8-654a8f833659",
  finance: "305bbd08-d17e-4cfe-9ac8-654a8f833659",
  conformity: "bf36056d-7231-4fc8-84e0-84ecefc1db73",
};
