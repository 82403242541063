export const accountContactValidate = (values) => {
  let errors = {};

  if (!values.firstNameAO) {
    errors.firstNameAO = "First name is required";
  } else if (/^[a-zA-Z- ]*$/.test(values.firstNameAO) === false) {
    errors.firstNameAO = "Only alphabets & hyphens allowed";
  }

  if (!values.lastNameAO) {
    errors.lastNameAO = "Last name is required";
  } else if (/^[a-zA-Z- ]*$/.test(values.lastNameAO) === false) {
    errors.lastNameAO = "Only alphabets & hyphens allowed";
  }

  let isEmail = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(
    values.emailAO
  );
  if (!values.emailAO) {
    errors.emailAO = "Email address is required";
  } else if (isEmail === false) {
    errors.emailAO = "Email address is invalid";
  }

  if (!values.countryAO) {
    errors.countryAO = "Country is required";
  }

  let isnum = /^(?=.*[0-9])[- +()0-9]+$/.test(values.phoneNoAO);

  if (!values.phoneNoAO) {
    errors.phoneNoAO = "Phone number is required";
  } else if (isnum === false) {
    errors.phoneNoAO = "Only digits, space, hyphens and plus is allowed";
  }

  return errors;
};

export const billingContactValidate = (values) => {
  let errors = {};
  if (!values.firstNameBC) {
    errors.firstNameBC = "First name is required";
  } else if (/^[a-zA-Z- ]*$/.test(values.firstNameBC) === false) {
    errors.firstNameBC = "Only alphabets & hyphens allowed";
  }

  if (!values.lastNameBC) {
    errors.lastNameBC = "Last name is required";
  } else if (/^[a-zA-Z- ]*$/.test(values.lastNameBC) === false) {
    errors.lastNameBC = "Only alphabets & hyphens allowed";
  }

  let isEmail = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(
    values.emailBC
  );
  if (!values.emailBC) {
    errors.emailBC = "Email address is required";
  } else if (isEmail === false) {
    errors.emailBC = "Email address is invalid";
  }

  if (!values.countryBC) {
    errors.countryBC = "Country is required";
  }

  let isnum = /^(?=.*[0-9])[- +()0-9]+$/.test(values.phoneNoBC);

  if (!values.phoneNoBC) {
    errors.phoneNoBC = "Phone number is required";
  } else if (isnum === false) {
    errors.phoneNoBC = "Only digits, space, hyphens and plus is allowed";
  }
  return errors;
};

export const securityContactValidate = (values) => {
  let errors = {};
  if (!values.firstNameSC) {
    errors.firstNameSC = "First name is required";
  } else if (/^[a-zA-Z- ]*$/.test(values.firstNameSC) === false) {
    errors.firstNameSC = "Only alphabets & hyphens allowed";
  }

  if (!values.lastNameSC) {
    errors.lastNameSC = "Last name is required";
  } else if (/^[a-zA-Z- ]*$/.test(values.lastNameSC) === false) {
    errors.lastNameSC = "Only alphabets & hyphens allowed";
  }

  let isEmail = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(
    values.emailSC
  );
  if (!values.emailSC) {
    errors.emailSC = "Email address is required";
  } else if (isEmail === false) {
    errors.emailSC = "Email address is invalid";
  }

  if (!values.countrySC) {
    errors.countrySC = "Country is required";
  }

  let isnum = /^(?=.*[0-9])[- +()0-9]+$/.test(values.phoneNoSC);

  if (!values.phoneNoSC) {
    errors.phoneNoSC = "Phone number is required";
  } else if (isnum === false) {
    errors.phoneNoSC = "Only digits, space, hyphens and plus is allowed";
  }
  return errors;
};

export const technicalContactValidate = (values) => {
  let errors = {};
  if (!values.firstNameTC) {
    errors.firstNameTC = "First name is required";
  } else if (/^[a-zA-Z- ]*$/.test(values.firstNameTC) === false) {
    errors.firstNameTC = "Only alphabets & hyphens allowed";
  }

  if (!values.lastNameTC) {
    errors.lastNameTC = "Last name is required";
  } else if (/^[a-zA-Z- ]*$/.test(values.lastNameTC) === false) {
    errors.lastNameTC = "Only alphabets & hyphens allowed";
  }

  let isEmail = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(
    values.emailTC
  );
  if (!values.emailTC) {
    errors.emailTC = "Email address is required";
  } else if (isEmail === false) {
    errors.emailTC = "Email address is invalid";
  }

  if (!values.countryTC) {
    errors.countryTC = "Country is required";
  }

  let isnum = /^(?=.*[0-9])[- +()0-9]+$/.test(values.phoneNoTC);

  if (!values.phoneNoTC) {
    errors.phoneNoTC = "Phone number is required";
  } else if (isnum === false) {
    errors.phoneNoTC = "Only digits, space, hyphens and plus is allowed";
  }
  return errors;
};

export const billingInfoValidate = (values) => {
  let errors = {};
  if (!values.billingopco) {
    errors.billingopco = "Billing operating company is required";
  }
  if (!values.billingSubOpco) {
    errors.billingSubOpco = "Billing sub operating company is required";
  }

  if (!values.billingDepartment) {
    errors.billingDepartment = "Billing department is required";
  }

  if (!values.billingRegion) {
    errors.billingRegion = "Billing region is required";
  }

  if (!values.maxSpend) {
    errors.maxSpend = "Max spend is required";
  }
  if (!values.billto) {
    errors.billto = "Bill to is required";
  }

  if (values.billto === "Global IS (GISC)") {
    if (!values.io) {
      errors.io = "IO is required";
    }
  } else {
    if (!values.costcenter) {
      errors.costcenter = "Cost center is required";
    }
  }

  return errors;
};

export const groupEmailAddressValidate = (values) => {
  let errors = {};
  let isEmailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

  if (!values.technicalGroup) {
    errors.technicalGroup = "Technical Group is required";
  } else if (isEmailRegex.test(values.technicalGroup) === false) {
    errors.technicalGroup = "Email address is invalid";
  }
  if (!values.securityGroup) {
    errors.securityGroup = "Security Group is required";
  } else if (isEmailRegex.test(values.securityGroup) === false) {
    errors.securityGroup = "Email address is invalid";
  }

  if (!values.billingGroup) {
    errors.billingGroup = "Billing Group is required";
  } else if (isEmailRegex.test(values.billingGroup) === false) {
    errors.billingGroup = "Email address is invalid";
  }

  return errors;
};

export const accountDetailsValidate = (values) => {
  let errors = {};
  if (!values.opco) {
    errors.opco = "Operating company is required";
  }
  if (!values.subopco) {
    errors.subopco = "Sub operating company is required";
  }

  if (!values.department) {
    errors.department = "Department is required";
  }

  if (!values.environmenttype) {
    errors.environmenttype = "Environment type is required";
  }
  if (!values.assetUserBase) {
    errors.assetUserBase = "Asset user base is required";
  }
  if (!values.dataClassification) {
    errors.dataClassification = "Data classification is required";
  }

  let ispurposeid = /^[a-zA-Z-]*$/.test(values.purposeid);
  const consecutiveChar = /([-])\1/.test(values.purposeid);
  if (!values.purposeid) {
    errors.purposeid = "Purpose ID is required";
  } else if (ispurposeid === false) {
    errors.purposeid =
      'Digits and special characters are not allowed "-" and space are allowed';
  } else if (
    ispurposeid === false ||
    consecutiveChar === true ||
    values.purposeid[values.purposeid?.length - 1] === "-"
  ) {
    errors.purposeid =
      'Special chracters apart from "-" not allowed / No consequtive special chracters and No special characters at the end are allowed.';
  }

  if (!values.accountdescription) {
    errors.accountdescription = "Account description is required";
  }

  return errors;
};

export const accountHostPatchValidate = (values) => {
  let errors = {};
  if (!values.dow) {
    errors.dow = "Day is required";
  }
  if (!values.week) {
    errors.week = "week is required";
  }
  if (!values.startDateHour) {
    errors.startDateHour = "Hour is required";
  }

  if (!values.startDateMinute) {
    errors.startDateMinute = "Minute is required";
  }
  return errors;
};

export const awsPatchTagValidate = (values) => {
  let errors = {};
  if (!values.tagName) {
    errors.tagName = "Tag name is required";
  }
  if (!values.tagDescription) {
    errors.tagDescription = "Tag description is required";
  }
  if (!values.region) {
    errors.region = "Region is required";
  }

  return errors;
};
export const awsBackUpValidate = (values) => {
  let errors = {};
  if (!values.accountName) {
    errors.accountName = "Account name is required";
  }
  if (!values.accountNumber) {
    errors.accountNumber = "Account number is required";
  }

  if (!values.backUpPlan) {
    errors.backUpPlan = "Backup plan is required";
  }

  if (!values.region) {
    errors.region = "Region is required";
  }
  return errors;
};
