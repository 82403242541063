import React, { useEffect, useState, useCallback } from "react";
import { Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import moment from "moment";
import SpinnerLoader from "components/Common/SpinnerLoader";
import DangerAlert from "components/Message/DangerAlert";
import { useTranslation } from "react-i18next";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";

import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  PaginationTotalStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

// Helper
import { postApiCall } from "helper/postApiCall";

const GISSecurity = () => {
  // Redux State
  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  // API Variables
  const apiUrl = `${process.env.REACT_APP_SECURITY_GIS}`;
  const idToken = `${userInfo.idToken}`;
  const requestBody = {};

  // API State
  const [apiLoading, setApiLoading] = useState(true);
  const [apiResponseData, setApiResponseData] = useState([]);
  const [apiError, setApiError] = useState(null);
  const { t } = useTranslation();

  // Datatable Variables & Options
  const columns = [
    {
      dataField: "AccountId",
      text: t("account_number"),
      sort: true,
      searchable: true,
    },
    {
      dataField: "AccountName",
      text: t("account_name"),
      sort: true,
      searchable: true,
    },
    {
      dataField: "Envtype",
      text: t("environment"),
      sort: true,
      searchable: true,
    },
    {
      dataField: "ExecutionDate",
      text: t("last_run_date"),
      sort: true,
      formatter: (cellContent, row) => {
        let dateConverted = moment(row.ExecutionDate).format("MMM DD, YYYY");
        return <span>{dateConverted}</span>;
      },
      sortFunc: (a, b, order) => {
        let dateConvertedA = moment(a).format("MMM DD, YYYY");
        let dateConvertedB = moment(b).format("MMM DD, YYYY");
        if (order === "asc") {
          return Date.parse(dateConvertedA) - Date.parse(dateConvertedB);
        } else if (order === "desc") {
          return Date.parse(dateConvertedB) - Date.parse(dateConvertedA);
        }
      },
    },
    {
      dataField: "Status",
      text: t("status"),
      sort: true,
      searchable: true,
      formatter: (cellContent, row) => {
        if (row.Status === "success") {
          return (
            <div
              className="approved-badge text-capitalize"
              style={{ fontSize: "11px" }}
            >
              {row.Status}
            </div>
          );
        } else {
          return (
            <div
              className="canceled-badge text-capitalize"
              style={{ fontSize: "11px" }}
            >
              {row.Status}
            </div>
          );
        }
      },
    },
  ];

  const defaultSorted = [
    {
      dataField: "ExecutionDate",
      order: "desc",
    },
  ];

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      {t("showing_results", { from, to, size })}
    </span>
  );

  const pageOptions = {
    sizePerPage: 10,
    totalSize: apiResponseData.length,
    custom: true,
    paginationTotalRenderer: customTotal,
  };

  const { SearchBar } = Search;

  useEffect(() => {
    setApiLoading(true);
    postApiCall(apiUrl, idToken, requestBody)
      .then((response) => {
        setApiResponseData(response.data);
        setApiLoading(false);
      })
      .catch((err) => {
        setApiError(t("failed_to_retrieve_data"));
        setApiLoading(false);
      });
    return () => {
      setApiResponseData([]);
    };
  }, []);

  return (
    <div className="custom-card px-2">
      {apiLoading && (
        <Row>
          <Col md={12}>
            <SpinnerLoader />
          </Col>
        </Row>
      )}
      {apiLoading === false && apiError === null && (
        <PaginationProvider
          pagination={paginationFactory(pageOptions)}
          keyField="AccountId"
          columns={columns}
          data={apiResponseData}
        >
          {({ paginationProps, paginationTableProps }) => (
            <ToolkitProvider
              keyField="AccountId"
              columns={columns}
              data={apiResponseData}
              search
            >
              {(toolkitProps) => (
                <>
                  <Row className="d-flex align-items-center">
                    <Col md={9} className="mb-2">
                      <div className="subtitle-text">
                        {t("gis_security_update_status")}
                      </div>
                    </Col>

                    {apiResponseData.length > 0 && (
                      <Col md={3} className="mb-2">
                        <div className="search-input-group">
                          <i className="fa fa-search"></i>
                          <SearchBar
                            {...toolkitProps.searchProps}
                            className="custom-search-input-form-control"
                            placeholder={t("search_placeholder")}
                          />
                        </div>
                      </Col>
                    )}
                  </Row>

                  <>
                    <Row>
                      <Col md={12} xl={12} className="mt-4">
                        <div className="table-responsive">
                          <BootstrapTable
                            keyField={"AccountId"}
                            responsive
                            bordered={false}
                            striped={false}
                            defaultSorted={defaultSorted}
                            classes={"table align-middle table-nowrap"}
                            headerWrapperClasses={
                              "custom-table-head custom-pointer"
                            }
                            bodyClasses={"custom-table-body"}
                            {...toolkitProps.baseProps}
                            {...paginationTableProps}
                          />
                        </div>
                      </Col>
                      <Col>
                        {apiResponseData.length === 0 && (
                          <div className="custom-error-message text-center">
                            {t("no_data_to_display")}
                          </div>
                        )}
                      </Col>
                    </Row>

                    <Row className="align-items-md-center mt-3">
                      <Col className="d-flex flex-wrap justify-content-between align-items-baseline">
                        <div>
                          <PaginationTotalStandalone {...paginationProps} />
                        </div>
                        <div>
                          <PaginationListStandalone {...paginationProps} />
                        </div>
                      </Col>
                    </Row>
                  </>
                </>
              )}
            </ToolkitProvider>
          )}
        </PaginationProvider>
      )}

      {apiLoading === false &&
        apiError === null &&
        apiResponseData.length === 0 && (
          <div className="d-flex justify-content-center align-items-center">
            {t("no_data_to_display")}
          </div>
        )}

      {apiLoading === false && apiError !== null && (
        <DangerAlert message={apiError} />
      )}
    </div>
  );
};

export default GISSecurity;
