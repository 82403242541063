import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { BOT_WINDOW } from "redux/chatbot/chatBotConstant";
import BotIcon from "./BotIcon";

const HeaderComponent = () => {
  const dispatch = useDispatch();
  const showBot = useSelector((state) => state.chatBot.botWindowStatus);
  return (
    <>
      <div className="bot-custom-header">
        <div>
          <BotIcon type="header" />
        </div>
        <p className="ps-1 fw-bold bot-header-text">Ask NEO</p>
        <p
          className="bot-close-icon cursor-pointer"
          onClick={() => {
            dispatch({
              type: BOT_WINDOW,
              payload: false,
            });
          }}
        >
          x
        </p>
      </div>
    </>
  );
};

export default HeaderComponent;
