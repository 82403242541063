import React, { useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";

import SpinnerLoader from "components/Common/SpinnerLoader";
import CustomModal from "components/Modal/CustomModal";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import Moment from "moment";

const TrendMicroDownload = ({ userInfo, accountnumber }) => {
  const fileName = `Trend Micro Hosts`;

  const [loading, setLoading] = useState(false);
  const [responseData, setResponseData] = useState(null);
  const [showMessageModal, setShowMessageModal] = useState(false);

  function loadDownloadData() {
    setLoading(true);
    const setIdToken = `{"idToken":{"idToken": "${userInfo.idToken}"}}`;

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: setIdToken,
      },
    };
    const apiUrl = accountnumber
      ? `${process.env.REACT_APP_SECURITY_TREND_MICRO_DETAILS}`
      : `${process.env.REACT_APP_SECURITY_TREND_MICRO_DETAILS_DOWNLOAD}`;

    const requestBody = accountnumber
      ? { context: "download", accountnumber }
      : {
          email: userInfo.email,
        };

    axios.post(apiUrl, requestBody, config).then((r) => {
      if (accountnumber) {
        const fileName = `TM-WorkloadSecurity-${Moment().format("YYYY-MM-DD")}`;
        exportToCSV(r.data.body, fileName);
      } else {
        if (r.data.message) {
          setResponseData(t("trend_micro_reports_msg"));
        } else {
          setResponseData(t("failed_to_retrieve_data"));
        }
        setShowMessageModal(true);
      }

      setLoading(false);
    });
  }

  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const exportToCSV = (apiData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(apiData, {
      header: [
        "Accountnumber",
        "AccountName",
        "hostname",
        "DisplayName",
        "Platform",
        "ComputerStatus",
        "StatusMessage"
      ],
    });
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  const { t } = useTranslation();

  return (
    <>
      {loading && (
        <div className="d-flex flex-column justify-content-center">
          <SpinnerLoader />
          <span className="custom-loader-message">
            {t("file_will_be_downloaded_in_a_while") + "!"}
          </span>
        </div>
      )}
      {loading === false && (
        <button
          className="teal-bordered-download-btn-sm"
          onClick={(e) => {
            loadDownloadData();
          }}
        >
          <i
            className="fa fa-download"
            style={{ fontSize: "12px", marginRight: "6px" }}
          ></i>
          {t("download")}
        </button>
      )}

      {showMessageModal && (
        <CustomModal
          showModal={true}
          children={
            <div className="d-flex justify-content-center flex-column">
              <p>{responseData}</p>
              <ul className="btn-ul">
                <li>
                  <button
                    type="button"
                    className="teal-filled-btn"
                    onClick={() => {
                      setShowMessageModal(false);
                    }}
                  >
                    {t("ok")}
                  </button>
                </li>
              </ul>
            </div>
          }
        />
      )}
    </>
  );
};

export default TrendMicroDownload;
