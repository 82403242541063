import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import ReactECharts from "echarts-for-react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

// Internal Component
import PrismaCanvas from "./PrismaCanvas";
import PrismaDownload from "./PrismaDownload";

import SpinnerLoader from "components/Common/SpinnerLoader";
import DangerAlert from "components/Message/DangerAlert";

// Import Constant
import { PRISMA, TREND } from "../../redux/dashboard/dashboardConstant";
import { useTrendMicroConformityApi } from "./dataloader";

const pieChartValues = [
  {
    keyField: "EXTREME",
    name: "Extreme",
    value: 300,
    color: "#ff3f3f",
    type: "aws",
  },
  {
    keyField: "VERY_HIGH",
    name: "Very High",
    value: 300,
    color: "#fd7b43",
    type: "aws",
  },
  {
    keyField: "HIGH",
    name: "High",
    value: 400,
    color: "#ffe181",
    type: "aws",
  },
];

const Prisma = () => {
  const [primsaCanvasTitle, setPrismaCanvasTitle] = useState(null);
  const [selectedLegend, setSelectedLegend] = useState(null);
  const [selectedCloudType, setSelectedCloudType] = useState(null);

  const {
    isFetching: loading,
    data: responseData,
    error,
  } = useTrendMicroConformityApi();

  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const { userInfo } = user;
  const security = useSelector((state) => state.security);
  const { prismaCanvas } = security;

  const { awsDataSet, azureDataSet } = React.useMemo(() => {
    const dataSets = {
      awsDataSet: [],
      azureDataSet: [],
    };

    if (responseData.length) {
      const awsInfo = responseData.find((item) => item.type === "aws");
      const azureInfo = responseData.find((item) => item.type === "azure");
      dataSets.awsDataSet = pieChartValues.map((item) => {
        return { ...item, value: awsInfo[item.keyField] };
      });
      dataSets.azureDataSet = [...pieChartValues].map((item) => {
        return { ...item, value: azureInfo[item.keyField] };
      });
    }

    return dataSets;
  }, [responseData]);

  var awsOption = {
    maintainAspectRatio: false,
    tooltip: {
      show: true,
    },
    legend: {
      show: false,
    },
    series: [
      {
        type: "pie",
        radius: ["40px", "30px"],
        data: awsDataSet.map(({ name, value, color, borderRadius, type }) => ({
          name,
          value,
          itemStyle: { color: color, borderRadius: borderRadius },
          type,
        })),
        label: {
          alignTo: "labelLine",
          formatter: "{c}",
          minMargin: 5,
          edgeDistance: 10,
        },
        labelLine: {
          length: 20,
          length2: 10,
        },
      },
    ],
  };

  var azureOption = {
    maintainAspectRatio: false,
    tooltip: {
      show: true,
    },
    legend: {
      show: false,
    },
    series: [
      {
        type: "pie",
        radius: ["40px", "30px"],
        data: azureDataSet.map(({ name, value, type, color }) => ({
          name,
          value,
          type,
          itemStyle: { color: color },
        })),
        label: {
          alignTo: "labelLine",
          formatter: "{c}",
          minMargin: 5,
          edgeDistance: 10,
        },
        labelLine: {
          length: 20,
          length2: 10,
        },
      },
    ],
  };

  function onChartClick(param, echarts) {
    // console.log(param.data);
    setPrismaCanvasTitle("Alert Details");
    setSelectedLegend(param.data.name);
    setSelectedCloudType(param.data.type);
    dispatch({
      type: PRISMA,
      payload: true,
    });
    dispatch({
      type: TREND,
      payload: false,
    });
  }

  const { t } = useTranslation();

  return (
    <>
      {loading && (
        <Row>
          <Col className="d-flex justify-content-center align-items-center mt-3">
            <SpinnerLoader />
          </Col>
        </Row>
      )}
      {loading === false && error !== null && (
        <Row>
          <Col className="d-flex justify-content-center align-items-center mt-3">
            <DangerAlert message={error} />
          </Col>
        </Row>
      )}
      {loading === false && error === null && (
        <>
          <Row>
            <Col md={6} xs={6}>
              <ReactECharts
                option={awsOption}
                onEvents={{
                  click: onChartClick,
                }}
                className="aws-chart-logo-center"
                style={{ height: "200px", width: "100%" }}
              />
            </Col>

            <Col md={6} xs={6}>
              <ReactECharts
                option={azureOption}
                onEvents={{
                  click: onChartClick,
                }}
                className="azure-chart-logo-center"
                style={{ height: "200px", width: "100%" }}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12} className="d-flex align-items-center">
              <ul className="legend-list-wrapper px-0">
                <li className="legend-list">
                  <div className="legend-list-red"></div>
                  {t("extreme")}
                </li>
                <li className="legend-list">
                  <div className="legend-list-orange"></div>
                  {t("very_high")}
                </li>
                <li className="legend-list">
                  <div className="legend-list-yellow"></div>
                  {t("high")}
                </li>
                <li>
                  <PrismaDownload userInfo={userInfo} />
                </li>
              </ul>
            </Col>
          </Row>
        </>
      )}

      {prismaCanvas === true && (
        <PrismaCanvas
          userInfo={userInfo}
          title={primsaCanvasTitle}
          selectedLegend={selectedLegend}
          selectedCloudType={selectedCloudType}
        />
      )}
    </>
  );
};

export default Prisma;
