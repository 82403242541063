export const DefaultAWSData = {
  installatonSchedule: {
    startDateHour: "",
    startDateMinute: "",
    week: "",
    dow: "",
  },
  targetInstance: {
    instances: [],
    tagName: "",
    tagDescription: "",
    region: "",
    instanceId: "",
    displayName: "",
    ssmStatus: "",
    targetSource: "",
  },
};

export const AWSReducer = (awsData, { type, payload }) => {
  switch (type) {
    case "UPDATE_INSTALLATION_SCHEDULE":
      return {
        ...awsData,
        installatonSchedule: {
          ...awsData.installatonSchedule,
          ...payload,
        },
      };
    case "UPDATE_TARGET_INSTANCE":
      return {
        ...awsData,
        targetInstance: {
          ...awsData.targetInstance,
          ...payload,
        },
      };
    default:
      return awsData;
  }
};
