import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useOktaAuth } from '@okta/okta-react';
import { useTranslation } from 'react-i18next';

// Import Internal Components
import AddRequest from './AddRequest';
import PageContentSidebar from 'components/PageContent/PageContentSidebar';

// Import Constant
import { USER_INFO } from 'redux/user/userConstant';

const NetworkHubAddIndex = ({ history }) => {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const { authState, oktaAuth } = useOktaAuth();

  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  useEffect(() => {
    if (authState.isAuthenticated) {
      oktaAuth.getUser().then((info) => {
        const getIdToken = oktaAuth.getIdToken();

        dispatch({
          type: USER_INFO,
          payload: {
            name: info.name,
            email: info.email,
            preferred_username: info.preferred_username,
            groups: info.groups,
            idToken: getIdToken,
          },
        });
      });
    } else {
      dispatch({
        type: USER_INFO,
        payload: null,
      });
      oktaAuth.signOut();
    }
  }, [authState, oktaAuth, dispatch]);

  return (
    <>
      {userInfo !== null && authState && authState.isAuthenticated && (
        <>
          <Container fluid className='mb-3'>
            <Row>
            <Col md={2} className='px-0'>
            <PageContentSidebar
                firstLogoFirstMatchingLink='network-hub-view'
                firstLogoSecondMatchingLink='network-hub-add'
                firstLogoFirstLinkText={t("view_request")}
                firstLogoSecondLinkText={t("Network Request")}
                showLinks='yes'
              />
            </Col>
              <Col md={10} className='mt-3'>
                <AddRequest />
              </Col>
            </Row>
          </Container>
        </>
      )}
    </>
  );
};

export default NetworkHubAddIndex;
