import React from "react";

const SecuritySecondPrompt = (props) => {
  const options = [
    {
      text: 'How can i resolve alert "S3 cross account access alert"?',
      handler: () => props.actionProvider.handleAccountAlerts("alertRuleId"),
      id: 8,
    },
  ];

  const optionsMarkup = options.map((option) => {
    return (
      <button
        key={option.id}
        onClick={option.handler}
        className="option-button"
      >
        {option.text}
      </button>
    );
  });

  return (
    <>
      <div className="options-container">{optionsMarkup}</div>
    </>
  );
};

export default SecuritySecondPrompt;
