import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import ReactECharts from "echarts-for-react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useTranslation } from "react-i18next";

// Internal Component
import TrendCanvas from "./TrendCanvas";
import TrendMicroDownload from "./TrendMicroDownload";

import SpinnerLoader from "components/Common/SpinnerLoader";
import DangerAlert from "components/Message/DangerAlert";

// Import Constant
import { PRISMA, TREND } from "../../redux/dashboard/dashboardConstant";

const TrendMicro = ({ accountnumber }) => {
  const [trendCanvasTitle, setTrendCanvasTitle] = useState(null);
  const [selectedLegend, setSelectedLegend] = useState(null);

  const [loading, setLoading] = useState(true);
  const [responseData, setResponseData] = useState([]);
  const [error, setError] = useState(null);

  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  const security = useSelector((state) => state.security);
  const { trendCanvas } = security;

  var awsDataSet = [
    {
      name: "Critical",
      value: responseData.Error,
      color: "#ff3f3f",
    },
    {
      name: "InActive ",
      value: responseData.InActive,
      color: "#ffe181",
    },
    {
      name: "Active ",
      value: responseData.Active,
      color: "#69bc50",
    },
    {
      name: "Warning",
      value: responseData.Warning,
      color: "#FD7B43",
    },
  ];

  var awsOption = {
    maintainAspectRatio: false,
    tooltip: {
      show: true,
    },
    legend: {
      show: false,
    },
    series: [
      {
        type: "pie",
        radius: "40%",
        // radius: ['40px'],
        data: awsDataSet.map(({ name, value, color, borderRadius }) => ({
          name,
          value,
          itemStyle: { color: color, borderRadius: borderRadius },
        })),
        label: {
          formatter: "{c}",
          position: "outer",
          alignTo: "labelLine",
          bleedMargin: 0,
        },
        labelLine: {
          length: 20,
          length2: 0,
          maxSurfaceAngle: 100,
        },
      },
    ],
  };

  function onChartClick(param, echarts) {
    setTrendCanvasTitle("Trend Host Details");
    const getLabelText = param.data.name;
    if (getLabelText === "Critical") {
      setSelectedLegend("Error");
    } else {
      setSelectedLegend(getLabelText.trim());
    }
    dispatch({
      type: TREND,
      payload: true,
    });
    dispatch({
      type: PRISMA,
      payload: false,
    });
  }

  useEffect(() => {
    const setIdToken = `{"idToken":{"idToken": "${userInfo.idToken}"}}`;

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: setIdToken,
      },
    };

    const requestBody = accountnumber ? { accountnumber } : {};
    axios
      .post(
        `${process.env.REACT_APP_SECURITY_TREND_MICRO_COUNT}`,
        requestBody,
        config
      )
      .then((response) => {
        setResponseData(response.data);
        setLoading(false);
      })
      .catch((err) => {
        setError(t("failed_to_retrieve_data"));
      });

    return () => {
      setResponseData([]);
    };
  }, [accountnumber]);
  const { t } = useTranslation();

  return (
    <>
      {loading && (
        <Row>
          <Col className="d-flex justify-content-center align-items-center mt-3">
            <SpinnerLoader />
          </Col>
        </Row>
      )}
      {loading === false && error !== null && (
        <Row>
          <Col className="d-flex justify-content-center align-items-center mt-3">
            <DangerAlert message={error} />
          </Col>
        </Row>
      )}
      {loading === false && error === null && (
        <>
          <Row>
            <Col md={12} xs={12}>
              <ReactECharts
                option={awsOption}
                onEvents={{
                  click: onChartClick,
                }}
                style={{ height: "200px", width: "100%" }}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <ul className="trend-micro-legend-list-wrapper align-items-center px-0">
                <li className="trend-micro-legend-list">
                  <div className="trend-micro-legend-list-yellow"></div>
                  {t("unmanaged")}
                </li>
                <li className="trend-micro-legend-list">
                  <div className="trend-micro-legend-list-red"></div>
                  {t("error")}
                </li>

                <li className="trend-micro-legend-list">
                  <div className="trend-micro-legend-list-orange"></div>
                  {t("warning")}
                </li>
                <li>
                  <TrendMicroDownload userInfo={userInfo} accountnumber={accountnumber} />
                </li>
              </ul>
            </Col>
          </Row>
        </>
      )}

      {trendCanvas === true && (
        <TrendCanvas
          accountnumber={accountnumber}
          title={trendCanvasTitle}
          selectedLegend={selectedLegend.toLowerCase()}
          userInfo={userInfo}
        />
      )}
    </>
  );
};

export default TrendMicro;
