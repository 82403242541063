import React from "react";
import { Row, Col } from "react-bootstrap";

import Prisma from "./Prisma";
import PrismaTopAlert from "./PrismaTopAlert";

const PrismaContainer = () => {
  return (
    <div className="custom-card px-2">
      <Row>
        <Col className="d-flex justify-content-between align-items-center">
          <div>
            <p className="subtitle-text px-2 mb-0">
              TrendMicro Conformity <br />
              <a
                href="https://cloudone.trendmicro.com/conformity/"
                className="splunk-link mx-0"
                target="_blank"
                rel="noopener noreferrer"
              >
                Click here for more details...
              </a>
            </p>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Prisma />
        </Col>
        <Col md={6}>
          <PrismaTopAlert />
        </Col>
      </Row>
    </div>
  );
};

export default PrismaContainer;
