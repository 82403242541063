import React, { useRef, useState } from "react";
import { useOnDemandScanApi } from "./dataloader";
import useInitScreen from "components/InitPage";
import { useOktaAuth } from "@okta/okta-react";
import { useFormik } from "formik";
import { Col, Row } from "react-bootstrap";
import SpinnerLoader from "components/Common/SpinnerLoader";
import Table from "components/Table";
import Slider from "components/Slider";

export default function OnDemandScanIndex() {
  useInitScreen();
  const fileRef = useRef(null);
  const { authState } = useOktaAuth();
  const { mutateAsync: upload, data = [], isLoading } = useOnDemandScanApi();
  const [radioSel, setRadioSel] = useState();
  const [selFileName, setSelFileName] = useState();
  const [showDetails, setShowDetails] = useState();
  const { handleSubmit, getFieldProps, values, setFieldValue } = useFormik({
    initialValues: { cfn: "", file: "" },
    onSubmit: async (values) => {
      if (radioSel) {
        if (fileRef.current.files[0] && radioSel === "file-radio") {
          const fr = new FileReader();
          fr.readAsText(fileRef.current.files[0]);
          fr.onload = function () {
            upload({
              template: btoa(fr.result),
              idToken: authState.idToken.idToken,
            });
          };
        } else {
          upload({
            template: btoa(values.cfn),
            idToken: authState.idToken.idToken,
          });
        }
      }
    },
  });

  const handleRadioSel = (event) => {
    setRadioSel(event.target.value);
  };

  const handleChange = (event) => {
    getFieldProps("file")?.onChange(event);
    setSelFileName(event.target.files[0]?.name);
  };

  const clearFile = () => {
    setFieldValue("file", "");
    setSelFileName("");
  };

  const handleRowClick = (row) => {
    if (row) {
      setShowDetails(row);
    }
  };

  return (
    <div className="pageMargin">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        {/*
        <div className="d-flex custom-card mb-4 someclass">
          <textarea
            style={{ width: "100%", height: 200 }}
            value={values.cfn}
            rows={50}
            type="text"
            {...getFieldProps("cfn")}
          />
          <div>
            <input
              accept=".yml"
              ref={fileRef}
              type="file"
              {...getFieldProps("file")}
            />
          </div>
        </div>
        */}
        <div className="d-flex gap-4 SectionTitle">
          <div className="arrowContainer">
            <img
              className="arrowImg"
              src="http://localhost:3000/static/media/back.25571301.svg"
              alt=""
            />
          </div>
          <p className="DemandTitle">On-Demand Scan</p>
        </div>
        <div className="row inputElements">
          <div className="col-6">
            <div className="d-flex gap-3">
              <div>
                <input
                  name="radiobox"
                  type="radio"
                  className="radio-btn"
                  value="cfn-radio"
                  onClick={handleRadioSel}
                />
              </div>
              <div>
                <p>Copy the CFN content:</p>
                <textarea
                  className="textfield_content"
                  name=""
                  id=""
                  cols="90"
                  rows="10"
                  placeholder="Write CFN content"
                  value={values.cfn}
                  {...getFieldProps("cfn")}
                ></textarea>
              </div>
            </div>
          </div>

          <div className="col-6">
            <div className="d-flex gap-3">
              <div>
                <input
                  name="radiobox"
                  type="radio"
                  className="radio-btn"
                  value="file-radio"
                  onClick={handleRadioSel}
                />
              </div>
              <div className="uploadSectionParent">
                <p>Choose File:</p>
                <div className="d-flex align-items-center gap-4 upload-section">
                  <div className="d-flex align-items-center file-input-container">
                    {values?.file && (
                      <i className="fa fa-file-pdf-o file-icon"></i>
                    )}
                    <input
                      className="uploadValueField px-5 w-100"
                      type="text"
                      disabled
                      placeholder="Choose files from here"
                      value={selFileName}
                    />
                    {values?.file && (
                      <button className="file-remove-icon" onClick={clearFile}>
                        <i class="fa fa-times-circle-o"></i>
                      </button>
                    )}
                  </div>
                  <div class="overHeadSection">
                    <div class="BrowseBtn">Browse</div>
                    <input
                      type="file"
                      name="myfile"
                      accept=".yml"
                      ref={fileRef}
                      {...getFieldProps("file")}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <button
          type="submit"
          className="teal-filled-btn mb-5"
          disabled={!radioSel}
        >
          Run the scan
        </button>
        {isLoading && (
          <Row>
            <Col className="d-flex justify-content-center align-items-center">
              <SpinnerLoader />
            </Col>
          </Row>
        )}
      </form>
      {!!data.length && (
        <div className="scanned-results-container">
          <Table
            data={data}
            columns={columns}
            text="Scan Results"
            idField="id"
            striped
            bordered
            onRowClick={handleRowClick}
          />
        </div>
      )}
      {showDetails && (
        <Slider
          headerText="Scan Details"
          onClose={() => setShowDetails(undefined)}
        >
          <Row className="details-modal">
            <Col md={12} className="mb-3">
              <div className="view-alarm-container header py-2 px-3">
                {showDetails.status} Detail
              </div>
              <div className="details-content mt-3 px-4">
                <Row>
                  <Col md={6}>Status</Col>
                  <Col md={6}>
                    {showDetails.status === "SUCCESS" ? (
                      <div
                        className="badge-wrapper green-badge text-capitalize"
                        style={{ fontSize: "11px" }}
                      >
                        {showDetails.status}
                      </div>
                    ) : (
                      <div
                        className="badge-wrapper red-badge text-capitalize"
                        style={{ fontSize: "11px" }}
                      >
                        {showDetails.status}
                      </div>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>Message</Col>
                  <Col md={6}>{showDetails["rule-title"]}</Col>
                </Row>
                <Row>
                  <Col md={6}>Region</Col>
                  <Col md={6}>{showDetails.region}</Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Slider>
      )}
    </div>
  );
}

const columns = [
  {
    dataField: "rule-title",
    text: "RULE",
    sort: true,
    searchable: true,
  },
  {
    dataField: "service",
    text: "SERVICE",
    sort: true,
    searchable: true,
  },
  {
    dataField: "categories",
    text: "CATEGORIES",
    sort: true,
    searchable: true,
    formatter: (cellContent, row) => {
      return row.categories?.map((item) => <div key={item}>{item}</div>);
    },
  },
  {
    dataField: "risk-level",
    text: "RISK LEVEL",
    sort: true,
    searchable: true,
  },
  {
    dataField: "status",
    text: "COUNTS",
    sort: true,
    searchable: true,
    formatter: (cellContent, row) => {
      if (row.status === "SUCCESS") {
        return (
          <div
            className="badge-wrapper green-badge text-capitalize"
            style={{ fontSize: "11px" }}
          >
            {row.status}
          </div>
        );
      }
      if (row.status === "FAILURE") {
        return (
          <div
            className="badge-wrapper red-badge text-capitalize"
            style={{ fontSize: "11px" }}
          >
            {row.status}
          </div>
        );
      }
    },
  },
];
