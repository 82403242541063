import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import BotIcon from "./BotIcon";
import { BOT_WINDOW } from "redux/chatbot/chatBotConstant";
import Tooltip from "rc-tooltip";

function BotIconComponent() {
  const dispatch = useDispatch();
  const showBot = useSelector((state) => state.chatBot.botWindowStatus);
  const [rotate, setRotate] = useState(false);

  useEffect(() => {
    const iconRotate = setTimeout(() => {
      setRotate(true);
    }, 4000);
    return () => {
      clearTimeout(iconRotate);
    };
  }, []);

  return (
    <div class="chat-tooltip">
      <button
        className={` app-chatbot-button ${rotate ? "rotate" : ""} ${
          showBot ? "right--30" : "right--0"
        }`}
        onClick={() => {
          dispatch({
            type: BOT_WINDOW,
            payload: true,
          });
        }}
      >
        <BotIcon />
        {/* <p className="">Ask Nia</p> */}
        <div></div>
      </button>
      <span class="chat-tooltiptext">Ask NEO</span>
    </div>
  );
}

export default BotIconComponent;
