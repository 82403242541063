import React, { useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { useDispatch } from 'react-redux';

import SpinnerLoader from 'components/Common/SpinnerLoader';

// Import Constant
import { USER_INFO } from '../../redux/user/userConstant';

import { CS_Hub_AWS_Account_Request_Admin } from 'roles/roles';

const Landing = ({ history }) => {
  const dispatch = useDispatch();
  const { authState, oktaAuth } = useOktaAuth();

  useEffect(() => {
    if (authState.isAuthenticated) {
      oktaAuth.getUser().then((info) => {
        const getIdToken = oktaAuth.getIdToken();

        dispatch({
          type: USER_INFO,
          payload: {
            name: info.name,
            email: info.email,
            preferred_username: info.preferred_username,
            groups: info.groups,
            idToken: getIdToken,
          },
        });

        if (!info?.groups?.includes(CS_Hub_AWS_Account_Request_Admin)) {
          history.push('/user-dashboard');
        } else {
          history.push('/dashboard');
        }
      });
    } else {
      oktaAuth.signOut();
    }
  }, [authState, oktaAuth, dispatch, history]);

  return (
    <div className='d-flex justify-content-center align-items-center custom-landing-loader'>
      <SpinnerLoader />
    </div>
  );
};

export default Landing;
