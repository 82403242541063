import React from "react";

const SecurityFirstAnswerPrompt = (props) => {
  const options = [
    {
      text: "How many alerts for rule '0.0 open' in all my aws accounts?",
      handler: () => props.actionProvider.handleAccountAlerts("alertRule"),
      id: 8,
    },
    {
      text: "How many alerts in total for all my AWS accounts?",
      handler: () => props.actionProvider.handleAccountAlerts("alertsTotal"),
      id: 9,
    },
  ];

  const optionsMarkup = options.map((option) => {
    return (
      <button
        key={option.id}
        onClick={option.handler}
        className="option-button"
      >
        {option.text}
      </button>
    );
  });

  return (
    <>
      <div className="options-container">{optionsMarkup}</div>
    </>
  );
};

export default SecurityFirstAnswerPrompt;
