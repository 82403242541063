import React from "react";
import { useTranslation } from "react-i18next";
import { useOktaAuth } from "@okta/okta-react";

const WarningMessage = ({ setShowAccountListError, show }) => {
  const { oktaAuth } = useOktaAuth();
  const { t } = useTranslation();
  return show ? (
    <div id="chat-Modal">
      <div className="chat-modal-in">
        <div className="chat-Modal-modal-content">
          <WarningMessage />

          <div className="d-flex justify-content-center flex-column">
            <p>
              {t("Your session has expired. pease click here to continue.")}
            </p>
            <ul className="btn-ul">
              <li>
                <button
                  type="button"
                  className="teal-filled-btn"
                  onClick={() => {
                    oktaAuth.signOut();
                  }}
                >
                  {t("ok")}
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default WarningMessage;
