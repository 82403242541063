import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

// Import Form Component
import TextInput from 'components/Forms/TextInput';
import Loader from 'components/Common/Loader';
import BillingCompanyDropdown from 'components/Forms/BillingCompanyDropdown';
import BillingSubCompanyDropdown from 'components/Forms/BillingSubCompanyDropdown';
import BillingDepartmentDropdown from 'components/Forms/BillingDepartmentDropdown';
import StaticDropdown from 'components/Forms/StaticDropdown';
import CustomModal from 'components/Modal/CustomModal';

// Import Internal Component
import EditRequestCancel from './EditRequestCancel';
import EditFormMenu from './EditFormMenu';
import EditRequestSubmit from './EditRequestSubmit';

// Helper
import { getApiCall } from 'helper/getApiCall';
import { postApiCall } from 'helper/postApiCall';

// Validator
import { groupEmailAddressValidate, accountContactValidate, billingContactValidate, securityContactValidate, technicalContactValidate, billingInfoValidate, accountDetailsValidate } from 'pages/Requests/Validator/validator';

// Static Data
import {
  staticEnvironmentType,
  staticAssetUserBase,
  staticDataClassification,
} from '../../StaticData/awsStaticData';
import { CS_Hub_AWS_Account_Request_Admin } from 'roles/roles';

import { TAB_ERROR_VALUES } from "redux/edit-window/editValueConstant.js";

const EditAccountDetails = ({ step, setStep, formData, setFormData }) => {
  const dispatch = useDispatch()

  // Redux State
  const user = useSelector((state) => state.user);
  const { userInfo } = user;
  const tabErrorValues = useSelector(
    (state) => state?.editValue?.tabErrorReduxValues
  );

  const accountRequestReload = useSelector(
    (state) => state.accountRequestReload
  );
  const { accountRequestReloadType } = accountRequestReload;

  // Set ID Token Globally
  const idToken = userInfo.idToken;

  // Response Data State
  const [loading, setLoading] = useState(true);
  const [responseData, setResponseData] = useState([]);

  // Component Form Data State
  const [billingSubOpCompany, setBillingSubOpCompany] = useState([]);
  const [billingDepartment, setBillingDepartment] = useState([]);

  // Form Submit State
  const [formSubmitLoading, setFormSubmitLoading] = useState(false);
  const [formSubmitSuccess, setFormSubmitSuccess] = useState(false);
  // const [newRequestUid, setNewRequestUid] = useState(null);

  // Data Input Error State
  const [dataInputError, setDataInputError] = useState({});

  // Show Cancel Modal
  const [showCancelModal, setShowCancelModal] = useState(false);

  // Close Modal Callback (Modal No Click Part)
  const closeModalCallback = useCallback((value) => {
    setShowCancelModal(value);
  }, []);

  // handle onchange
  const handleData = ({ target }) => {
    if (target.name === 'opco') {
      setFormData({
        type: 'UPDATE_ACCOUNT_DETAILS',
        payload: {
          [`subopco`]: '',
        },
      });
      setFormData({
        type: 'UPDATE_ACCOUNT_DETAILS',
        payload: {
          [`department`]: '',
        },
      });
      setBillingSubOpCompany([]);
      setBillingDepartment([]);
      let opCompany = target.value;
      if (opCompany !== '') {
        const getSubOpCompany = responseData.find(
          (t) => t.opco === opCompany
        ).subopcod;
        setBillingSubOpCompany(getSubOpCompany);
      }
    }
    if (target.name === 'subopco') {
      setFormData({
        type: 'UPDATE_ACCOUNT_DETAILS',
        payload: {
          [`department`]: '',
        },
      });
      setBillingDepartment([]);
      let opSubCompany = target.value;
      if (opSubCompany !== '') {
        const getDepartment = billingSubOpCompany.find(
          (t) => t.subopco === opSubCompany
        ).dept;
        setBillingDepartment(getDepartment);
      }
    }

    setFormData({
      type: 'UPDATE_ACCOUNT_DETAILS',
      payload: { [target.name]: target.value },
    });

    if (formData.requestStatus === 'Pending') {
      if (
        formData.accountDetails.opco !== '' &&
        formData.accountDetails.purposeid !== '' &&
        formData.accountDetails.environmenttype !== ''
      ) {
        setFormData({
          type: 'UPDATE_ACCOUNT_DETAILS',
          payload: {
            [`accountName`]: `${
              formData.accountDetails.opco
            }_${formData.accountDetails.purposeid.replace(/_/g, '-')}_${
              formData.accountDetails.environmenttype
            }`,
          },
        });
      }
    }

  
  };

  const handleContinue = (e) => {
    e.preventDefault();

  // validate all the form fields

    const validationOrder = [ accountContactValidate, billingContactValidate, securityContactValidate, technicalContactValidate, billingInfoValidate, groupEmailAddressValidate, accountDetailsValidate ]

    const formDataOrder = [ 'accountContact', 'billingContact', 'securityContact', 'technicalContact', 'billingInfo', 'groupEmailAddress', 'accountDetails' ]
    let errors;

    dispatch({
      type: TAB_ERROR_VALUES,
      payload: [],
    });
    const errorArr=[]
      for(var i=0; i < validationOrder?.length; i++){

       errors = validationOrder[i](formData[formDataOrder[i]]);
       setDataInputError(errors);
       
      if(Object.keys(errors)?.length > 0 && i !== step){
        // setStep(i)
        errorArr.push(i)
      }
    }

    dispatch({
      type: TAB_ERROR_VALUES,
      payload: errorArr,
    });
    console.log("errorArr", errors)
    // ---- <END> ----

    // setDataInputError(errors);
    if (errorArr?.length > 0) {
      return;
    } else {
      // Form Submission
      setFormSubmitLoading(true);
      // const currentEpochTime = Date.now();
      const formSubmitApiUrl = `${process.env.REACT_APP_AWS_ACCOUNT_REQUEST_EDIT_REQUEST_SUBMIT}`;
      // Request body condition based on request status
      var updatedReqStatus;
      var updatedAccountName;
      if (
        formData.requestStatus === 'Approved' ||
        formData.requestStatus === 'Update Declined'
      ) {
        updatedReqStatus = 'Pending Approval for Update';
        updatedAccountName = formData.unEditableAccountName;
      }

      if (formData.requestStatus === 'Pending') {
        updatedReqStatus = 'Pending';
        updatedAccountName = formData.accountDetails.accountName;
      }

      // postApiCall(formSubmitApiUrl, idToken, {
      //   firstNameAO: formData.accountContact.firstNameAO,
      //   lastNameAO: formData.accountContact.lastNameAO,
      //   emailAO: formData.accountContact.emailAO,
      //   countryAO: formData.accountContact.countryAO,
      //   phoneNoAO: formData.accountContact.phoneNoAO,
      //   firstNameBC: formData.billingContact.firstNameBC,
      //   lastNameBC: formData.billingContact.lastNameBC,
      //   emailBC: formData.billingContact.emailBC,
      //   countryBC: formData.billingContact.countryBC,
      //   phoneNoBC: formData.billingContact.phoneNoBC,
      //   firstNameSC: formData.securityContact.firstNameSC,
      //   lastNameSC: formData.securityContact.lastNameSC,
      //   emailSC: formData.securityContact.emailSC,
      //   countrySC: formData.securityContact.countrySC,
      //   phoneNoSC: formData.securityContact.phoneNoSC,
      //   firstNameTC: formData.technicalContact.firstNameTC,
      //   lastNameTC: formData.technicalContact.lastNameTC,
      //   emailTC: formData.technicalContact.emailTC,
      //   countryTC: formData.technicalContact.countryTC,
      //   phoneNoTC: formData.technicalContact.phoneNoTC,
      //   billingopco: formData.billingInfo.billingopco,
      //   billingSubOpco: formData.billingInfo.billingSubOpco,
      //   billingDepartment: formData.billingInfo.billingDepartment,
      //   billingRegion: formData.billingInfo.billingRegion,
      //   maxSpend: formData.billingInfo.maxSpend,
      //   billto: formData.billingInfo.billto,
      //   io: formData.billingInfo.io,
      //   costcenter: formData.billingInfo.costcenter,
      //   technicalGroup: formData.groupEmailAddress.technicalGroup,
      //   securityGroup: formData.groupEmailAddress.securityGroup,
      //   billingGroup: formData.groupEmailAddress.billingGroup,
      //   pgcsgoldenami: formData.groupEmailAddress.pgcsgoldenami,
      //   ptm: userInfo.groups.includes(CS_Hub_AWS_Account_Request_Admin) ? formData.groupEmailAddress.ptm : "True",
      //   conformity: userInfo.groups.includes(CS_Hub_AWS_Account_Request_Admin) ? formData.groupEmailAddress.conformity : "True",
      //   sonyad: formData.groupEmailAddress.sonyad,
      //   opco: formData.accountDetails.opco,
      //   subopco: formData.accountDetails.subopco,
      //   department: formData.accountDetails.department,
      //   environmenttype: formData.accountDetails.environmenttype,
      //   assetUserBase: formData.accountDetails.assetUserBase,
      //   dataClassification: formData.accountDetails.dataClassification,
      //   purposeid: formData.accountDetails.purposeid,
      //   accountdescription: formData.accountDetails.accountdescription,
      //   accountName: `${updatedAccountName}`,
      //   requestStatus: `${updatedReqStatus}`,
      //   uid: formData.uid,
      //   submittedBy: formData.submittedBy,
      //   aws_account_id: formData.aws_account_id,
      //   RequestActionTakenBy: formData.RequestActionTakenBy,
      // }).then((response) => {
      //   if (response.data.status === 'Success') {
      //     // setNewRequestUid(`u-${currentEpochTime}`);
      //     setFormSubmitSuccess(true);
      //     setFormSubmitLoading(false);
      //   }
      // });
    }
  };

  useEffect(() => {
    if (accountRequestReloadType === true) {
      setFormSubmitSuccess(false);
    }
    setLoading(true);
    // adding query string fr oauth
    const queryObj = `{
      "submittedBy": "${userInfo?.email}",
      "type_of_user": ${userInfo.groups.includes(CS_Hub_AWS_Account_Request_Admin) ? 1 : 0}
    }`
    const encodedString = Buffer.from(queryObj).toString('base64');
    // end

    const apiUrl = `${process.env.REACT_APP_AWS_ACCOUNT_REQUEST_BILLING_INFO}?enchash=${encodedString}`;

    getApiCall(apiUrl, idToken).then((response) => {
      setResponseData(response.data.body);
      let getSubOpCompany = [];
      if (formData.accountDetails.opco !== '') {
        setBillingSubOpCompany([]);
        setBillingDepartment([]);

        const checkOpco = response.data.body.find(
          (t) => t.opco === formData.accountDetails.opco
        );

        if (checkOpco === undefined) {
          setBillingSubOpCompany([]);
        } else {
          getSubOpCompany = response.data.body.find(
            (t) => t.opco === formData.accountDetails.opco
          ).subopcod;
          setBillingSubOpCompany(getSubOpCompany);
        }
      }
      if (formData.accountDetails.subopco !== '') {
        setBillingDepartment([]);

        const checkSubOpco = getSubOpCompany.find(
          (t) => t.subopco === formData.accountDetails.subopco
        );

        if (checkSubOpco === undefined) {
          setBillingDepartment([]);
        } else {
          const getDepartment = getSubOpCompany.find(
            (t) => t.subopco === formData.accountDetails.subopco
          ).dept;
          setBillingDepartment(getDepartment);
        }
      }
      setLoading(false);
    });

    return () => {
      setResponseData([]);
    };
  }, [accountRequestReloadType]);

  const AccountNameValidation = useCallback(() => {
    
    let getAccDetails;
    const { purposeid, opco, environmenttype } = formData?.accountDetails

    const AccountDetailsValidation = () => {
      const initialSpace = purposeid?.trimStart()
      const endSpace = initialSpace?.trimEnd()

      const spaceRemoval = endSpace?.replace(/  +/g, ' ');

      getAccDetails = spaceRemoval?.replace(/[^a-zA-Z-]/g, "-");
      const adjustSingleSpace = getAccDetails?.replace(/ +/g, '-');
      const adjustedValue = adjustSingleSpace?.replace(/__+/g, '-');
      const adjustEmptySpace = adjustedValue?.replace(/_+/g, '-');

      let getSplittedValues = adjustEmptySpace?.split("")
      // getSplittedValues.map((x, i) => {
      //   if(x === '-'){
      //     getSplittedValues[i-1] = (getSplittedValues[i-1]==="-" || getSplittedValues[i-1]==="_") ? '' : getSplittedValues[i-1]
      //     getSplittedValues[i+1]=(getSplittedValues[i+1]==="-" || getSplittedValues[i+1]==="_") ? '' : getSplittedValues[i+1]
      //  }
      //  return 
      //  });
      return getSplittedValues.join("")
    }
    
    const getCombinedValues = opco +
  "_" + AccountDetailsValidation() + "_" +
  environmenttype
    return getCombinedValues
  }, [formData.accountDetails.purposeid, formData.accountDetails.opco, formData.accountDetails.environmenttype])
      
  const ApplicationNameValidation = () => {
    let getAccDetails;
    const { purposeid } = formData?.accountDetails

      const initialSpace = purposeid?.trimStart()
      // const endSpace = initialSpace?.trimEnd()

      const spaceRemoval = initialSpace?.replace(/  +/g, ' ');

      getAccDetails = spaceRemoval?.replace(/[^a-zA-Z_-]/g, "-");
      const adjustedValue = getAccDetails?.replace(/__+/g, '-');
      const adjustEmptySpace = adjustedValue?.replace(/_+/g, '-');
      let getSplittedValues = adjustEmptySpace?.split("")
      // getSplittedValues.map((x, i) => {
      //   if(x === '-'){
      //     getSplittedValues[i-1] = (getSplittedValues[i-1]==="-" || getSplittedValues[i-1]==="_") ? '' : getSplittedValues[i-1]
      //     getSplittedValues[i+1]=(getSplittedValues[i+1]==="-" || getSplittedValues[i+1]==="_") ? '' : getSplittedValues[i+1]
      //  }
      //  return 
      //  });
       if(getSplittedValues[0] === '-'){
        getSplittedValues.shift()
       }  
      return getSplittedValues.join("")    
  }

  const handleApplicationName = (e) => {
    const appName = e.target.value.split('')
    if (
      formData.accountDetails.purposeid !== '' && appName[appName?.length - 1] ==='-'
    ) {
      appName.pop()
      const purposeID = appName.join('')
      setFormData({
        type: 'UPDATE_ACCOUNT_DETAILS',
        payload: {
          [`purposeid`]: `${purposeID}`,
        },
      });
    } else {
      setFormData({
        type: 'UPDATE_ACCOUNT_DETAILS',
        payload: {
          [`purposeid`]: `${appName.join('')}`,
        },
      });
    }
  }
  return (
    <>
      <Row>
        <Col md={12} xs={12}>
          <EditFormMenu accountDetails setStep={setStep} step={step} formData={formData} setDataInputError={setDataInputError}/>
        </Col>
      </Row>
      <Form onSubmit={handleContinue}>
        {loading && (
          <div className='mb-3'>
            <Loader />
          </div>
        )}
        {loading === false && (
          <>
            <Row className='px-3'>
              <Col md={12}>
                <TextInput
                  readOnly
                  label='*Account Name:'
                  name='accountName'
                  value={ formData.unEditableAccountName !== '' ? `${formData.unEditableAccountName}`  : AccountNameValidation() }
                  onChange={(e) => handleData(e)}
                  placeholder='Account Name'
                  isInvalid={dataInputError.accountName ? true : false}
                  invalidMessage={dataInputError.accountName}
                  isInfoBubbleAvailable={true}
                  infoBubbleTitle='This is the name the account will show as in the console and billing report. It’s made up of Operating Company, Application name, Environment Type'
                />
              </Col>

              <Col md={12}>
                <TextInput
                  label='*Account Description:'
                  name='accountdescription'
                  value={formData.accountDetails.accountdescription}
                  onChange={(e) => handleData(e)}
                  placeholder='Account Description'
                  isInvalid={dataInputError.accountdescription ? true : false}
                  invalidMessage={dataInputError.accountdescription}
                />
              </Col>

              <Col md={12}>
                <TextInput
                  label='*Application Name:'
                  name='purposeid'
                  readOnly={
                    formData.unEditableAccountName !== '' ? true : false
                  }
                  value={ApplicationNameValidation()}
                  onBlur={(e) => { handleApplicationName(e); handleData(e)}}
                  onChange={(e) => handleData(e)}
                  placeholder='Application Name'
                  isInvalid={dataInputError.purposeid ? true : false}
                  invalidMessage={dataInputError.purposeid}
                />
              </Col>

              <Col md={12}>
                <BillingCompanyDropdown
                  label='*Operating Company:'
                  defaultSelectMessage='Select Operating Company'
                  name='opco'
                  value={formData.accountDetails.opco}
                  onChange={(e) => handleData(e)}
                  selectValues={responseData}
                  isInvalid={dataInputError.opco ? true : false}
                  invalidMessage={dataInputError.opco}
                />
              </Col>
              <Col md={12}>
                <BillingSubCompanyDropdown
                  label='*Sub Operating Company:'
                  defaultSelectMessage='Select Sub Operating Company'
                  name='subopco'
                  value={formData.accountDetails.subopco}
                  onChange={(e) => handleData(e)}
                  selectValues={billingSubOpCompany}
                  isInvalid={dataInputError.subopco ? true : false}
                  invalidMessage={dataInputError.subopco}
                />
              </Col>
              <Col md={12}>
                <BillingDepartmentDropdown
                  label='*Department:'
                  defaultSelectMessage='Select Department'
                  name='department'
                  value={formData.accountDetails.department}
                  onChange={(e) => handleData(e)}
                  selectValues={billingDepartment}
                  isInvalid={dataInputError.department ? true : false}
                  invalidMessage={dataInputError.department}
                />
              </Col>

              <Col md={12}>
                <StaticDropdown
                  label='*Environment Type:'
                  defaultSelectMessage='Select Environment Type'
                  name='environmenttype'
                  value={formData.accountDetails.environmenttype}
                  onChange={(e) => handleData(e)}
                  selectValues={staticEnvironmentType}
                  isInvalid={dataInputError.environmenttype ? true : false}
                  invalidMessage={dataInputError.environmenttype}
                />
              </Col>

              <Col md={12}>
                <StaticDropdown
                  label='*Asset User Base:'
                  defaultSelectMessage='Select Asset User Base'
                  name='assetUserBase'
                  value={formData.accountDetails.assetUserBase}
                  onChange={(e) => handleData(e)}
                  selectValues={staticAssetUserBase}
                  isInvalid={dataInputError.assetUserBase ? true : false}
                  invalidMessage={dataInputError.assetUserBase}
                />
              </Col>

              <Col md={12}>
                <StaticDropdown
                  label='*Data Classification:'
                  defaultSelectMessage='Select Data Classification'
                  name='dataClassification'
                  value={formData.accountDetails.dataClassification}
                  onChange={(e) => handleData(e)}
                  selectValues={staticDataClassification}
                  isInvalid={dataInputError.dataClassification ? true : false}
                  invalidMessage={dataInputError.dataClassification}
                />
              </Col>
            </Row>
            
            <Row className='px-3'>
            {
            tabErrorValues?.length ?
              <div className='d-flex justify-content-end'>
                <p className='custom-error-message-text fw-normal w-auto my-0'>Please fill the mandatory fields</p>
              </div>
            : <div className='d-flex justify-content-end invisible'>
            <p className='custom-error-message-text fw-normal w-auto my-0'>Please fill the mandatory fields</p>
          </div> }

              <div className='d-flex justify-content-end'>
                {formSubmitLoading === true && <Loader />}
                {formSubmitLoading === false && (
                  <ul className='px-0 btn-ul'>
                    <li>
                      <button
                        type='button'
                        className='teal-bordered-btn'
                        onClick={() => setStep(step - 1)}
                      >
                        Back
                      </button>
                    </li>
                    <li>
                      <button
                        type='button'
                        className='teal-bordered-btn'
                        onClick={() => {
                          setShowCancelModal(true);
                        }}
                      >
                        Cancel
                      </button>
                    </li>
                    <li>
                      <button type='submit' className='teal-filled-btn'>
                        Save & Submit
                      </button>
                    </li>
                  </ul>
                )}
              </div>
            </Row>
          </>
        )}
      </Form>
      {formSubmitSuccess && (
        <CustomModal showModal={true} children={<EditRequestSubmit />} />
      )}
      {showCancelModal && (
        <CustomModal
          showModal={true}
          children={<EditRequestCancel closeModalParent={closeModalCallback} />}
        />
      )}
    </>
  );
};

export default EditAccountDetails;
