import React from "react";

const FinancePrompt = (props) => {
  const options = [
    {
      text: "Hosting charges for last 3 months?",
      handler: () => props.actionProvider.handleFinanceInfo("hosting6"),
      id: 11,
    },
    {
      text: "Hosting charges for current month?",
      handler: () => props.actionProvider.handleFinanceInfo("hostingCurrent"),
      id: 12,
    },
  ];

  const optionsMarkup = options.map((option) => {
    return (
      <button
        key={option.id}
        onClick={option.handler}
        className="option-button"
      >
        {option.text}
      </button>
    );
  });

  return (
    <>
      <div className="options-container">{optionsMarkup}</div>
    </>
  );
};

export default FinancePrompt;
