import React, { useState } from "react";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

import Like from "icons/thumbs_up.svg";
import SelectedLike from "icons/thumbs_up_selected.svg";
import DisLike from "icons/thumbs-down.svg";
import selectedDisLike from "icons/thumbs-down_selected.svg";
import Download from "icons/chat-download.svg";

const TableDownload = ({
  formattedCsvData,
  isLiked,
  setLiked,
  isDisLiked,
  setDisLiked,
}) => {
  // To change the file name modify here
  const fileName = `Table data`;
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = () => {
    const ws = XLSX.utils.json_to_sheet(formattedCsvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  return (
    <div
      className="text-end float-end vertical-align-middle"
      key={formattedCsvData[0]}
    >
      {!isLiked ? (
        <img
          src={Like}
          alt="cloudEye icon"
          className="reaction-icons me-2 cursor-pointer"
          onClick={() => {
            setLiked(true);
            if (isDisLiked) {
              setDisLiked(false);
            }
          }}
        />
      ) : (
        <img
          src={SelectedLike}
          alt="cloudEye icon"
          className="reaction-icons me-2 cursor-pointer"
          onClick={() => {
            setLiked(false);
          }}
        />
      )}
      {!isDisLiked ? (
        <img
          src={DisLike}
          alt="cloudEye icon"
          className="reaction-icons me-2 cursor-pointer"
          onClick={() => {
            setDisLiked(true);
            if (isLiked) {
              setLiked(false);
            }
          }}
        />
      ) : (
        <img
          src={selectedDisLike}
          alt="cloudEye icon"
          className="reaction-icons me-2 cursor-pointer"
          onClick={() => {
            setDisLiked(false);
          }}
        />
      )}
      <img
        src={Download}
        alt="cloudEye icon"
        className="reaction-icons cursor-pointer"
        onClick={() => {
          exportToCSV();
        }}
      />
    </div>
  );
};

export default TableDownload;
