import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col } from "react-bootstrap";
import moment from "moment";
import { useTranslation } from "react-i18next";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";

import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  PaginationTotalStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

// Import Icon
import dots from "icons/3-dots.svg";

import Loader from "components/Common/Loader";
import CustomModal from "components/Modal/CustomModal";

// Import Internal Components
import AccountRequestsView from "./AccountRequestsView";
import AccountRequestsEdit from "./AccountRequestsEdit";
import AccountRequestsApprove from "./AccountRequestsApprove";
import AccountRequestsDecline from "./AccountRequestsDecline";
import AccountRequestsCancel from "./AccountRequestsCancel";

// API Call
import { postApiCall } from "helper/postApiCall";

import { ACCOUNT_BACKUP_RELOAD } from "redux/account-requests/accountRequestConstant";
import { Link } from "react-router-dom";

import { CS_Hub_AWS_Account_Request_Admin, Everyone_Role } from "roles/roles";

const AccountRequestsTable = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Redux State
  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  const accountRequestBackup = useSelector(
    (state) => state.accountRequestBackup
  );

  const { accountRequestBackupType } = accountRequestBackup;

  // API URL
  const apiUrl = `${process.env.REACT_APP_AWS_BACKUP_VIEW}`;

  // ID Token
  const idToken = `${userInfo.idToken}`;

  // Request Body
  const requestBody = userInfo?.groups?.includes(
    CS_Hub_AWS_Account_Request_Admin
  )
    ? `{"type_of_user":1}`
    : `{"submittedBy":"${userInfo.preferred_username}", "email":"${userInfo.email}", "type_of_user":0}`;

  const fetchTableData = useCallback(() => {
    setLoading(true);
    setOptions({
      showOption: null,
      selectedOptionUID: null,
      selectedOptionData: {},
    });
    postApiCall(apiUrl, idToken, requestBody).then((response) => {
      setResponseData(response?.data);
      setLoading(false);
    });
  }, [apiUrl, idToken, requestBody]);

  // ! Option Part
  const [options, setOptions] = useState({
    showOption: null,
    selectedOptionUID: null,
    selectedOptionData: {},
  });

  // Option Click Handler
  function optionHandler(selectedOptionDataValues, isMenuOpen = null) {
    if (options.showOption || isMenuOpen) {
      setOptions({
        showOption: false,
        selectedOptionUID: selectedOptionDataValues.uid,
        selectedOptionData: selectedOptionDataValues,
      });
    } else {
      setOptions({
        showOption: true,
        selectedOptionUID: selectedOptionDataValues.uid,
        selectedOptionData: selectedOptionDataValues,
      });
    }
  }

  // ! View Part
  // ! View Box State & Callback
  const [showViewBox, setShowViewBox] = useState(false);

  // ! Close View Callback
  const closeViewCallback = useCallback((value) => {
    setShowViewBox(value);
  }, []);

  // ! Edit Part
  // ! Edit Box State & Callback
  const [showEditBox, setShowEditBox] = useState(false);

  // ! Edit Callback
  const editCallback = useCallback((value) => {
    setShowEditBox(value);
  }, []);

  // ! Modal Part
  // ! Modal State & Callback
  const [showModalBox, setShowModalBox] = useState(null);

  //account number state
  const [accountNumberNameData, setAccountNumberNameData] = useState([]);
  const [accountNumberArray, setAccountNumberArray] = useState(null);
  const [getAccountsApiError, setGetAccountsApiError] = useState(null);

  // ! Close Modal Callback (Modal No Click Part)
  const closeModalCallback = useCallback((value) => {
    setShowModalBox(value);
  }, []);

  // !  Modal Yes Click Part & Reload Table Data
  const yesCallback = useCallback(
    (value) => {
      setShowModalBox(value);
      fetchTableData();
    },
    [fetchTableData]
  );

  // Table State
  const [Loading, setLoading] = useState(false);
  const [apiLoading, setapiLoading] = useState(false);
  const [responseData, setResponseData] = useState([]);

  // Data Tables
  const columns = [
    {
      // dataField: 'uid',
      dataField: "dummyCreatedOn",
      isDummyField: true,
      text: t("request_id"),
      sort: true,
      formatter: (cell, row) => {
        return <span className="text-uppercase">{`${row.uid}`}</span>;
      },
    },
    // {
    //   dataField: 'firstNameAO',
    //   text: 'ACCOUNT OWNER',
    //   formatter: (cell, row) => {
    //     return <div>{`${row.firstNameAO} ${row.lastNameAO}`}</div>;
    //   },
    //   sort: true,
    // },
    {
      dataField: "accountName",
      text: t("account_name"),
      sort: true,
      style: { width: "10%" },
    },
    {
      dataField: "accountNumber",
      text: t("account_number"),
      sort: true,
    },
    {
      dataField: "region",
      text: t("Region"),
      sort: true,
    },

    {
      // dataField: 'dummyCreatedOn',
      // isDummyField: true,
      dataField: "uid",
      text: t("created_on"),
      sort: true,
      formatter: (cellContent, row) => {
        let dateConverted = moment(parseInt(row.uid.split("-")[1])).format(
          "MMM DD, YYYY hh:mm A"
        );
        return <span>{dateConverted}</span>;
      },
      sortFunc: (a, b, order) => {
        let dateConvertedA = moment(parseInt(a.split("-")[1])).format(
          "MMM DD, YYYY hh:mm A"
        );
        let dateConvertedB = moment(parseInt(b.split("-")[1])).format(
          "MMM DD, YYYY hh:mm A"
        );
        if (order === "asc") {
          return Date.parse(dateConvertedA) - Date.parse(dateConvertedB);
        } else if (order === "desc") {
          return Date.parse(dateConvertedB) - Date.parse(dateConvertedA);
        }
      },
    },
    {
      dataField: "requestStatus",
      text: t("status"),
      sort: true,
      formatter: (cellContent, row) => {
        if (row.requestStatus === "Approved") {
          return (
            <div
              className="approved-badge text-capitalize"
              style={{ fontSize: "11px" }}
            >
              {row.requestStatus}
            </div>
          );
        }
        if (
          row.requestStatus === "Pending" ||
          row.requestStatus === "Processing"
        ) {
          return (
            <div
              className="pending-badge text-capitalize"
              style={{ fontSize: "11px" }}
            >
              {row.requestStatus}
            </div>
          );
        }
        if (row.requestStatus === "Pending Approval for Update") {
          return (
            <div
              className="pending-badge text-capitalize"
              style={{ fontSize: "11px" }}
            >
              {row.requestStatus}
            </div>
          );
        }
        if (row.requestStatus === "Cancelled") {
          return (
            <div
              className="canceled-badge text-capitalize"
              style={{ fontSize: "11px" }}
            >
              {row.requestStatus}
            </div>
          );
        }
        if (row.requestStatus === "Declined") {
          return (
            <div
              className="declined-badge text-capitalize"
              style={{ fontSize: "11px" }}
            >
              {row.requestStatus}
            </div>
          );
        }
        if (row.requestStatus === "Update Declined") {
          return (
            <div
              className="declined-badge text-capitalize"
              style={{ fontSize: "11px" }}
            >
              {row.requestStatus}
            </div>
          );
        }
      },
    },
    {
      dataField: "dummyActionField",
      text: "",
      formatExtraData: options,
      formatter: (cellContent, row) => {
        return (
          <div
            className="custom-table-option-conatiner"
            tabindex="0"
            onBlur={() => {
              optionHandler(row, "open");
            }}
            onClick={() => {
              optionHandler(row);
            }}
          >
            <img src={dots} alt="action" className="p-2" />
            {options.showOption === true &&
              options.selectedOptionUID === row.uid && (
                <div className="custom-table-option">
                  <ul>
                    {/* Pending Logic Condition */}
                    {row.requestStatus === "Declined" &&
                      (userInfo?.groups?.includes(
                        CS_Hub_AWS_Account_Request_Admin
                      ) ||
                        row.emailAO.toLowerCase() ===
                          userInfo.email.toLowerCase() ||
                        row.emailTC.toLowerCase() ===
                          userInfo.email.toLowerCase() ||
                        row.submittedBy === userInfo.preferred_username) && (
                        <>
                          <li
                            onClick={() => {
                              setShowViewBox(true);
                            }}
                          >
                            {t("view")}
                          </li>
                          <li
                            onClick={() => {
                              setShowEditBox(true);
                            }}
                          >
                            {t("edit")}
                          </li>
                          <li
                            onClick={() => {
                              setShowModalBox("Cancel");
                            }}
                          >
                            {t("cancel")}
                          </li>
                        </>
                      )}

                    {userInfo?.groups?.includes(
                      CS_Hub_AWS_Account_Request_Admin
                    ) &&
                      row.requestStatus === "Pending" && (
                        <>
                          <li
                            onClick={() => {
                              setShowViewBox(true);
                            }}
                          >
                            {t("view")}
                          </li>
                          <li
                            onClick={() => {
                              setShowEditBox(true);
                            }}
                          >
                            {t("edit")}
                          </li>

                          <li
                            onClick={() => {
                              setShowModalBox("Approve");
                            }}
                          >
                            {t("approve")}
                          </li>
                          <li
                            onClick={() => {
                              setShowModalBox("Decline");
                            }}
                          >
                            {t("decline")}
                          </li>
                          <li
                            onClick={() => {
                              setShowModalBox("Cancel");
                            }}
                          >
                            {t("cancel")}
                          </li>
                        </>
                      )}

                    {/* Approved Logic Condition */}
                    {row.requestStatus === "Approved" &&
                    userInfo?.groups?.includes(Everyone_Role) &&
                    row.submittedBy === userInfo.preferred_username ? (
                      <li
                        onClick={() => {
                          setShowViewBox(true);
                        }}
                      >
                        {t("view")}
                      </li>
                    ) : (row.requestStatus === "Approved" ||
                        row.requestStatus === "Processing") &&
                      (userInfo?.groups?.includes(
                        CS_Hub_AWS_Account_Request_Admin
                      ) ||
                        row.emailAO.toLowerCase() ===
                          userInfo.email.toLowerCase() ||
                        row.emailTC.toLowerCase() ===
                          userInfo.email.toLowerCase()) ? (
                      <>
                        <li
                          onClick={() => {
                            setShowViewBox(true);
                          }}
                        >
                          {t("view")}
                        </li>
                        {/* <li
                          onClick={() => {
                            setShowEditBox(true);
                          }}
                        >
                          {t('edit')}
                        </li> */}
                      </>
                    ) : (
                      <></>
                    )}

                    {/* Updated Logic Condition */}
                    {(userInfo?.groups?.includes(
                      CS_Hub_AWS_Account_Request_Admin
                    ) ||
                      userInfo?.groups?.includes(Everyone_Role)) &&
                      row.requestStatus === "Pending Approval for Update" && (
                        <>
                          <li
                            onClick={() => {
                              setShowViewBox(true);
                            }}
                          >
                            {t("view")}
                          </li>
                        </>
                      )}
                    {userInfo?.groups?.includes(
                      CS_Hub_AWS_Account_Request_Admin
                    ) &&
                      row.requestStatus === "Pending Approval for Update" && (
                        <>
                          <li
                            onClick={() => {
                              setShowModalBox("Approve");
                            }}
                          >
                            {t("approve")}
                          </li>
                          <li
                            onClick={() => {
                              setShowModalBox("Decline");
                            }}
                          >
                            {t("decline")}
                          </li>
                        </>
                      )}
                    {/* Declined & Canceled Logic Condition */}
                    {(userInfo?.groups?.includes(
                      CS_Hub_AWS_Account_Request_Admin
                    ) ||
                      userInfo?.groups?.includes(Everyone_Role)) &&
                      (row.requestStatus === "Declined" ||
                        row.requestStatus === "Cancelled") && (
                        <li
                          onClick={() => {
                            setShowViewBox(true);
                          }}
                        >
                          View
                        </li>
                      )}

                    {/* Update Declined */}
                    {row.requestStatus === "Update Declined" &&
                    userInfo?.groups?.includes(Everyone_Role) &&
                    row.submittedBy === userInfo.preferred_username ? (
                      <li
                        onClick={() => {
                          setShowViewBox(true);
                        }}
                      >
                        View
                      </li>
                    ) : row.requestStatus === "Update Declined" &&
                      (userInfo?.groups?.includes(
                        CS_Hub_AWS_Account_Request_Admin
                      ) ||
                        row.emailAO.toLowerCase() ===
                          userInfo.email.toLowerCase() ||
                        row.emailTC.toLowerCase() ===
                          userInfo.email.toLowerCase()) ? (
                      <>
                        <li
                          onClick={() => {
                            setShowViewBox(true);
                          }}
                        >
                          {t("view")}
                        </li>
                        <li
                          onClick={() => {
                            setShowEditBox(true);
                          }}
                        >
                          {t("edit")}
                        </li>
                      </>
                    ) : (
                      <></>
                    )}
                  </ul>
                </div>
              )}
          </div>
        );
      },
    },
  ];

  const defaultSorted = [
    {
      dataField: "uid",
      order: "desc",
    },
  ];

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      {t("showing_results", { from, to, size })}
    </span>
  );

  const pageOptions = {
    sizePerPage: 10,
    totalSize: responseData?.length,
    custom: true,
    paginationTotalRenderer: customTotal,
  };

  const { SearchBar } = Search;

  // API Variables
  const accountNumberapi = `${process.env.REACT_APP_CUSTOM_ROLE_ACCOUNT_NUMBER_NAME}`;

  // Account Number Useeffect
  useEffect(() => {
    setGetAccountsApiError(null);
    setapiLoading(true);
    setAccountNumberNameData([]);

    const requestBody = {
      email: `${userInfo?.email}`,
      user_type: `${
        userInfo.groups.includes(CS_Hub_AWS_Account_Request_Admin)
          ? "admin"
          : "user"
      }`,
    };

    postApiCall(accountNumberapi, idToken, requestBody)
      .then((response) => {
        setapiLoading(false);
        console.log("response", response);
        setAccountNumberNameData(response?.data);

        const accountNumberOptions = response?.data
          ?.filter((f) => f !== "")
          .map((d) => ({
            value: d.split(" - ")[0],
            label: d.split(" - ")[0],
          }));

        setAccountNumberArray(accountNumberOptions);

        setapiLoading(false);
      })
      .catch((err) => {
        setapiLoading(false);
        setGetAccountsApiError(
          "Failed to Retrieve data, Contact Sony Global Cloud Team for Assistance"
        );
      });

    return () => {
      setAccountNumberNameData([]);
    };
  }, []);

  useEffect(() => {
    if (accountRequestBackupType === true) {
      setShowEditBox(false);
      dispatch({
        type: ACCOUNT_BACKUP_RELOAD,
        payload: false,
      });
    }
    fetchTableData();
    return () => {
      setResponseData([]);
    };
  }, [fetchTableData, accountRequestBackupType, dispatch]);

  return (
    <div>
      {(Loading || apiLoading || getAccountsApiError) && <Loader />}

      {Loading === false &&
        apiLoading === false &&
        getAccountsApiError === null && (
          <PaginationProvider
            pagination={paginationFactory(pageOptions)}
            keyField="id"
            columns={columns}
            data={responseData}
          >
            {({ paginationProps, paginationTableProps }) => (
              <ToolkitProvider
                keyField="uid"
                columns={columns}
                data={responseData}
                search
              >
                {(toolkitProps) => (
                  <React.Fragment>
                    <Row className="mb-2">
                      <Col md={4} className="d-flex align-items-center mb-2">
                        <h5 className="page-content-title">
                          {t("AWS Backup Request")}
                        </h5>
                      </Col>

                      <Col md={1} className="mb-2">
                        <button
                          className="teal-bordered-btn w-100"
                          type="button"
                          onClick={() => {
                            fetchTableData();
                          }}
                        >
                          <i className="fa fa-refresh"></i>
                        </button>
                      </Col>

                      <Col md={3} className="mb-2">
                        <div className="search-input-group">
                          <i className="fa fa-search"></i>
                          <SearchBar
                            {...toolkitProps.searchProps}
                            className="custom-search-input-form-control"
                            placeholder={t("search")}
                          />
                        </div>
                      </Col>

                      <Col md={4} className="mb-2">
                        <Link to="/aws-backup-new-request">
                          <button className="teal-filled-btn w-100">
                            <i className="fa fa-plus mx-2"></i>
                            {t("new_request")}
                          </button>
                        </Link>
                      </Col>
                    </Row>

                    <Row>
                      <Col xl="12" className="mt-4">
                        <div className="table-responsive">
                          <BootstrapTable
                            keyField={"id"}
                            responsive
                            bordered={false}
                            striped={false}
                            defaultSorted={defaultSorted}
                            classes={"table align-middle table-nowrap"}
                            headerWrapperClasses={
                              "custom-table-head custom-pointer"
                            }
                            bodyClasses={"custom-table-body"}
                            {...toolkitProps.baseProps}
                            {...paginationTableProps}
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row className="align-items-md-center mt-3">
                      <Col className="d-flex flex-wrap justify-content-between align-items-baseline">
                        <div>
                          <PaginationTotalStandalone {...paginationProps} />
                        </div>
                        <div>
                          <PaginationListStandalone {...paginationProps} />
                        </div>
                      </Col>
                    </Row>
                  </React.Fragment>
                )}
              </ToolkitProvider>
            )}
          </PaginationProvider>
        )}

      {Loading === false &&
        apiLoading === false &&
        responseData?.length === 0 && (
          <div className="d-flex justify-content-center align-items-center">
            No Data to Display
          </div>
        )}

      {/* View Box */}
      {showViewBox && (
        <AccountRequestsView
          data={options.selectedOptionData}
          cloudTitle="AWS"
          closeViewParent={closeViewCallback}
          openEditParent={editCallback}
          userInfo={userInfo}
        />
      )}

      {/* Edit Box */}
      {showEditBox && (
        <AccountRequestsEdit
          data={options.selectedOptionData}
          cloudTitle="AWS"
          closeEditParent={editCallback}
          accountNumberNameData={accountNumberNameData}
          accountNumberArray={accountNumberArray}
        />
      )}

      {/* Pending to Approve Modal */}
      {showModalBox === "Approve" && (
        <CustomModal
          showModal={true}
          children={
            <AccountRequestsApprove
              closeModalParent={closeModalCallback}
              yesParent={yesCallback}
              status={options.selectedOptionData.requestStatus}
              uid={options.selectedOptionUID}
            />
          }
        />
      )}

      {/* Pending to Decline Modal */}
      {showModalBox === "Decline" && (
        <CustomModal
          showModal={true}
          children={
            <AccountRequestsDecline
              closeModalParent={closeModalCallback}
              yesParent={yesCallback}
              status={options.selectedOptionData.requestStatus}
              uid={options.selectedOptionUID}
            />
          }
        />
      )}

      {/* Pending to Cancel Modal */}
      {showModalBox === "Cancel" && (
        <CustomModal
          showModal={true}
          children={
            <AccountRequestsCancel
              closeModalParent={closeModalCallback}
              yesParent={yesCallback}
              status={options.selectedOptionData.requestStatus}
              uid={options.selectedOptionUID}
            />
          }
        />
      )}

      {/* Pending Approval for Update to Approve Modal */}
      {showModalBox === "Pending Approval for Update" && (
        <CustomModal
          showModal={true}
          children={
            <AccountRequestsApprove
              closeModalParent={closeModalCallback}
              yesParent={yesCallback}
              status={options.selectedOptionData.requestStatus}
              uid={options.selectedOptionUID}
            />
          }
        />
      )}
    </div>
  );
};

export default AccountRequestsTable;
