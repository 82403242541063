import React, { useState, useCallback } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';

// Import Form Component
import TextInput from 'components/Forms/TextInput';
import StaticDefaultValueDropdown from 'components/Forms/StaticDefaultValueDropdown';
import CustomModal from 'components/Modal/CustomModal';

// Import Internal Component
import EditRequestCancel from './EditRequestCancel';
import EditFormMenu from './EditFormMenu';

// Validator
import { groupEmailAddressValidate } from '../../../Validator/validator';

// Static Data
import { trueFalseData } from '../../StaticData/awsStaticData';

import { CS_Hub_AWS_Account_Request_Admin } from 'roles/roles';

const EditGroupEmailAddress = ({ step, setStep, formData, setFormData }) => {

  // Redux State
  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  // Data Input Error State
  const [dataInputError, setDataInputError] = useState({});

  // Show Cancel Modal
  const [showCancelModal, setShowCancelModal] = useState(false);

  // Close Modal Callback (Modal No Click Part)
  const closeModalCallback = useCallback((value) => {
    setShowCancelModal(value);
  }, []);

  // handle onchange
  const handleData = ({ target }) => {
    setFormData({
      type: 'UPDATE_GROUP_EMAIL_ADDRESS',
      payload: { [target.name]: target.value },
    });
  };

  const handleContinue = (e) => {
    e.preventDefault();
    const errors = groupEmailAddressValidate(formData.groupEmailAddress);
    setDataInputError(errors);
    if (Object.keys(errors).length > 0) {
      return;
    } else {
      setStep(step + 1);
    }
  };

  return (
    <>
      <Row>
        <Col md={12} xs={12}>
          <EditFormMenu groupEmailAddress setStep={setStep} step={step} formData={formData} setDataInputError={setDataInputError}/>
        </Col>
      </Row>
      <Form onSubmit={handleContinue}>
        <Row className='px-3'>
          <Col md={12}>
            <TextInput
              label='*Technical Group:'
              name='technicalGroup'
              value={formData.groupEmailAddress.technicalGroup}
              onChange={(e) => handleData(e)}
              placeholder='Technical Group'
              isInvalid={dataInputError.technicalGroup ? true : false}
              invalidMessage={dataInputError.technicalGroup}
              isInfoBubbleAvailable={true}
              infoBubbleTitle='This is the email distribution list, preferably the application team DL who will be responsible for technical aspect of the cloud account'
            />
          </Col>
          <Col md={12}>
            <TextInput
              label='*Security Group:'
              name='securityGroup'
              value={formData.groupEmailAddress.securityGroup}
              onChange={(e) => handleData(e)}
              placeholder='Security Group'
              isInvalid={dataInputError.securityGroup ? true : false}
              invalidMessage={dataInputError.securityGroup}
              isInfoBubbleAvailable={true}
              infoBubbleTitle='This is the email distribution list, preferably the application team DL who will be responsible for security aspect of the cloud account'
            />
          </Col>
          <Col md={12}>
            <TextInput
              label='*Billing Group:'
              name='billingGroup'
              value={formData.groupEmailAddress.billingGroup}
              onChange={(e) => handleData(e)}
              placeholder='Billing Group'
              isInvalid={dataInputError.billingGroup ? true : false}
              invalidMessage={dataInputError.billingGroup}
              isInfoBubbleAvailable={true}
              infoBubbleTitle='This is the email distribution list, preferably the application team DL who will be responsible for billing aspect of the cloud account'
            />
          </Col>
        </Row>
        <Row className='px-3 mt-3'>
          <Col md={12} xs={12}>
            <h5 className='page-content-title mb-4'>Additional Details</h5>
          </Col>
        </Row>
        <Row className='px-3 mt-1'>
          <Col md={12}>
            <StaticDefaultValueDropdown
              label='*Avail Golden AMI:'
              name='pgcsgoldenami'
              value={formData.groupEmailAddress.pgcsgoldenami}
              onChange={(e) => handleData(e)}
              selectValues={trueFalseData}
              isInvalid={dataInputError.pgcsgoldenami ? true : false}
              invalidMessage={dataInputError.pgcsgoldenami}
              isInfoBubbleAvailable={true}
              infoBubbleTitle='Select Yes if you want to use Sony Golden AMI for your EC2 instances'
            />
          </Col>
          { userInfo.groups.includes(CS_Hub_AWS_Account_Request_Admin) ?
          <>
          <Col md={12}>
            <StaticDefaultValueDropdown
              label='*Avail Trend Micro:'
              name='ptm'
              value={formData.groupEmailAddress.ptm}
              onChange={(e) => handleData(e)}
              selectValues={trueFalseData}
              isInvalid={dataInputError.ptm ? true : false}
              invalidMessage={dataInputError.ptm}
              isInfoBubbleAvailable={true}
              infoBubbleTitle='Select Yes for cloud server security by Trend Micro Deep Security Tool. This tool is acquired by Global Security'
            />
          </Col>
          <Col md={12}>
            <StaticDefaultValueDropdown
              label='*Avail Conformity Tool:'
              name='conformity'
              value={formData.groupEmailAddress.conformity}
              onChange={(e) => handleData(e)}
              selectValues={trueFalseData}
              isInvalid={dataInputError.conformity ? true : false}
              invalidMessage={dataInputError.conformity}
              isInfoBubbleAvailable={true}
              infoBubbleTitle='By default we will be onboarding your Account to Trendmicro. Trendmicro tool is acquired by Global Security , this is for future reference only'
            />
          </Col>
          </>
          : null
          }
          <Col md={12}>
            <StaticDefaultValueDropdown
              label='*Avail Managed AD:'
              name='sonyad'
              value={formData.groupEmailAddress.sonyad}
              onChange={(e) => handleData(e)}
              selectValues={trueFalseData}
              isInvalid={dataInputError.sonyad ? true : false}
              invalidMessage={dataInputError.sonyad}
              isInfoBubbleAvailable={true}
              infoBubbleTitle='Select Yes if you would like to consume Sony specific Managed AD service for domain joining or lookup for Machines'
            />
          </Col>
        </Row>
        <Row className='px-3'>
          <div className='d-flex justify-content-end'>
            <ul className='px-0 btn-ul'>
              <li>
                <button
                  type='button'
                  className='teal-bordered-btn'
                  onClick={() => setStep(step - 1)}
                >
                  Back
                </button>
              </li>
              <li>
                <button
                  type='button'
                  className='teal-bordered-btn'
                  onClick={() => {
                    setShowCancelModal(true);
                  }}
                >
                  Cancel
                </button>
              </li>
              <li>
                <button type='submit' className='teal-filled-btn'>
                  Save & Continue
                </button>
              </li>
            </ul>
          </div>
        </Row>
      </Form>
      {showCancelModal && (
        <CustomModal
          showModal={true}
          children={<EditRequestCancel closeModalParent={closeModalCallback} />}
        />
      )}
    </>
  );
};

export default EditGroupEmailAddress;
