import React from "react";

function BotIcon({ type = null }) {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="50px"
      height="50px"
      viewBox="0 0 256.000000 256.000000"
      preserveAspectRatio="xMidYMid meet"
      className={`${type === "header" && "header-bot-icon"} ${
        type === "topSection" && "chat-window-top"
      }`}
    >
      <g
        transform="translate(0.000000,256.000000) scale(0.100000,-0.100000)"
        fill="#fff"
        stroke="none"
      >
        <path
          d="M1198 2545 c-135 -50 -201 -210 -139 -338 25 -52 78 -101 129 -119
31 -12 32 -13 32 -75 l0 -62 -357 -3 c-354 -3 -359 -3 -403 -27 -89 -46 -154
-125 -179 -218 -11 -41 -12 -41 -74 -52 -80 -14 -133 -51 -166 -116 -26 -49
-26 -52 -29 -322 -2 -175 1 -292 8 -327 20 -97 112 -175 206 -176 l41 0 16
-59 c29 -103 119 -196 219 -226 40 -11 104 -15 279 -15 l228 0 113 -196 c117
-203 138 -227 181 -207 14 7 64 84 135 207 l113 196 228 0 c175 0 239 4 279
15 100 30 190 123 219 226 l16 59 41 0 c23 0 59 9 82 19 50 23 111 93 121 140
5 20 8 167 8 326 l0 291 -26 49 c-33 65 -86 102 -166 116 -62 11 -63 11 -74
52 -25 93 -90 172 -179 218 -44 24 -49 24 -402 27 l-358 3 0 62 c0 62 1 63 33
75 132 48 191 214 121 342 -16 29 -42 63 -59 76 -67 51 -162 67 -237 39z m136
-120 c86 -41 89 -177 5 -221 -36 -18 -82 -18 -119 1 -81 42 -80 178 2 218 41
21 71 21 112 2z m744 -629 c26 -18 51 -47 67 -79 l25 -51 0 -485 c0 -452 -2
-489 -19 -526 -10 -22 -31 -52 -47 -67 -58 -54 -81 -58 -354 -58 -247 0 -251
0 -273 -22 -12 -13 -60 -89 -106 -170 -46 -82 -87 -148 -91 -148 -4 0 -45 66
-91 148 -46 81 -94 157 -106 169 -22 23 -26 23 -273 23 -273 0 -296 4 -354 58
-16 15 -37 45 -47 67 -17 37 -19 74 -19 525 0 458 2 487 20 528 22 48 61 88
105 108 24 11 171 13 776 11 l746 -2 41 -29z m-1808 -616 l0 -350 -34 0 c-22
0 -45 9 -65 26 l-31 26 0 299 0 300 26 24 c19 18 38 25 65 25 l39 0 0 -350z
m2124 324 l26 -27 0 -297 0 -298 -31 -26 c-20 -17 -43 -26 -65 -26 l-34 0 0
350 0 350 39 0 c28 0 45 -7 65 -26z"
        />
        <path
          d="M752 1521 c-187 -47 -208 -311 -30 -386 86 -35 180 -9 242 68 26 32
31 48 34 105 3 56 0 74 -21 112 -41 77 -141 122 -225 101z m79 -121 c23 0 49
-42 49 -79 0 -31 -6 -44 -26 -60 -56 -44 -134 -11 -134 57 0 20 7 45 14 54 15
21 56 38 74 32 8 -2 18 -4 23 -4z"
        />
        <path
          d="M1712 1521 c-55 -14 -108 -56 -132 -106 -33 -68 -26 -160 16 -212 86
-109 242 -109 328 0 26 32 31 49 34 105 3 56 0 74 -21 112 -41 77 -141 122
-225 101z m78 -121 c21 0 50 -46 50 -77 0 -70 -78 -106 -134 -62 -26 21 -35
65 -20 104 8 20 63 46 82 40 8 -3 18 -5 22 -5z"
        />
        <path
          d="M964 975 c-23 -36 -12 -65 51 -133 78 -84 151 -116 265 -116 114 0
187 32 265 116 63 68 74 97 51 133 -28 43 -60 33 -134 -43 -70 -71 -112 -92
-184 -92 -75 0 -151 43 -206 116 -39 53 -81 60 -108 19z"
        />
      </g>
    </svg>
  );
}

export default BotIcon;
