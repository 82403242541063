export const regionData = [
  { label: "Global", value: "Global" },
  { label: "AM", value: "AM" },
  { label: "EU", value: "EU" },
  { label: "AP", value: "AP" },
  { label: "CN", value: "CN" },
];
export const awsBackupRegionData = [
  { label: "us-east-1", value: "us-east-1" },
  { label: "us-east-2", value: "us-east-2" },
  { label: "us-west-1", value: "us-west-1" },
  { label: "us-west-2", value: "us-west-2" },
  { label: "eu-west-1", value: "eu-west-1" },
  { label: "eu-west-2", value: "eu-west-2" },
  { label: "ap-south-2", value: "ap-south-2" },
  { label: "ap-south-1", value: "ap-south-1" },
  { label: "ap-norhteast-1", value: "ap-norhteast-1" },
  { label: "ap-norhteast-3", value: "ap-norhteast-3" },
];
export const optionalDropDownData = [
  { label: "yes", value: "true" },
  { label: "no", value: "false" },
];

export const backUpScheduleData = [
  { label: "hourly", value: "hourly" },
  { label: "Daily", value: "Daily" },
  { label: "weekly", value: "weekly" },
  { label: "monthly", value: "monthly" },
  { label: "yearly", value: "yearly" },
];
