import { useQuery } from "react-query";
import { useOktaAuth } from "@okta/okta-react";
import Api from "api/api";
import { apiWrapper } from "pages/UserCloudEye/dataloader";
import { errorToast } from "components/Notification";
import { useSelector } from "react-redux";
import {
  securityTrendMicroConformityApi,
  securityTrendMicroConformityDetailsApi,
  securityTrendMicroConformityBranch2Api,
  securityTrendMicroConformityDetailsBranch2Api,
} from "api/security";

export function useTrendMicroConformityApi(body) {
  const { authState } = useOktaAuth();
  const user = useSelector((state) => state.user);
  const { userInfo } = user;
  const requestBody = body && body.accountnumber ? { accountnumber: body.accountnumber, days: +body.days } : {};

  return useQuery(
    [
      body &&  body.accountnumber
        ? Api.securityTrendMicroConformityBranch2
        : Api.securityTrendMicroConformity,
    ],
    apiWrapper(
      body && body.accountnumber
        ? securityTrendMicroConformityBranch2Api
        : securityTrendMicroConformityApi,
      {
        ...requestBody,
        idToken: authState.idToken.idToken,
      }
    ),
    {
      enabled: !!userInfo,
      initialData: [],
      onError: (res) => {
        errorToast("failed to fetch support requests");
        return "failed to fetch support requests";
      },
    }
  );
}

export function useTrendMicroConformityDetailsApi(body) {
  const { authState } = useOktaAuth();
  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  return useQuery(
    [
      body && body.accountnumber
        ? Api.securityTrendMicroDetailsBranch2
        : Api.securityTrendMicroDetails,
    ],
    apiWrapper(
      body && body.accountnumber
        ? securityTrendMicroConformityDetailsBranch2Api
        : securityTrendMicroConformityDetailsApi,
      {
        ...body,
        idToken: authState.idToken.idToken,
      }
    ),
    {
      enabled: !!userInfo,
      initialData: [],
      onError: (res) => {
        errorToast("failed to fetch support requests");
        return "failed to fetch support requests";
      },
    }
  );
}
