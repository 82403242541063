import React, { useReducer, useEffect, useState } from "react";
import { AWSReducer, DefaultAWSData } from "../edit-aws-reducer";

import BulkEditFormSteps from "./BulkEditFormSteps";

const BulkEditForm = ({ data, selectedData, availableData }) => {
  let [step, setStep] = useState(0);
  const [formData, setFormData] = useReducer(AWSReducer, DefaultAWSData);
  let steps = BulkEditFormSteps(step, setStep, formData, setFormData, selectedData, availableData);

  useEffect(() => {
   
    // Billing Info
    setFormData({
      type: "UPDATE_BILLING_INFO",
      payload: {
        [`billingopco`]: data.billingopco ? data.billingopco : "",
        [`billingSubOpco`]: data.billingSubOpco ? data.billingSubOpco : "",
        [`billingDepartment`]: data.billingDepartment
          ? data.billingDepartment
          : "",
        [`billingRegion`]: data.billingRegion,
        [`maxSpend`]: data.maxSpend,
        [`billto`]: data.billto,
        [`io`]: data.io,
        [`costcenter`]: data.costcenter,
      },
    });
  
    // Account Details
    setFormData({
      type: "UPDATE_ACCOUNT_DETAILS",
      payload: {
        [`opco`]: data.opco ? data.opco : "",
        [`subopco`]: data.subopco ? data.subopco : "",
        [`department`]: data.department ? data.department : "",
        // [`environmenttype`]: data.environmenttype,
     
      },
    });
     // Billing Contact
     setFormData({
      type: "UPDATE_BILLING_CONTACT",
      payload: {
        [`firstNameBC`]: data?.firstNameBC,
        [`lastNameBC`]: data?.lastNameBC,
        [`emailBC`]: data?.emailBC,
        [`countryBC`]: data?.countryBC,
        [`phoneNoBC`]: data?.phoneNoBC,
      },
    });
    // Request Status
    setFormData({
      type: "UPDATE_COMMON_DETAILS",
      payload: { [`requestStatus`]: data.requestStatus },
    });
    setFormData({
      type: "UPDATE_COMMON_DETAILS",
      payload: { [`uid`]: data.uid },
    });
    setFormData({
      type: "UPDATE_COMMON_DETAILS",
      payload: { [`aws_account_id`]: data.aws_account_id },
    });
    if (
      data.requestStatus === "Approved" ||
      data.requestStatus === "Update Declined"
    ) {
      setFormData({
        type: "UPDATE_COMMON_DETAILS",
        payload: { [`unEditableAccountName`]: data.accountName },
      });
    }
    setFormData({
      type: "UPDATE_COMMON_DETAILS",
      payload: { [`RequestActionTakenBy`]: data.RequestActionTakenBy },
    });
    setFormData({
      type: "UPDATE_COMMON_DETAILS",
      payload: { [`submittedBy`]: data.submittedBy },
    });
  }, []);
  return <>{steps[`${step}`].content}</>;
};

export default BulkEditForm;
