import React, { useState, useCallback } from "react";
import { Row, Col, Form, Table, Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import Loader from "components/Common/Loader";
import EnableAccountFormMenu from "./EnableAccountFormMenu";

import NewRequestSubmit from "../NewRequestSubmit";
import CustomModal from "components/Modal/CustomModal";
import NewRequestCancel from "../NewRequestCancel";

// Helper
import { postApiCall } from "helper/postApiCall";
import FormProgress from "../FormProgress";

// Static Data
import { dayOfWeek, weeksData } from "../../staticData";
import NewRequestConfirmation from "../NewRequestConfirmation";

//redux
import { REDIRECT_ALERT } from "redux/aws-host-patch-management/patchAccountConstant";

function ReviewAccount({
  step,
  setStep,
  formData,
  setFormData,
  instanceList,
  handleEditWindowClose,
}) {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  // Redux State
  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  // Set ID Token Globally
  const idToken = userInfo.idToken;

  const selectedInstances = useSelector(
    (state) => state.awsHostPatching?.selectedInstancesList
  );

  // Response Data State
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState(null);
  const [newRequestUid, setNewRequestUid] = useState(null);

  // Show Cancel Modal
  const [showCancelModal, setShowCancelModal] = useState(false);

  // Show Confirm Modal
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  // Form Submit State
  const [formSubmitLoading, setFormSubmitLoading] = useState(false);
  const [formSubmitSuccess, setFormSubmitSuccess] = useState(false);
  const enhancedData = instanceList.filter((item) =>
    selectedInstances?.includes(item.InstanceId)
  );

  // Close Modal Callback (Modal No Click Part)
  const closeModalCallback = useCallback((value) => {
    setShowCancelModal(value);
    setShowConfirmModal(value);
  }, []);

  const filterByRegion = () => {
    const uniqueRegionList = [];
    const filterBYId = selectedInstances.map((item) => {
      return instanceList.find((instance) => {
        if (instance.InstanceId === item) {
          if (!uniqueRegionList.includes(instance?.Region)) {
            uniqueRegionList.push(instance?.Region);
          }
          return item;
        }
      });
    });
    const filteredByRegion = uniqueRegionList.map((region) => {
      return {
        [region]: filterBYId
          .map((item) => {
            if (region === item?.Region) {
              return item?.InstanceId;
            }
          })
          .filter((notUndefined) => notUndefined !== undefined),
      };
    });
    return filteredByRegion;
  };

  const handleProceed = (e) => {
    e.preventDefault();
    setShowConfirmModal(false);
    setFormSubmitLoading(true);

    // Form Submission
    const currentEpochTime = Date.now();

    const formSubmitApiUrl = `${process.env.REACT_APP_SSM_PATCHING_NEW_REQUEST_SUBMIT}`;

    const requestBody = {
      accountname: formData?.installatonSchedule?.selectedAccount?.accountname,
      accountnumber:
        formData?.installatonSchedule?.selectedAccount?.accountnumber,
      week: formData?.installatonSchedule?.week,
      dow: formData?.installatonSchedule?.dow,
      hour: formData?.installatonSchedule?.startDateHour,
      instances: filterByRegion(),
      minute: formData?.installatonSchedule?.startDateMinute,
      requestStatus: "Pending",
      uid: `u-${currentEpochTime}`,
      submittedBy: userInfo.email,
      instancetype: "1",
      type: "install",
    };

    const tagRequestBody = {
      accountname: formData?.installatonSchedule?.selectedAccount?.accountname,
      accountnumber:
        formData?.installatonSchedule?.selectedAccount?.accountnumber,
      week: formData?.installatonSchedule?.week,
      dow: formData?.installatonSchedule?.dow,
      hour: formData?.installatonSchedule?.startDateHour,
      minute: formData?.installatonSchedule?.startDateMinute,
      requestStatus: "Pending",
      uid: `u-${currentEpochTime}`,
      submittedBy: userInfo.email,
      instancetype: "0",
      type: "install",
      tags: {
        tagName: formData?.targetInstance?.tagName,
        tagDescription: formData?.targetInstance?.tagDescription,
        region: formData?.targetInstance?.region,
      },
    };
    const requestData =
      Number(formData?.targetInstance?.targetSource) === 1
        ? requestBody
        : tagRequestBody;

    postApiCall(formSubmitApiUrl, idToken, requestData)
      .then((response) => {
        if (response.data.status === "Success") {
          setNewRequestUid(`u-${currentEpochTime}`);
          setFormSubmitSuccess(true);
          setFormSubmitLoading(false);
        } else {
          setFormSubmitLoading(false);
          setApiError(t("failed_to_retrieve_data"));
          dispatch({
            type: REDIRECT_ALERT,
            payload: true,
          });
          history.push("/aws-host-patch-request");
        }
      })
      .catch((err) => {
        setFormSubmitLoading(false);
        setApiError(t("failed_to_retrieve_data"));
        dispatch({
          type: REDIRECT_ALERT,
          payload: true,
        });
        history.push("/aws-host-patch-request");
      });
  };
  const getOptionName = (data, dataArr) => {
    const getName = dataArr.find((item) => item.name === data);
    return getName?.value
      ?.toLowerCase()
      .replace(/(?:(^.)|(\s+.))/g, function (match) {
        return match.charAt(match.length - 1).toUpperCase();
      });
  };
  return (
    <>
      <Row>
        <Col md={12} xs={12}>
          <FormProgress
            gisAccountSelection
            installatonSchedule
            targetInstance
          />
        </Col>
      </Row>

      <Row>
        <span className="page-content-title mt-4 mb-2">
          Kindly check and Proceed further
        </span>
        <Col md={12} xs={12}>
          <div className="review-acc-details mb-2 mt-3">
            <h5 className="subtitle-text d-inline  fw-bold">
              {t("Account Number:")}
            </h5>
            <h5 className="subtitle-text d-inline  ms-2 fw-500 ">
              {formData?.installatonSchedule?.selectedAccount?.accountnumber}
            </h5>
          </div>
          <div className="review-acc-details mb-2">
            <h5 className="subtitle-text d-inline  fw-bold">
              {t("Patch Installation Schedule - ")}
            </h5>
            {/* <h5 className="subtitle-text d-inline ms-2 fw-bold">{`Hour: ${formData?.installatonSchedule?.startDateHour} | Minutes: ${formData?.installatonSchedule?.startDateMinute} (UTC)`}</h5> */}
            <div className="fw-500 d-inline-block subtitle-text">
              {formData.installatonSchedule.week
                ? getOptionName(formData.installatonSchedule.week, weeksData)
                : "______"}{" "}
              {formData.installatonSchedule.dow
                ? getOptionName(formData.installatonSchedule.dow, dayOfWeek)
                : "______"}{" "}
              of Every month @{" "}
              {formData.installatonSchedule.startDateHour
                ? formData.installatonSchedule.startDateHour
                : "00"}
              :
              {formData.installatonSchedule.startDateMinute
                ? formData.installatonSchedule.startDateMinute
                : "00"}{" "}
              UTC
            </div>
          </div>
          <div className="review-acc-details mb-4">
            <h5 className="subtitle-text d-inline  fw-bold">
              {t("scan schedule:")}
            </h5>
            <h5 className="subtitle-text d-inline  ms-2 fw-500">
              “First Saturday of Every month at 08:30 AM UTC”
            </h5>
          </div>
          {Number(formData?.targetInstance?.targetSource) === 0 ? (
            <>
              <h5 className="subtitle-text d-inline  fw-bold">
                {t("Selected Tag Details")}
              </h5>
              <Table
                bordered={true}
                size="sm"
                className="account-confirmation-table"
              >
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Tag Name</th>
                    <th>Tag Description</th>
                    <th>Region</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{1}</td>
                    <td>{formData?.targetInstance?.tagName}</td>
                    <td>{formData?.targetInstance?.tagDescription}</td>
                    <td>{formData?.targetInstance?.region}</td>
                  </tr>
                </tbody>
              </Table>
            </>
          ) : (
            <>
              <h5 className="subtitle-text d-inline  fw-bold">
                {t("Selected Targets")}
              </h5>
              <Table
                bordered={true}
                size="sm"
                className="account-confirmation-table"
              >
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Instance Id</th>
                    <th>Display Name</th>
                    <th>SSM Status</th>
                    <th>Region</th>
                  </tr>
                </thead>
                <tbody>
                  {enhancedData.map((item, index) => (
                    <>
                      <tr>
                        <td>{index + 1}</td>
                        <td>{item?.InstanceId}</td>
                        <td>{item?.DisplayName}</td>
                        <td>{item?.["SSM Status"]}</td>
                        <td>{item?.Region}</td>
                      </tr>
                    </>
                  ))}
                </tbody>
              </Table>
            </>
          )}
        </Col>
        <div className="col-md-12 mt-4">
          <Col md={12}>
            <div className="d-flex justify-content-center">
              {formSubmitLoading === false && apiError !== null && (
                <Alert variant="danger">{apiError}</Alert>
              )}
            </div>
          </Col>
          <div className="col-md-5 offset-md-7">
            {formSubmitLoading === true && (
              <div className="mb-3">
                <Loader />
              </div>
            )}
          </div>
          {/* {formSubmitLoading === false && ( */}
          <ul className="px-0 btn-ul justify-content-end">
            <li>
              <button
                type="button"
                className="teal-bordered-btn"
                onClick={() => setStep(step - 1)}
              >
                {t("back")}
              </button>
            </li>
            <li>
              <button
                type="button"
                className="teal-bordered-btn"
                onClick={() => {
                  setShowCancelModal(true);
                }}
              >
                {t("cancel")}
              </button>
            </li>
            <li>
              <button
                type="button"
                onClick={(e) => {
                  setShowConfirmModal(true);
                }}
                className="teal-filled-btn"
              >
                {t("save_submit")}
              </button>
            </li>
          </ul>
          {/* )} */}
        </div>
      </Row>

      {showCancelModal && (
        <CustomModal
          showModal={true}
          children={
            <NewRequestCancel
              closeModalParent={closeModalCallback}
              handleEditWindowClose={handleEditWindowClose}
            />
          }
        />
      )}
      {formSubmitSuccess && (
        <CustomModal
          showModal={true}
          children={<NewRequestSubmit refUid={newRequestUid} />}
        />
      )}
      {showConfirmModal && (
        <CustomModal
          showModal={true}
          children={
            <NewRequestConfirmation
              closeModalParent={closeModalCallback}
              handleProceed={handleProceed}
            />
          }
        />
      )}
    </>
  );
}

export default ReviewAccount;
