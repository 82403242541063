import React, { useReducer, useEffect, useState } from "react";
import { AzureReducer, DefaultAzureData } from "../edit-azure-reducer";

import EditFormSteps from "./EditFormSteps";

const EditForm = ({ data }) => {
  let [step, setStep] = useState(0);
  const [formData, setFormData] = useReducer(AzureReducer, DefaultAzureData);
  let steps = EditFormSteps(step, setStep, formData, setFormData);

  useEffect(() => {
    // Account Contact
    setFormData({
      type: "UPDATE_ACCOUNT_CONTACT",
      payload: {
        [`firstNameAO`]: data.firstNameAO,
        [`lastNameAO`]: data.lastNameAO,
        [`emailAO`]: data.emailAO,
        [`countryAO`]: data.countryAO,
        [`phoneNoAO`]: data.phoneNoAO,
      },
    });
    // Billing Contact
    setFormData({
      type: "UPDATE_BILLING_CONTACT",
      payload: {
        [`firstNameBC`]: data.firstNameBC,
        [`lastNameBC`]: data.lastNameBC,
        [`emailBC`]: data.emailBC,
        [`countryBC`]: data.countryBC,
        [`phoneNoBC`]: data.phoneNoBC,
      },
    });
    // Security Contact
    setFormData({
      type: "UPDATE_SECURITY_CONTACT",
      payload: {
        [`firstNameSC`]: data.firstNameSC,
        [`lastNameSC`]: data.lastNameSC,
        [`emailSC`]: data.emailSC,
        [`countrySC`]: data.countrySC,
        [`phoneNoSC`]: data.phoneNoSC,
      },
    });
    // Technical Contact
    setFormData({
      type: "UPDATE_TECHNICAL_CONTACT",
      payload: {
        [`firstNameTC`]: data.firstNameTC,
        [`lastNameTC`]: data.lastNameTC,
        [`emailTC`]: data.emailTC,
        [`countryTC`]: data.countryTC,
        [`phoneNoTC`]: data.phoneNoTC,
      },
    });
    // Billing Info
    setFormData({
      type: "UPDATE_BILLING_INFO",
      payload: {
        [`billingopco`]: data.billingopco ? data.billingopco : "",
        [`billingSubOpco`]: data.billingSubOpco ? data.billingSubOpco : "",
        [`billingDepartment`]: data.billingDepartment
          ? data.billingDepartment
          : "",
        [`billingRegion`]: data.billingRegion,
        [`maxSpend`]: data.maxSpend,
        [`billto`]: data.billto,
        [`io`]: data.io,
        [`costcenter`]: data.costcenter,
      },
    });
    // Group Email Address
    setFormData({
      type: "UPDATE_GROUP_EMAIL_ADDRESS",
      payload: {
        [`technicalGroup`]: data.technicalGroup,
        [`securityGroup`]: data.securityGroup,
        [`billingGroup`]: data.billingGroup,
        [`pgcsgoldenami`]: data.pgcsgoldenami,
        [`ptm`]: data.ptm,
        [`conformity`]: data.conformity,
        [`sonyad`]: data.sonyad,
      },
    });
    // Account Details
    setFormData({
      type: "UPDATE_ACCOUNT_DETAILS",
      payload: {
        [`opco`]: data.opco ? data.opco : "",
        [`subopco`]: data.subopco ? data.subopco : "",
        [`department`]: data.department ? data.department : "",
        [`environmenttype`]: data.environmenttype,
        [`assetUserBase`]: data.assetUserBase,
        [`dataClassification`]: data.dataClassification,
        [`purposeid`]: data.purposeid,
        [`accountdescription`]: data.accountdescription,
        [`accountName`]: data.accountname,
        [`accountnumber`]: data.accountnumber,
        [`accstatus`]: data.accstatus,
      },
    });
    // Request Status
    setFormData({
      type: "UPDATE_COMMON_DETAILS",
      payload: { [`requestStatus`]: data.requestStatus },
    });
    setFormData({
      type: "UPDATE_COMMON_DETAILS",
      payload: { [`uid`]: data.uid },
    });
    setFormData({
      type: "UPDATE_COMMON_DETAILS",
      payload: { [`aws_account_id`]: data.aws_account_id },
    });
    if (
      data.requestStatus === "Approved" ||
      data.requestStatus === "Update Declined"
    ) {
      setFormData({
        type: "UPDATE_COMMON_DETAILS",
        payload: { [`unEditableAccountName`]: data.accountName },
      });
    }
    setFormData({
      type: "UPDATE_COMMON_DETAILS",
      payload: { [`RequestActionTakenBy`]: data.RequestActionTakenBy },
    });
    setFormData({
      type: "UPDATE_COMMON_DETAILS",
      payload: { [`submittedBy`]: data.submittedBy },
    });
  }, []);
  return <>{steps[`${step}`].content}</>;
};

export default EditForm;
