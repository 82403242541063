import React from 'react';
import { useDispatch } from "react-redux"; 

// Import Internal Component
import EditForm from './Edit/EditForm';

// Import Icons
import backicon from 'icons/back.svg';
import { IS_DIRTY_APPLICAION_NAME, TAB_ERROR_VALUES } from "redux/edit-window/editValueConstant.js";

const AccountRequestsEdit = ({ data, cloudTitle, closeEditParent }) => {
  const dispatch = useDispatch();
    
  const handleBackClick = () =>{
    dispatch({
      type: TAB_ERROR_VALUES,
      payload: [],
    });

     closeEditParent(false);
      dispatch({
        type: IS_DIRTY_APPLICAION_NAME,
        payload: false,
      });
    }

  return (
    <div className='view-edit-box-conatiner'>
      <div className='view-edit-box-top-bar'>
        <div className='view-edit-box-back-title-container'>
          <img
            src={backicon}
            alt='Back'
            className='view-edit-box-back-btn'
            onClick={() => handleBackClick()}
          />
          <h5 className='view-edit-box-title'>{cloudTitle} Account Details</h5>
          {(data.requestStatus === 'Approved' ||
            data.requestStatus === 'Update Declined') && (
            <span className='view-edit-box-accountid mx-3'>
              Subscription ID: {data.azure_account_id}
            </span>
          )}
        </div>
      </div>
      <div className='table-edit-box-content px-1 mt-4 mb-5'>
        <EditForm data={data} />
      </div>
    </div>
  );
};

export default AccountRequestsEdit;
