import React, { useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import Moment from "moment";

import SpinnerLoader from "components/Common/SpinnerLoader";

const PrismaDownload = ({ userInfo }) => {
  const fileName = `Conformityalertcount-${Moment()
    .subtract(7, "d")
    .format("YYYY-MM-DD")}-${Moment().format("YYYY-MM-DD")}`;
  const [loading, setLoading] = useState(false);

  function loadDownloadData() {
    setLoading(true);
    const setIdToken = `{"idToken":{"idToken": "${userInfo.idToken}"}}`;

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: setIdToken,
      },
    };
    const apiUrl = `${process.env.REACT_APP_SECURITY_PRISMA_ALERT_DETAILS}`;

    axios
      .post(
        apiUrl,
        {
          // qty: '7',
          // attr: 'day',
          context: "download",
        },
        config
      )
      .then((r) => {
        exportToCSV(r.data, fileName);
        setLoading(false);
      });
  }

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = (apiData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(apiData, {
      header: [
        "accountnumber",
        "accountname",
        "Cloud",
        "EXTREME",
        "VERY_HIGH",
        "HIGH",
      ],
    });
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  const { t } = useTranslation();

  return (
    <>
      {loading && (
        <div className="d-flex flex-column justify-content-center">
          <SpinnerLoader />
          <span className="custom-loader-message">
            {t("file_will_be_downloaded_in_a_while") + "!"}
          </span>
        </div>
      )}
      {loading === false && (
        <button
          className="teal-bordered-download-btn-sm"
          onClick={(e) => {
            loadDownloadData();
          }}
        >
          <i
            className="fa fa-download"
            style={{ fontSize: "12px", marginRight: "6px" }}
          ></i>
          {t("download")}
        </button>
      )}
    </>
  );
};

export default PrismaDownload;
